import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { ReactComponent as P400 } from '../../images/sco-image-terminal-hint-verifone.svg';
// @ts-ignore
import { ReactComponent as Feig } from '../../images/sco-image-terminal-hint-feig.svg';
// @ts-ignore
import { ReactComponent as Q30Pax } from '../../images/sco-image-terminal-hint-q30pax.svg';
import Text from '../../typography/Text';
import { useAppSelector } from '../../store';

const TERMINAL_IMAGES: Record<string, ReactNode> = {
  p400: <P400 className="default__image" />,
  feig: <Feig className="default__image" />,
  q30pax: <Q30Pax className="default__image" />,
};

export default function CheckoutTerminalPaymentProcessingView() {
  const terminalType = useAppSelector(state => state.checkoutDevice.config.terminalType);
  const { t } = useTranslation();

  return (
    <div className="default">
      <div className="default__content">
        <div className="terminal">
          {TERMINAL_IMAGES[terminalType] ? TERMINAL_IMAGES[terminalType] : TERMINAL_IMAGES.feig}
        </div>
        <Text className="default__text">{t('terminal.hint')}</Text>
      </div>
    </div>
  );
}
