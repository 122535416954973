import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { addCartItems, manipulateCartItem, removeCartItem, updateCartItemQuantity } from '../session';
import { AddCartItemOptions } from '../session/content/changesets/add';
import { useAbortableAppDispatch, useAppDispatch } from '../store';
import useDebounced from '../useDebounced';
import CartMutationContext from './CartMutationContext';

const DEBOUNCE = 200;

interface CartMutationProviderProps {
  children: ReactNode;
}

/**
 * A component that coordinates cart mutations of its consumers. For example,
 * cancels overlapping requests and applies debouncing when quantities are
 * changed. The provided functions can be used via the `useCartMutator` hook.
 */
export default function CartMutationProvider({
  children,
}: CartMutationProviderProps) {
  const dispatch = useAppDispatch();
  const dispatchAbortable = useAbortableAppDispatch();

  const abortControllerRef = useRef<AbortController>();

  useEffect(() => () => {
    abortControllerRef.current?.abort();
  }, []);

  // Change quantity

  const sendUpdatedQuantity = useCallback(
    async (id: string, amount: number) => {
      abortControllerRef.current = new AbortController();

      await dispatchAbortable(
        updateCartItemQuantity({ id, amount }),
        abortControllerRef.current.signal,
      );

      abortControllerRef.current = undefined;
    },
    [dispatchAbortable],
  );

  const sendUpdatedQuantityDebounced = useDebounced(sendUpdatedQuantity, DEBOUNCE);

  const abort = useCallback(() => {
    abortControllerRef.current?.abort();
    sendUpdatedQuantityDebounced.cancel();
  }, [sendUpdatedQuantityDebounced]);

  const addItems = useCallback(
    async (options: AddCartItemOptions[]) => {
      abort();
      await dispatch(addCartItems(options));
    },
    [abort, dispatch],
  );

  const removeItem = useCallback(
    async (id: string) => {
      abort();
      await dispatch(removeCartItem(id));
    },
    [abort, dispatch],
  );

  const updateItemQuantity = useCallback(
    async (id: string, amount: number) => {
      const updatedAmount = Math.max(amount, 0);

      abort();

      dispatch(manipulateCartItem({
        id,
        item: { amount: updatedAmount },
      }));

      if (updatedAmount === 0) {
        await removeItem(id);
        return;
      }

      sendUpdatedQuantityDebounced(id, updatedAmount);
    },
    [abort, dispatch, removeItem, sendUpdatedQuantityDebounced],
  );

  return (
    <CartMutationContext.Provider
      value={{
        addCartItems: addItems,
        updateCartItemQuantity: updateItemQuantity,
        removeCartItem: removeItem,
      }}
    >
      {children}
    </CartMutationContext.Provider>
  );
}
