import CheckoutInfoErrorData from './CheckoutInfoErrorData';
import CheckoutInfoErrorDetail, { CheckoutInfoErrorDetailType } from './CheckoutInfoErrorDetail';
import CheckoutInfoErrorType from './CheckoutInfoErrorType';
import LineItem from './line-item/LineItem';
import LineItemType from './line-item/LineItemType';

export default function hasNullPriceItems(lineItems: LineItem[]): CheckoutInfoErrorData | null {
  const errorDetails: CheckoutInfoErrorDetail[] = [];

  lineItems.forEach((lineItem: any) => {
    if (lineItem.price === null && lineItem.type === LineItemType.Default) {
      errorDetails.push({
        message: 'Mising price',
        sku: lineItem.sku,
        type: CheckoutInfoErrorDetailType.MissingPrice,
      });
    }
  });

  if (errorDetails.length === 0) return null;
  return {
    type: CheckoutInfoErrorType.InvalidCartItem,
    details: errorDetails,
  };
}
