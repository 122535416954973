import { Buffer } from 'buffer';
// @ts-ignore
import forge from 'node-forge';

const formatPEM = (certificate: string) => (`-----BEGIN CERTIFICATE-----\n${certificate}\n-----END CERTIFICATE-----`);

const bufferFromJson = (json: Record<string, any>) => Buffer.from(JSON.stringify(json));

export default function encryptOrigin(
  content: Record<string, any>,
  certificate: string | undefined,
) {
  if (!certificate) return null;
  const { publicKey } = forge.pki.certificateFromPem(formatPEM(certificate));

  const buffer = bufferFromJson(content);

  const encryptedOrigin = publicKey.encrypt(buffer, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
  });

  const encryptedOriginBase64 = forge.util.encode64(encryptedOrigin);

  return encryptedOriginBase64;
}
