import Big from 'big.js';
import { v4 as uuidv4 } from 'uuid';
import findIndex from 'lodash/findIndex';
import isNull from 'lodash/isNull';
import remove from 'lodash/remove';
import { formatPrice, toBig } from '../helper/price';
import ProductDb from '../db/ProductDb';
import ProductCartItem from './ProductCartItem';
import CouponCartItem from './CouponCartItem';
import CustomerCards from './CustomerCards';
import findCouponByCode from '../coupon/findCouponByCode';

export default class ShoppingCart {
  static MAX_QUANTITY = 99;
  static DISCOUNT_TYPE = 'discount';

  #totalPrice = null;

  constructor() {
    this.items = [];
    this.AdditionalCartItems = [];
    this.discount = new Big(0);
    this.session = null;
    this.missingItem = false;
    this.hasError = false;
    this.customerCard = null;
    this.paymentCard = null;
    this.canPayWithExternalBilling = false;
    this.customerCards = new CustomerCards();
    this.redeemableCoupons = [];
  }

  start() {
    this.session = uuidv4();
  }

  get productItems() {
    return this.items.filter(item => item instanceof ProductCartItem);
  }

  get couponItems() {
    return this.items.filter(item => item instanceof CouponCartItem);
  }

  get inActiveSession() {
    return !!this.session;
  }

  get isEmpty() {
    return this.items.length === 0 && this.AdditionalCartItems.length === 0;
  }

  setMetadata(metadata) {
    if (!metadata) return;
    this.decimalDigits = metadata.decimalDigits;
    this.currency = metadata.currency;
    this.rounding = metadata.rounding;
    this.shop = metadata.shop;
    this.customerCards.setAccepted(metadata);
  }

  update(checkoutInfo, errors) {
    this.AdditionalCartItems = [];
    this.discount = new Big(0);
    this.hasError = !!errors && errors.length > 0;

    if (!checkoutInfo) return;

    this.items.forEach((cartItem) => {
      if (errors && errors.length) {
        errors.forEach((error) => {
          if (cartItem.product && error.sku === cartItem.product.sku) {
            cartItem.addError(error.type);
          }
        });
      }

      if (checkoutInfo?.violations?.length) {
        this.hasError = true;
        checkoutInfo.violations.forEach((violation) => {
          if (violation.refersTo === cartItem.key) {
            cartItem.addError(violation.type);
          }
        });
      }

      const lineItem =
        checkoutInfo
          .lineItems
          .find(item => item.id === cartItem.key);

      if (!lineItem) {
        return;
      }

      if (lineItem.sku !== cartItem.sku) {
        // vPos may change the SKU to something else, so we need to make
        // another product lookup here and update the product data in the cart
        // item accordingly
        const product = ProductDb.findBySku(lineItem.sku);
        if (product) {
          cartItem.updateProduct(product);
        }
      }

      cartItem.updateFromLineItem(lineItem);

      const referringLineItems =
        checkoutInfo
          .lineItems
          .filter(item => item.refersTo === cartItem.key);

      const AdditionalCartItems = [];

      referringLineItems.forEach((item) => {
        if (item.type === ShoppingCart.DISCOUNT_TYPE) {
          this.addDiscount(item.totalPrice);
          return;
        }

        const product = ProductDb.findBySku(item.sku);
        if (!product) return;

        const referringItem = ProductCartItem.fromLineItem(item, product);
        referringItem.isCountable = false;
        referringItem.isRemovable = false;

        if (product.isDeposit) {
          // eslint-disable-next-line no-param-reassign
          cartItem.depositItem = referringItem;
        } else {
          AdditionalCartItems.push(referringItem);
        }
      });

      this.AdditionalCartItems = AdditionalCartItems;
    });

    this.#totalPrice = this.#toBigString(checkoutInfo.price.price);
  }

  findItem(key) {
    return this.items.find(item => item.key === key);
  }

  findItemIndex(key) {
    return findIndex(this.items, item => item.key === key);
  }

  #findProductItem(product) {
    if (!product) return null;
    return this.items.find(item =>
      item.product && item.isCountable && item.product.sku === product.sku);
  }

  addItems(codes) {
    const items = [];

    (codes || []).forEach((code) => {
      if (!code) return;

      const item = this.addItem(code);
      if (item) items.push(item);
    });

    return items;
  }

  addPaymentCard(code) {
    if (this.customerCards.isPaymentMethod(code)) {
      this.setPaymentCard(code);
      return true;
    }
    return false;
  }

  addCustomerCard(code) {
    if (this.customerCards.isCustomerCard(code)) {
      this.setCustomerCard(code);
      return true;
    }
    return false;
  }

  addItem(code) {
    if (!this.session) {
      this.start();
    }

    const item = this.#addItemByCode(code);
    if (item) {
      this.#calculateTotalPrice();
    }
    return item;
  }

  #addItemByCode(code) {
    const item = this.#addCouponItemByCode(code);
    if (item) {
      return item;
    }

    return this.#addProductItemByCode(code);
  }

  #addProductItemByCode(code) {
    const { product, scannedCode } = ProductDb.findByCode(code);
    if (!product) {
      if (this.customerCard || this.paymentCard || !this.addCustomerCard(code)) {
        this.missingItem = true;
      }
      return null;
    }

    const quantity = product.getSpecifiedQuantity(scannedCode) || 1;

    let item = this.#findProductItem(product);

    // FIXME: Do not ADD QUANTITY for found price override products
    // https://snabble.atlassian.net/browse/SCO-43

    if (item) {
      item.addQuantity(quantity);
    } else {
      item = new ProductCartItem(product, quantity, scannedCode);
      this.items.push(item);
    }

    return item;
  }

  #addCouponItemByCode(code) {
    const { coupon, scannedCode } = findCouponByCode(
      this.redeemableCoupons,
      ProductDb.codeTemplates,
      code,
    );

    if (!coupon) {
      return null;
    }

    let item = this.#findCouponItem(coupon);
    if (!item) {
      item = new CouponCartItem(coupon, scannedCode);
      this.items.push(item);
    }

    return item;
  }

  #findCouponItem(coupon) {
    return this.items.some(item => item.coupon && item.coupon.id === coupon.id);
  }

  addDiscount(price) {
    this.discount = this.discount.add(this.#toBigString(price));
  }

  get discountText() {
    if (this.discount.eq(0)) return '';

    return formatPrice(this.discount, this.decimalDigits, this.currency);
  }

  setQuantity(key, quantity) {
    const q = Math.min(quantity, ShoppingCart.MAX_QUANTITY);

    const item = this.findItem(key);
    if (!item) return item;

    if (q <= 0) {
      this.#removeItem(key);
    } else {
      item.setQuantity(q);
    }

    this.#calculateTotalPrice();

    return item;
  }

  resetMissingItem() {
    this.missingItem = false;
  }

  removeCards() {
    this.customerCard = null;
    this.paymentCard = null;
    this.canPayWithExternalBilling = false;
  }

  insertItem(index, item) {
    const items = [...this.items];
    items.splice(index, 0, item);
    this.items = items;
    this.#calculateTotalPrice();
    return item;
  }

  clear() {
    this.items = [];
    this.AdditionalCartItems = [];
    this.discount = new Big(0);
    this.session = null;
    this.#totalPrice = null;
    this.missingItem = false;
    this.hasError = false;
    this.customerCard = null;
    this.paymentCard = null;
    this.canPayWithExternalBilling = false;
  }

  get hasImages() {
    return this.items.some(item => !!item.imageUrl);
  }

  get totalPriceText() {
    return formatPrice(this.totalPrice, this.decimalDigits, this.currency);
  }

  setPaymentCard(value) {
    this.paymentCard = value;
    this.canPayWithExternalBilling = true;
  }

  setCustomerCard(value) {
    this.customerCard = value;
  }

  getTotalQuantity() {
    return this.items.reduce(
      (q, item) => q + (item.quantity ?? 0),
      0,
    );
  }

  get totalPrice() {
    if (isNull(this.#totalPrice)) {
      this.#calculateTotalPrice();
    }
    return this.#totalPrice;
  }

  getSendableCart() {
    return {
      shopId: this.shop,
      items: this.items.map(item => item.serializeForCheckout()),
      session: this.session,
      customer: {
        loyaltyCard: this.customerCards.getSendableCode(this.customerCard),
      },
    };
  }

  #removeItem(key) {
    remove(this.items, item => item.key === key);
  }

  #calculateTotalPrice() {
    this.#totalPrice = this.items.reduce(
      (totalPrice, item) => totalPrice.add(item.totalPrice ?? 0),
      new Big(0),
    );
  }

  #toBigString(value) {
    return toBig(value, this.decimalDigits, this.rounding);
  }
}
