import { replaceWithApi } from '../helper/urls';

const DEFAULT_VARIANT = '1x';

export default (files, format, useFallback, { src, useDarkVariant }) => {
  if (!files || !files.length) return '';

  let srcName = src;
  // if src starts with slash we remove it as we look up for the asset name
  if (srcName.startsWith('/')) {
    srcName = srcName.slice(1);
  }

  let file;
  if (useDarkVariant) {
    file = files.find(f => f.name.startsWith(`${srcName}_dark.${format}`));

    if (!useFallback && !file) {
      return '';
    }
  }

  if (!file) {
    file = files.find(f => f.name.startsWith(`${srcName}.${format}`));
  }

  if (!file || !file.variants[DEFAULT_VARIANT]) {
    if (!useFallback) {
      return '';
    }

    // look up without format if useFallback is true
    file = files.find(f => f.name.startsWith(`${srcName}.`));

    if (!file || !file.variants[DEFAULT_VARIANT]) {
      return '';
    }
  }

  return replaceWithApi(document.location, file.variants[DEFAULT_VARIANT]);
};
