// @ts-ignore
import { TemplateLibrary } from 'npm-code-templates';
import { DEFAULT_CODE_TEMPLATE } from './CodeTemplate';
import CodeTemplates from './CodeTemplates';
import parseGS1Code from './gs1/parseGS1Code';
import PriceOverride from './PriceOverride';
import ScannedCodeInfo from './ScannedCodeInfo';
import generateCodeVariants from './generateCodeVariants';

export interface ResolveScannedCodesOptions {
  code: string;
  templates: CodeTemplates;
  priceOverrides?: PriceOverride[];
}

interface MatchingTemplate {
  template: string,
  parts: {
    code: string,
    embed?: number,
  };
}

export default function resolveScannedCodes({
  code,
  templates,
  priceOverrides = [],
}: ResolveScannedCodesOptions): ScannedCodeInfo[] {
  const trimmedCode = code.trim();
  const templatesLookup = new TemplateLibrary(templates, priceOverrides);

  const codeInfos: ScannedCodeInfo[] =
    templatesLookup
      .findMatchingTemplate(trimmedCode)
      .map((matching: MatchingTemplate) => ({
        code: matching.parts.code,
        template: matching.template,
        embed: matching.parts.embed,
        scannedByUser: code,
      }));

  const gs1Code = parseGS1Code(trimmedCode);
  if (gs1Code) {
    const embed = gs1Code.weight ?? gs1Code.units ?? gs1Code.price;
    codeInfos.push({
      code: gs1Code.code,
      template: DEFAULT_CODE_TEMPLATE,
      embed,
      scannedByUser: code,
    });
  }

  return codeInfos.flatMap(codeInfo =>
    generateCodeVariants(codeInfo.code).map(c => ({ ...codeInfo, code: c })));
}
