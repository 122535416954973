import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Feature, useHasFeatureFlag } from '../../feature';
import { useAppSelector } from '../../store';
import calculateSummarizedFulfillmentState from '../../fulfillment/calculateSummarizedFulfillmentState';
import CheckoutFulfillmentsView from './CheckoutFulfillmentsView';
import CheckoutReceiptView from './CheckoutReceiptView';
import getPrintableReceiptContent from '../../receipt/api/getPrintableReceiptContent';
import getSignedDigitalReceiptUrl from '../../receipt/api/getSignedDigitalReceiptUrl';
import logger from '../../logging';
import printContent from '../../terminal/api/printContent';
import ReceiptDeliveryType from '../../receipt/ReceiptDeliveryType';
import SummarizedFulfillmentState from '../../fulfillment/SummarizedFulfillmentState';
import useAbortSignal from '../../abort/useAbortSignal';
import useCheckoutProcess from '../useCheckoutProcess';
import useCheckoutProcessPoller from '../../checkout/useCheckoutProcessPoller';
import useGetFallbackReceiptContent from '../useGetFallbackReceiptContent';
import { Route, Router } from '../../routing';

enum View {
  Receipt,
  Fulfillments,
}

export default function ScoCheckoutSucceededView() {
  const abortSignal = useAbortSignal();
  const checkoutProcess = useCheckoutProcess();
  const clientToken = useAppSelector(state => state.token.payment);

  const supportsDigitalReceipt = useHasFeatureFlag(Feature.DigitalReceipt);
  const [digitalReceiptUrl, setDigitalReceiptUrl] = useState('');
  const [receiptPrinted, setReceiptPrinted] = useState(false);

  const receiptUrl = checkoutProcess?.links.receipt?.href;

  const printReceiptContent = useCallback(async (content: string) => {
    await printContent({ content, signal: abortSignal });
    setReceiptPrinted(true);
  }, [abortSignal]);

  const getFallbackReceiptContent = useGetFallbackReceiptContent();

  const handleMaxAttemptsExceededToGetReceipt = useCallback(async () => {
    logger.warning('Max attempts exceeded to fetch receipt, printing fallback receipt instead', { tag: 'Checkout' });
    await printReceiptContent(getFallbackReceiptContent());
  }, [getFallbackReceiptContent, printReceiptContent]);

  useCheckoutProcessPoller({
    disabled: !!receiptUrl,
    interval: 1000,
    maxAttempts: 100,
    onMaxAttempts: handleMaxAttemptsExceededToGetReceipt,
    immediate: true,
  });

  const fulfillmentState = useMemo(() => {
    if (!checkoutProcess?.fulfillments) return undefined;
    return calculateSummarizedFulfillmentState(checkoutProcess.fulfillments);
  }, [checkoutProcess]);

  const hasFailedFulfillment =
    fulfillmentState === SummarizedFulfillmentState.Failed;

  const printReceipt = useCallback(async (url: string) => {
    let receiptContent: string;
    try {
      logger.info(`Fetching printable receipt content for "${checkoutProcess?.id}"`, { tag: 'Checkout' });
      receiptContent = await getPrintableReceiptContent({
        clientToken,
        url,
        signal: abortSignal,
      });
    } catch (e) {
      logger.warning(`Failed to get receipt content for ${checkoutProcess?.id}, using fallback receipt instead`, { tag: 'Checkout' });
      receiptContent = getFallbackReceiptContent();
    }

    await printReceiptContent(receiptContent);
  }, [abortSignal, checkoutProcess, clientToken, getFallbackReceiptContent, printReceiptContent]);

  const handleDemandPrintedReceipt = useCallback(async () => {
    if (!receiptUrl || receiptPrinted) return;

    logger.info(`Printing demanded sending print request for "${checkoutProcess?.id}"`, { tag: 'Checkout' });
    await printReceipt(receiptUrl);
  }, [checkoutProcess, printReceipt, receiptPrinted, receiptUrl]);

  useEffect(() => {
    // We need to check here whether the receipt has been printed here or else
    // this will get invoked on unmount and cancel the sign request immediately.
    if (!receiptUrl || receiptPrinted) return;

    (async () => {
      // when a fulfillment exists but it fails, we always want to PRINT a
      // receipt and never provide a digital receipt
      if (!supportsDigitalReceipt || hasFailedFulfillment) {
        logger.info(`Requesting to print receipt for "${checkoutProcess?.id}"`, { tag: 'Checkout' });
        await printReceipt(receiptUrl);
        return;
      }

      logger.info(`Fetching signed digital receipt URL for "${checkoutProcess?.id}"`, { tag: 'Checkout' });
      setDigitalReceiptUrl(await getSignedDigitalReceiptUrl({
        url: receiptUrl,
        signal: abortSignal,
        clientToken,
      }));
    })();
  }, [
    abortSignal,
    checkoutProcess,
    clientToken,
    hasFailedFulfillment,
    printReceipt,
    receiptPrinted,
    receiptUrl,
    supportsDigitalReceipt,
  ]);

  const receiptDeliveryType = useMemo(() => {
    if (digitalReceiptUrl) return ReceiptDeliveryType.Digital;
    if (receiptPrinted) return ReceiptDeliveryType.Printed;

    return ReceiptDeliveryType.Unknown;
  }, [digitalReceiptUrl, receiptPrinted]);

  const currentView = useMemo(() => {
    if (
      !fulfillmentState ||
      fulfillmentState === SummarizedFulfillmentState.Succeeded
    ) {
      return View.Receipt;
    }

    return View.Fulfillments;
  }, [fulfillmentState]);

  return (
    <Router state={currentView}>
      <Route when={View.Fulfillments}>
        <CheckoutFulfillmentsView fulfillmentState={fulfillmentState!} />
      </Route>
      <Route when={View.Receipt}>
        <CheckoutReceiptView
          deliveryType={receiptDeliveryType}
          digitalReceiptUrl={digitalReceiptUrl}
          onDemandPrint={handleDemandPrintedReceipt}
        />
      </Route>
    </Router>
  );
}
