import CartItem from './CartItem';

const QUANTITY = 1;

export default class CouponCartItem extends CartItem {
  static UNREDEEMED = 'unredeemed';
  static REDEEMED = 'redeemed';
  static INDETERMINATE_REDEMPTION = 'indeterminate-redemption';

  isCountable = false;

  constructor(coupon, scannedCode) {
    super(QUANTITY, scannedCode);
    this.redemptionState = CouponCartItem.INDETERMINATE_REDEMPTION;
    this.coupon = coupon;
  }

  get displayName() {
    return this.coupon.name;
  }

  get isRedeemed() {
    return this.redemptionState === CouponCartItem.REDEEMED;
  }

  get isUnredeemed() {
    return this.redemptionState === CouponCartItem.UNREDEEMED;
  }

  serializeForCheckout() {
    return {
      id: this.key,
      couponID: this.coupon.id,
      amount: QUANTITY,
      scannedCode: this.scannedCode.code,
    };
  }

  serializeForEvent() {
    // TODO (tc): Use the same format as the app (maybe this is actually already
    // the format)
    return {
      ...super.serializeForEvent(),
      couponID: this.coupon.id,
    };
  }

  updateFromLineItem(lineItem) {
    this.redemptionState = lineItem.redeemed
      ? CouponCartItem.REDEEMED
      : CouponCartItem.UNREDEEMED;
  }
}
