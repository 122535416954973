// There may be more states than the ones defined here

enum CheckState {
  /**
   * The check is awaiting approval or rejection.
   */
  Pending = 'pending',

  /**
   * Check happens during the payment process.
   */
  Postponed = 'postponed',
}

export default CheckState;
