import mapLegacyRootThunk from './mapLegacyRootThunk';
import logger from '../logging';

const localRequest = (path, body, method, onSuccess, onFail) => {
  fetch(
    `http://localhost:8080/${path}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
      method,
    },
  ).then(onSuccess, onFail);
};

const post = (path, body, onSuccess, onFail) => {
  localRequest(path, body, 'POST', onSuccess, onFail);
};

const get = (path, onSuccess, onFail) => {
  localRequest(path, null, 'GET', onSuccess, onFail);
};


function terminalLogin() {
  return mapLegacyRootThunk(() => {
    post(
      'login',
      null,
      () => { logger.info('sent terminal login request', { tag: 'Terminal' }); },
      (err) => { logger.error(('error while terminal login request', err), { tag: 'Terminal' }); },
    );
  });
}

function terminalLogout() {
  return mapLegacyRootThunk(() => {
    post(
      'logout',
      null,
      () => { logger.info('sent terminal logout request', { tag: 'Terminal' }); },
      (err) => { logger.error(('error while terminal logout request', err), { tag: 'Terminal' }); },
    );
  });
}

function gotTerminalConfig(config) {
  return {
    type: 'GOT_TERMINAL_CONFIG',
    payload: config,
  };
}

function getTerminalConfig() {
  return mapLegacyRootThunk((dispatch) => {
    get(
      'scoConfig',
      (response) => {
        response.json().then((r) => {
          dispatch(gotTerminalConfig(r));
        });
      },
      (err) => {
        logger.error(('error while getting scoConfig from terminal', err), { tag: 'Terminal' });
      },
    );
  });
}

export {
  getTerminalConfig, terminalLogin, terminalLogout,
};
