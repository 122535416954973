import projectID from '../ProjectID';
import Registry from '../registry/Registry';
import tegutSuccessAnimation from './success-animation-tegut.json';
import hellwegSuccessAnimation from './success-animation-hellweg.json';
import tchiboSuccessAnimation from './success-animation-tchibo.json';

const config = new Registry<any>({ defaultKey: 'default' });

config.set(
  'default',
  {
    success: tegutSuccessAnimation,
  },
);

config.set(
  projectID.tegut,
  {
    success: tegutSuccessAnimation,
  },
);

config.set(
  projectID.hellweg,
  {
    success: hellwegSuccessAnimation,
  },
);

config.set(
  projectID.tchibo,
  {
    success: tchiboSuccessAnimation,
  },
);

config.set(
  projectID.tchiboDemo,
  {
    success: tchiboSuccessAnimation,
  },
);

export default config.freeze();
