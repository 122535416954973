import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { ReactComponent as AgeImg } from '../../images/sco-image-girocard-hint.svg';
import Text from '../../typography/Text';

export default function CheckoutTerminalPendingAgeVerificationView() {
  const { t } = useTranslation();

  return (
    <div className="default">
      <div className="default__content">
        <AgeImg className="default__image" />
        <Text className="default__text">{t('age-verification.girocard')}</Text>
        <Text className="default__hint">{t('age-verification.abort')}</Text>
      </div>
    </div>
  );
}
