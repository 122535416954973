import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import getMetadata from './api/getMetadata';
import mapRawToDeviceMetadata from './mapRawToDeviceMetadata';
import Metadata from './Metadata';

export interface MetadataState {
  data: Metadata | undefined;
  loadingCount: number;
}

const initialState: MetadataState = {
  data: undefined,
  loadingCount: 0,
};

export const metadataIsRefreshing =
  (state: RootState) => state.metadata.loadingCount > 0;

export const refreshMetadata = createAsyncThunk(
  'metadata/refreshMetadata',
  async (_, { getState, signal }) => {
    const state = getState() as RootState;
    const { project, shop: shopId } = state.root.terminal.config!;
    const clientToken = state.token.main;
    const rawMetadata = await getMetadata({ clientToken, project, signal });
    return mapRawToDeviceMetadata(shopId, rawMetadata);
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const { config } = state.root.terminal;

      return (
        !!config?.project &&
        !!config?.shop &&
        !metadataIsRefreshing(state)
      );
    },
  },
);

/* eslint-disable no-param-reassign */
export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(refreshMetadata.pending, (state) => {
        state.loadingCount += 1;
      })
      .addCase(refreshMetadata.fulfilled, (state, action) => {
        state.loadingCount -= 1;
        state.data = action.payload;
      })
      .addCase(refreshMetadata.rejected, (state) => {
        state.loadingCount -= 1;
      });
  },
});
/* eslint-enable */

export default metadataSlice.reducer;
