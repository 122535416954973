import FulfillmentState from './FulfillmentState';
import Fulfillment from './Fulfillment';
import SummarizedFulfillmentState from './SummarizedFulfillmentState';

function isAllocatingState(state: FulfillmentState) {
  return [
    FulfillmentState.Open,
    FulfillmentState.Allocating,
  ].includes(state);
}

function isPendingState(state: FulfillmentState) {
  return [
    FulfillmentState.Allocated,
    FulfillmentState.Processing,
  ].includes(state);
}

function isFailedState(state: FulfillmentState) {
  return [
    FulfillmentState.Aborted,
    FulfillmentState.AllocationFailed,
    FulfillmentState.AllocationTimedOut,
    FulfillmentState.Failed,
  ].includes(state);
}

function isSucceededState(state: FulfillmentState) {
  return state === FulfillmentState.Processed;
}

export default function calculateSummarizedFulfillmentState(fulfillments: Fulfillment[]) {
  if (!fulfillments.length) return undefined;

  let hasAllocatingFulfillments = false;
  let hasPendingFulfillments = false;
  let hasFailedFulfillments = false;
  let succeededFulfillments = 0;

  fulfillments.forEach((fulfillment) => {
    if (isAllocatingState(fulfillment.state)) {
      hasAllocatingFulfillments = true;
      return;
    }
    if (isPendingState(fulfillment.state)) {
      hasPendingFulfillments = true;
      return;
    }
    if (isFailedState(fulfillment.state)) {
      hasFailedFulfillments = true;
      return;
    }
    if (isSucceededState(fulfillment.state)) {
      succeededFulfillments += 1;
    }
  });

  if (hasFailedFulfillments) {
    return SummarizedFulfillmentState.Failed;
  }

  if (hasAllocatingFulfillments) {
    return SummarizedFulfillmentState.Allocating;
  }

  if (hasPendingFulfillments) {
    return SummarizedFulfillmentState.Pending;
  }

  if (succeededFulfillments === fulfillments.length) {
    return SummarizedFulfillmentState.Succeeded;
  }

  return undefined;
}
