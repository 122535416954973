import ApiOptions from '../../api/ApiOptions';
import client from '../../api/client';
import loggedError from '../../api/loggedError';
import RawMetadata from '../RawMetadata';

export interface GetMetadataOptions extends ApiOptions {
  clientToken: string;
  project: string;
}

export default async function getMetadata({
  clientToken,
  project,
  signal,
}: GetMetadataOptions) {
  try {
    const resp = await client.get<RawMetadata>(`/metadata/sco/${project}`, {
      headers: {
        'Client-Token': clientToken,
      },
      signal,
    });
    return resp.data;
  } catch (e) {
    throw loggedError(
      'metadata_not_fetchable',
      'Metadata could not be fetched',
      e,
    );
  }
}
