import React from 'react';
import SessionOrigin from '../../session/SessionOrigin';
import { useAppSelector } from '../../store';
import BackToCartView from '../BackToCartView';

export default function CheckoutAbortedView() {
  const isAppCart = useAppSelector(state => state.session.origin === SessionOrigin.App);

  return (<BackToCartView messageId="error.aborted" terminate={isAppCart} />);
}
