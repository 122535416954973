import React, { useMemo } from 'react';
import { Route, Router } from '../../routing';
import PaymentMethod from '../../payment-method/PaymentMethod';
import CheckoutProcessingTerminalPaymentView from './CheckoutProcessingTerminalPaymentView';
import CheckoutProcessingExternalPaymentView from './CheckoutProcessingExternalPaymentView';
import { useAppSelector } from '../../store';

enum View {
  ExternalPayment,
  TerminalPayment,
}

export default function CheckoutProcessingView() {
  const paymentMethod = useAppSelector(state =>
    state.session.checkoutProcess?.paymentMethod);

  const currentView = useMemo(() => {
    if (paymentMethod === PaymentMethod.GatekeeperTerminal) {
      return View.TerminalPayment;
    }
    return View.ExternalPayment;
  }, [paymentMethod]);

  return (
    <Router state={currentView}>
      <Route when={View.TerminalPayment}>
        <CheckoutProcessingTerminalPaymentView />
      </Route>
      <Route when={View.ExternalPayment}>
        <CheckoutProcessingExternalPaymentView />
      </Route>
    </Router>
  );
}
