import resolveScannableCodeFromProduct from './resolveScannableCodeFromProduct';
import ScannedProductInfo from './ScannedProductInfo';

export default function resolveEncodingUnit(productInfo: ScannedProductInfo) {
  const code = resolveScannableCodeFromProduct(productInfo);
  if (code && code.encodingUnit) {
    return code.encodingUnit;
  }

  return productInfo.product.encodingUnit;
}
