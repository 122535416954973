import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { isDevEnv } from '../env';
import { usePoller } from '../polling';
import SchedulerContext from './SchedulerContext';
import TickCallback from './TickCallback';

export interface SchedulerProviderOptions {
  checkInterval?: number;
  children: ReactNode;
}

/**
 * Provides a global timer that can be consumed in child components utilizing
 * the `useScheduled` hook.
 */
export default function SchedulerProvider({
  checkInterval = 1000,
  children,
}: SchedulerProviderOptions) {
  const [hasSubscriptions, setHasSubscriptions] = useState(false);
  const subscriptionsRef = useRef(new Set<TickCallback>());

  const handleSubscribe = useCallback((callback: TickCallback) => {
    subscriptionsRef.current.add(callback);
    setHasSubscriptions(true);
  }, []);

  const handleUnsubscribe = useCallback((callback: TickCallback) => {
    subscriptionsRef.current.delete(callback);
    setHasSubscriptions(subscriptionsRef.current.size > 0);
  }, []);

  const handleTick = useCallback((abortSignal: AbortSignal) => {
    subscriptionsRef.current.forEach((callback) => {
      try {
        callback(abortSignal);
      } catch (e) {
        if (!isDevEnv) return;
        // eslint-disable-next-line no-console
        console.error('[scheduled]', e);
      }
    });
  }, []);

  usePoller({
    onTick: handleTick,
    disabled: !hasSubscriptions,
    interval: checkInterval,
    immediate: true,
  });

  return (
    <SchedulerContext.Provider
      value={{
        subscribe: handleSubscribe,
        unsubscribe: handleUnsubscribe,
      }}
    >
      {children}
    </SchedulerContext.Provider>
  );
}
