import React from 'react';
import { useAppDispatch } from '../../store';
import CheckoutCigaretteDispenseView from './CheckoutCigaretteDispenseView';
import ErrorView from '../../error/ErrorView';
import SummarizedFulfillmentState from '../../fulfillment/SummarizedFulfillmentState';
import { resetSession } from '../../session';

export interface CheckoutFulfillmentsViewProps {
  fulfillmentState: SummarizedFulfillmentState;
}

export default function CheckoutFulfillmentsView({
  fulfillmentState,
}: CheckoutFulfillmentsViewProps) {
  const dispatch = useAppDispatch();

  const handleGoBackToStart = () => {
    dispatch(resetSession());
  };

  return ((
    <>
      {fulfillmentState === SummarizedFulfillmentState.Pending &&
        <CheckoutCigaretteDispenseView />
      }

      {fulfillmentState === SummarizedFulfillmentState.Failed &&
        <ErrorView
          messageId="error.dispensing-cigarettes"
          onBack={handleGoBackToStart}
        />
      }
    </>
  ));
}
