import { useEffect, useRef } from 'react';
import PollerOptions from './PollerOptions';
import durationToMilliseconds from '../time/durationToMilliseconds';
import { isDevEnv } from '../env';

export default function usePoller({
  onTick,
  onError,
  onMaxAttempts,
  disabled = false,
  interval,
  maxAttempts,
  immediate = false,
}: PollerOptions) {
  const attemptsRef = useRef(0);
  const intervalMs = durationToMilliseconds(interval);

  useEffect(() => {
    if (disabled) return () => {};

    let abortController: AbortController | undefined;
    let timer: NodeJS.Timer | undefined;

    const tick = () => {
      // abort previously running task
      abortController?.abort();

      if (attemptsRef.current === maxAttempts) {
        clearInterval(timer);
        onMaxAttempts?.();
        return;
      }

      abortController = new AbortController();

      try {
        onTick(abortController.signal);
      } catch (e) {
        if (isDevEnv) {
          // eslint-disable-next-line no-console
          console.error('[poller]', e);
        }
        onError?.(e);
      }

      attemptsRef.current += 1;
    };

    if (immediate) {
      tick();
    }

    timer = setInterval(tick, intervalMs);

    return () => {
      clearInterval(timer);
      abortController?.abort();
      attemptsRef.current = 0;
    };
  }, [
    onTick,
    onError,
    onMaxAttempts,
    disabled,
    immediate,
    intervalMs,
    maxAttempts,
  ]);
}
