import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import trimEnd from 'lodash/trimEnd';
import './button.scss';

// https://patrickhlauke.github.io/touch/touchscreen-detection/
const detectTouchScreen = () => {
  if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
    return navigator.maxTouchPoints > 0;
  }
  if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
    return true;
  } else if (window.TouchEvent || ('ontouchstart' in window)) {
    return true;
  }
  return false;
};

const variants = {
  plain: '',
  unelevated: 'button button--unelevated',
  outlined: 'button button--outlined',
};

function renderChildren(children, variant) {
  const element = {
    plain: children,
    unelevated: <span>{children}</span>,
    outlined: <span>{children}</span>,
  };
  return element[variant];
}

function classes(variant, className, modifier) {
  const basicClasses = trimEnd(`${variants[variant]} ${className}`);
  return trimEnd(`${basicClasses} ${modifier}`);
}

class Button extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
      lastX: 0,
      lastY: 0,
      isTouch: detectTouchScreen(),
      moving: 0,
      modifier: '',
      rippleVars: {},
    };
    this.touchDistanceDetection = this.touchDistanceDetection.bind(this);
    this.touchStarts = this.touchStarts.bind(this);
    this.touchEnds = this.touchEnds.bind(this);
    this.click = this.click.bind(this);
  }
  touchStarts(event) {
    if (!this.state.isTouch || this.props.disabled) return;

    if (event?.currentTarget && event?.targetTouches && event.targetTouches[0]) {
      const t = event.targetTouches[0];
      const button = event.currentTarget;
      const rippleSize = Math.max(button.clientWidth, button.clientHeight);
      const rippleRadius = rippleSize / 2;
      this.setState({
        x: t.clientX,
        y: t.clientY,
        lastX: t.clientX,
        lastY: t.clientY,
        moving: 0,
        modifier: 'button--touch-starts',
        rippleVars: {
          '--button-ripple-size': `${rippleSize}px`,
          '--button-ripple-top': `${t.clientY - (button.offsetTop + rippleRadius)}px`,
          '--button-ripple-left': `${t.clientX - (button.offsetLeft + rippleRadius)}px`,
        },
      });
    }
  }
  touchDistanceDetection(event) {
    if (!this.state.isTouch || this.props.disabled) return;
    if (event && event.targetTouches && event.targetTouches[0]) {
      const t = event.targetTouches[0];
      this.setState({
        lastX: t.clientX,
        lastY: t.clientY,
        moving: 1 + this.state.moving,
      });
    }
  }
  touchEnds(event) {
    if (!this.state.isTouch || this.props.disabled) return;
    const x = Math.abs(this.state.x - this.state.lastX);
    const y = Math.abs(this.state.y - this.state.lastY);
    if (x < 50 && y < 50 && this.state.moving < 10) {
      this.props.onClick(event);
    }
    this.setState({
      x: 0,
      y: 0,
      lastX: 0,
      lastY: 0,
      moving: 0,
      modifier: '',
    });
  }
  click(event) {
    if (!this.state.isTouch || this.props.disabled) {
      this.props.onClick(event);
    }
  }
  render() {
    const {
      children, disabled, style, variant, className, title,
    } = this.props;
    return (
      <button
        disabled={disabled}
        className={classes(variant, className, this.state.modifier)}
        onContextMenu={event => event.preventDefault()}
        onClick={event => this.click(event)}
        onTouchStart={event => this.touchStarts(event)}
        onTouchMove={event => this.touchDistanceDetection(event)}
        onTouchEnd={event => this.touchEnds(event)}
        style={Object.assign({}, style, this.state.rippleVars)}
        title={title}
      >
        {renderChildren(children, variant)}
      </button>
    );
  }
}

Button.defaultProps = {
  variant: 'outlined',
  disabled: false,
  className: '',
  children: null,
  style: {},
  title: null,
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(keys(variants)),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string,
};

export default Button;
