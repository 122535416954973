enum PrinterStatus {
  Busy = 'busy',
  DoorOpen = 'door open',
  DriverUpdateNeeded = 'driver update needed',
  Error = 'error',
  Initializing = 'initializing',
  IoActive = 'io active',
  ManualFeed = 'manual feed',
  NotAvailable = 'not available',
  NoToner = 'no toner',
  Offline = 'offline',
  OutOfMemory = 'out of memory',
  OutputBinFull = 'output bin full',
  PagePunt = 'page punt',
  PaperJam = 'paper jam',
  PaperOut = 'paper out',
  PaperProblem = 'paper problem',
  Paused = 'paused',
  PendingDeletion = 'pending deletion',
  PowerSafe = 'power safe',
  Printing = 'printing',
  Processing = 'processing',
  Ready = 'ready',
  ServerOffline = 'server offline',
  ServerUnknown = 'server unknown',
  TonerLow = 'toner low',
  UserIntervention = 'user intervention',
  Waiting = 'waiting',
  WarmingUp = 'warming up',
}

export default PrinterStatus;
