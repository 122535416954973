/**
 * The unit type that may be encoded in the price. There may be other encoding
 * units than the ones defined here, but these are currently the only ones we
 * handle.
 */
enum EncodingUnit {
  Grams = 'g',
  Piece = 'piece',
  Price = 'price',
}

export default EncodingUnit;
