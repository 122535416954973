import { Draft, PayloadAction } from '@reduxjs/toolkit';
import SessionState from '../SessionState';

export default function removeErrorItemFromCart(
  state: Draft<SessionState>,
  action: PayloadAction<string>,
) {
  const { errorItems } = state.content;
  const index = errorItems.findIndex(item => item.id === action.payload);
  if (index < 0) return;

  errorItems.splice(index, 1);
}
