import client from '../../api/client';
import CheckoutProcess from '../CheckoutProcess';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import PaymentMethod from '../../payment-method/PaymentMethod';
import SignedCheckoutInfo from '../../checkout-info/SignedCheckoutInfo';
import loggedError from '../../api/loggedError';

export interface PaymentInformation {
  originType: string;
  cardNumber?: string;
  subject?: string;
  encryptedOrigin?: string;
}

export interface CreateCheckoutParams {
  signedCheckoutInfo: SignedCheckoutInfo;
  paymentMethod: PaymentMethod;
  paymentInformation?: PaymentInformation;
}

export interface CreateCheckoutOptions extends AuthenticatedApiOptions {
  project: string;
  params: CreateCheckoutParams;
}

export default async function createCheckout({
  clientToken,
  params,
  project,
  signal,
}: CreateCheckoutOptions) {
  try {
    const response = await client.post<CheckoutProcess>(
      `/${project}/sco/process`,
      params,
      {
        headers: {
          'Client-Token': clientToken,
        },
        signal,
      },
    );
    return response.data;
  } catch (e) {
    throw loggedError(
      'process_not_creatable',
      'Checkout process could not be created',
      e,
    );
  }
}
