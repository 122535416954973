import ErrorWithCode from '../error/ErrorWithCode';
import { RootState } from '../store';

export default function requireTerminalConfig(state: RootState) {
  const { config } = state.root.terminal;
  if (!config) {
    throw new ErrorWithCode(
      'missing_terminal_config',
      'No terminal config loaded',
    );
  }

  return config;
}
