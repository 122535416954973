import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import { isTestEnv } from '../env';
import isRetryableError from './isRetryableError';

const DEFAULT_TIMEOUT = 10000;
const MAX_RETRIES = 5;

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const DEFAULT_RETRY_CONFIG: IAxiosRetryConfig = {
  // do never retry in test env by default
  retries: isTestEnv ? 0 : MAX_RETRIES,
  retryCondition: isRetryableError,
  retryDelay: axiosRetry.exponentialDelay,
  shouldResetTimeout: true,
};

export default function createClient(config?: AxiosRequestConfig): AxiosInstance {
  const client = axios.create({
    timeout: DEFAULT_TIMEOUT,
    headers: DEFAULT_HEADERS,
    ...config,
  });

  axiosRetry(client, DEFAULT_RETRY_CONFIG);

  return client;
}
