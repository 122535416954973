import { v4 as uuidv4 } from 'uuid';
import { Draft } from '@reduxjs/toolkit';
import SessionState from '../SessionState';
import SessionStatus from '../SessionStatus';
import { initialSessionContent } from '../SessionContent';
import SessionOrigin from '../SessionOrigin';

export default function resetSession(state: Draft<SessionState>) {
  /* eslint-disable no-param-reassign */
  state.checkoutProcess = undefined;
  state.content = initialSessionContent;
  state.customerReceipt = undefined;
  state.externalBilling = undefined;
  state.failureCause = undefined;
  state.iban = undefined;
  state.id = uuidv4();
  state.origin = SessionOrigin.SCO;
  state.status = SessionStatus.Cart;
  /* eslint-enable */
}
