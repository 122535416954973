import parseInt from 'lodash/parseInt';
import ErrorWithCode from '../../error/ErrorWithCode';
import CustomerCardProvider from './CustomerCardProvider';

// sample card numbers:
// 10-digit customer number: 5010961962
// EAN-13: 2405010961965
// 16-digit: 3083425010961962
//
// see https://docs.google.com/document/d/1abdqdIAJ1yN2pXwmOEXMes5dlYZ5nTvT

const prefix = '308342';
const eanPrefix = '240';

function isEven(value: number) {
  return value % 2 === 0;
}

function isNumericString(value: string) {
  return value.match(/^\d+$/);
}

function calcLuhnChecksum(code: string) {
  if (!isNumericString(code)) return '';

  const len = code.length;
  let sum = 0;

  for (let index = len - 1; index >= 0; index -= 1) {
    let d = parseInt(code.charAt(index));

    if (isEven(index)) {
      d *= 2;
    }

    if (d > 9) {
      d -= 9;
    }

    sum += d;
  }
  sum %= 10;
  return (sum === 0 ? 0 : 10 - sum).toString();
}

function isLuhnChecksumValid(value: string) {
  const plainNumber = value.slice(0, -1);
  const expectedNumberWithChecksum = `${plainNumber}${calcLuhnChecksum(plainNumber)}`;
  return expectedNumberWithChecksum === value;
}

function isPaybackCardNumber(code: string) {
  switch (code.length) {
    case 10:
      return isLuhnChecksumValid(prefix + code);
    case 13:
      return code.startsWith(eanPrefix);
    case 16:
      return code.startsWith(prefix) && isLuhnChecksumValid(code);
    default:
      return false;
  }
}

function padPaybackCardNumber(code: string) {
  if (!isPaybackCardNumber(code)) {
    throw new ErrorWithCode(
      'invalid_customer_card',
      'Invalid Payback card number',
    );
  }

  let fullCardNumber = code;

  switch (code.length) {
    case 10:
      return `${prefix}${fullCardNumber}`;
    case 13:
      fullCardNumber = fullCardNumber.slice(3);
      fullCardNumber = fullCardNumber.slice(0, -1);
      fullCardNumber = `${prefix}${fullCardNumber}`;
      return fullCardNumber + calcLuhnChecksum(fullCardNumber);
    default:
      return fullCardNumber;
  }
}

const provider: CustomerCardProvider = {
  name: 'payback',
  validateCode: isPaybackCardNumber,
  sanitizeCode: padPaybackCardNumber,
};

export default provider;
