import CheckoutInfoViolation from './CheckoutInfoViolation';
import CheckoutInfoViolationType from './CheckoutInfoViolationType';

export default function groupViolationsByReference(violations: CheckoutInfoViolation[]) {
  const map = new Map<string, CheckoutInfoViolationType[]>();
  if (!violations) return map;

  violations.forEach((violation) => {
    const refViolations = map.get(violation.refersTo) ?? [];
    refViolations.push(violation.type);
    map.set(violation.refersTo, refViolations);
  });

  return map;
}
