import ExternalBillingProvider from './ExternalBillingProvider';
import projectID from '../../ProjectID';

function isLeinweberCustomerNumber(value: string) {
  return /^\d{6}$/.test(value);
}

const provider: ExternalBillingProvider = {
  project: projectID.leinweber,
  originType: 'leinweberCustomerID',
  validateCode: isLeinweberCustomerNumber,
};

export default provider;
