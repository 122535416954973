import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import './pop-up.scss';
// @ts-ignore
import Button from '../button/Button';

export interface PopUpProps {
  children: ReactNode;
  open?: Boolean,
  onClose: () => void;
}

export default function PopUp({
  children,
  open = false,
  onClose,
}: PopUpProps) {
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <div className="pop-up">
      <div className="pop-up__dialog">
        <div className="pop-up__dialog__content">
          {children}
        </div>
        <Button
          variant="unelevated"
          className="pop-up__dialog__button"
          onClick={onClose}
        >
          {t('btn.close')}
        </Button>
      </div>
    </div>
  );
}
