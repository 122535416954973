import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';

type MapperFn = (
  dispatch: Dispatch<AnyAction>,
  getRootState?: () => any,
) => any;

/**
 * @deprecated Prefer migration to Redux Toolkit
 */
export default function mapLegacyRootThunk(mapper: MapperFn) {
  return (dispatch: Dispatch<AnyAction>, getState: () => ({ root: any })) =>
    mapper(dispatch, () => getState().root);
}
