import { RootState } from '../../../store';
import addCoupon from './addCoupon';
import addProduct from './addProduct';
import ErrorWithCode from '../../../error/ErrorWithCode';
import findCouponInfoForCodes from '../../../coupon/findCouponInfoForCodes';
import findProductInfoForCodes from '../../../product/findProductInfoForCodes';
import CartItemChangeSet from '../modification/CartItemChangeSet';
import requireMetadata from '../../../metadata/requireMetadata';
import resolveScannedCodes from '../../../barcode/resolveScannedCodes';
import logger from '../../../logging';

export interface AddCartItemOptions {
  scannedCode: string;
  amountToAdd?: number;
  index?: number;
}

function invalidCodeError(scannedCode: string) {
  logger.info(`Unable to find product for scanned code: ${scannedCode}`); // TODO move to Sentry
  return new ErrorWithCode(
    'invalid_code',
    `Invalid code '${scannedCode}'`,
  );
}

export default function add(state: RootState, {
  scannedCode,
  amountToAdd,
  index,
}: AddCartItemOptions): CartItemChangeSet {
  const { codeTemplates, priceOverrideCodes } = requireMetadata(state);

  const codes = resolveScannedCodes({
    code: scannedCode,
    templates: codeTemplates ?? {},
    priceOverrides: priceOverrideCodes,
  });
  if (codes.length === 0) {
    throw invalidCodeError(scannedCode);
  }

  const couponInfo = findCouponInfoForCodes(state.coupon.items, codes);
  if (couponInfo) {
    return addCoupon(state, { couponInfo, index });
  }

  const productInfo = findProductInfoForCodes(codes);
  if (productInfo) {
    return addProduct(state, { productInfo, amountToAdd, index });
  }

  throw invalidCodeError(scannedCode);
}
