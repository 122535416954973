import ApiOptions from '../../api/ApiOptions';
import loggedError from '../../api/loggedError';
import terminalClient from '../../api/terminalClient';

/*
* The reconciliation request is necessary for ccv terminals
* with opi protocol which support postfinance cards.
* It triggers a terminal request which leads to some sort of closing.
* This may block the terminal for a while but otherwise the terminal
* will not be able to proceed postfinance cards. This seems to be a known
* issue as the developers said this is a requirement if we want to use this
* sort of terminals.
*/
export default async function initiateReconciliation({ signal }: ApiOptions = {}) {
  try {
    await terminalClient.post('/reconciliation', undefined, { signal });
  } catch (e) {
    throw loggedError(
      'reconciliation_not_initiatable',
      'Unable to initiate reconciliation',
      e,
    );
  }
}
