import CheckoutProcess from '../../checkout-process/CheckoutProcess';
import logger from '../../logging';
import { UpdateCheckoutPaymentStatusOptions } from '../../session/actions/updateCheckoutPaymentStatus';
import { unshippedCheckoutsDb } from './unshippedCheckoutsDb';

export interface UnshippedCheckout {
  checkout: CheckoutProcess;
  paymentStatusUpdate: UpdateCheckoutPaymentStatusOptions;
  projectId: string;
}

export default async function addUnshippedCheckout({
  checkout,
  paymentStatusUpdate,
  projectId,
}: UnshippedCheckout) {
  try {
    await unshippedCheckoutsDb.processes.add({
      checkoutProcessId: checkout.id,
      links: checkout.links,
      paymentStatusUpdate,
      projectId,
      shopId: checkout.shopID,
    });
  } catch (e) {
    logger.error(`Unable to save unshipped checkout to db. Checkout process id: "${checkout.id}", payment status: "${paymentStatusUpdate.status}", project id: "${projectId}", shop id: "${checkout.shopID}, original error: "${(e as any).message}"`, { tag: 'Checkout' }); // TODO move to Sentry
  }
}
