enum TerminalFailureCause {
  /**
   * The user aborted the payment.
   */
  UserAborted = 'terminalAbort',

  /**
   * The payment failed.
   */
  TerminalFailed = 'terminalFailed',

  /**
   * The terminal errored.
   */
  TerminalErrored = 'terminalErrored',

  /**
   * The user is not old enough.
   */
  AgeVerificationFailed = 'ageVerificationFailed',

  /**
   * The users card does not support age verifications.
   */
  AgeVerificationNotSupportedByCard = 'ageVerificationNotSupportedByCard',
}

export default TerminalFailureCause;

