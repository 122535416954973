import Big from 'big.js';
import isString from 'lodash/isString';
import parseInt from 'lodash/parseInt';
// @ts-ignore
import parseBarcode from '../../helper/BarcodeParser';
import GS1CodeInfo from './GS1CodeInfo';

const GS1_CODE_MIN_LENGTH = 16;
const PRICE_AI_PREFIX = '390';
const UNITS_AI_PREFIX = '30';
const WEIGHT_AI_PREFIX = '310';

interface ParsedCode {
  parsedCodeItems: ParsedCodeItem[];
}

interface ParsedCodeItem {
  data: string | Big;
  ai: string;
  unit: string;
}

function findParsedCodeByAIPrefix(
  code: ParsedCode,
  prefix: string,
) {
  return code.parsedCodeItems.find(item => item.ai.startsWith(prefix));
}

function isValidGS1Code(code: ParsedCode) {
  if (!code) {
    return false;
  }

  const counts: Record<string, number> = {};
  code.parsedCodeItems.forEach((item) => {
    counts[item.ai] = (counts[item.ai] ?? 0) + 1;
  });

  return Object.values(counts).some(count => count === 1);
}

function extractWeight(code: ParsedCode) {
  const codeData = findParsedCodeByAIPrefix(code, WEIGHT_AI_PREFIX);
  if (!codeData || !(codeData.data instanceof Big)) return undefined;

  const decimalDigits = parseInt(codeData.ai.substring(WEIGHT_AI_PREFIX.length));
  if (Number.isNaN(decimalDigits)) return undefined;

  codeData.data.e += decimalDigits;
  return codeData.data.round(0).toNumber();
}

function extractUnits(code: ParsedCode) {
  const codeData = findParsedCodeByAIPrefix(code, UNITS_AI_PREFIX);
  if (!codeData || !isString(codeData.data)) return undefined;

  const units = parseInt(codeData.data);
  if (Number.isNaN(units)) return undefined;
  return units;
}

function extractPrice(code: ParsedCode) {
  const codeData = findParsedCodeByAIPrefix(code, PRICE_AI_PREFIX);
  if (!codeData || !(codeData.data instanceof Big)) return undefined;

  const decimalDigits = parseInt(codeData.ai.substring(PRICE_AI_PREFIX.length));
  if (Number.isNaN(decimalDigits)) return undefined;

  codeData.data.e += decimalDigits;
  return codeData.data.round(0).toNumber();
}

// This was moved here from the old ProductDb code resolver
export default function parseGS1Code(code: string): GS1CodeInfo | undefined {
  // Supported gs1 codes have at least 14 + 2 chars (the AI `01` and a GTIN-14)
  if (code.length < GS1_CODE_MIN_LENGTH) {
    return undefined;
  }

  let gs1Code: ParsedCode;
  let aiGTIN: ParsedCodeItem | undefined;
  try {
    gs1Code = parseBarcode(code);

    if (!isValidGS1Code(gs1Code)) {
      return undefined;
    }

    aiGTIN = gs1Code.parsedCodeItems.find(item => item.ai === '01');
  } catch (e) {
    // Not a valid gs1 code skip
    return undefined;
  }

  // Only codes containing a GTIN are supported
  if (!aiGTIN) {
    return undefined;
  }

  return {
    code: aiGTIN.data as string,
    weight: extractWeight(gs1Code),
    units: extractUnits(gs1Code),
    price: extractPrice(gs1Code),
  };
}
