import ApiOptions from '../../api/ApiOptions';
import loggedError from '../../api/loggedError';
import terminalClient from '../../api/terminalClient';

interface CreatePaymentParams {
  /**
   * The total price.
   */
  totalPrice: number;

  /**
   * The checkout process ID.
   */
  checkoutId: string;

  /**
   * The actual minimum age of the app user.
   */
  minimumAge?: number;
}

export interface CreatePaymentOptions extends ApiOptions {
  params: CreatePaymentParams;
}

export default async function createPayment({ params, signal }: CreatePaymentOptions) {
  try {
    await terminalClient.post('/pay', {
      amount: params.totalPrice,
      checkoutId: params.checkoutId,
      minimumAge: params.minimumAge ?? 0,
    }, { signal });
  } catch (e) {
    throw loggedError(
      'payment_not_creatable',
      'Payment could not be created',
      e,
    );
  }
}
