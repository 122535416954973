import React from 'react';
import './dialog-slot.scss';

export const DIALOG_SLOT_ID = 'dialog_slot';

/**
 * A component that provides a container element in which dialog views are
 * rendered. It is expected to be only mounted once in the app.
 */
export default function DialogSlot() {
  return <div id={DIALOG_SLOT_ID} className="dialog-slot" />;
}
