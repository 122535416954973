import CartItemType from './CartItemType';
import CouponCartItem from './CouponCartItem';
import ProductCartItem from './ProductCartItem';

type CartItem = ProductCartItem | CouponCartItem;

export default CartItem;

export type TypedCartItem<T extends CartItemType> = CartItem & { type: T };

export function isProductCartItem(item: CartItem): item is ProductCartItem {
  return item.type === CartItemType.Product;
}

export function isCouponCartItem(item: CartItem): item is CouponCartItem {
  return item.type === CartItemType.Coupon;
}
