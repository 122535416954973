import React, { useMemo } from 'react';
import { useAppSelector } from './store';
import CartMutationProvider from './cart/CartMutationProvider';
import ConfigManager from './config/ConfigManager';
import DialogSlot from './dialog/DialogSlot';
import GlobalErrorHandler from './error/GlobalErrorHandler';
import LanguageDetector from './language/LanguageDetector';
import LayoutView from './LayoutView';
import LedDebugProvider from './led/debug/LedDebugProvider';
import OfflineView from './error/OfflineView';
import ScannerProvider from './scanner/ScannerProvider';
import Theme from './theme/Theme';
import ToastManager from './toast/ToastManager';
import { SchedulerProvider } from './schedule';
import useDeviceTypeConfig from './device-type/useDeviceTypeConfig';
import AppUpdatesHandler from './app-updates/AppUpdatesHandler';
import UnshippedCheckoutsManager from './checkout/unshipped/UnshippedCheckoutsManager';
import KeyboardSlot from './keyboard/KeyboardSlot';

enum InitializationType {
  Bare,
  Full,
}

export default function App() {
  // TODO: Do we need to do extra steps to load shop / fallback config?
  const hasTerminalConfig = useAppSelector(state => !!state.root.terminal.config);
  const hasInitializedDeviceSettings = useAppSelector(state =>
    state.checkoutDevice.initialized);

  const hasMetadata = useAppSelector(state => !!state.metadata.data);
  const hasInitializedAppDb = useAppSelector(state => state.appDb.isInitialized);

  const deviceTypeConfig = useDeviceTypeConfig();
  const initializationType = useMemo(() => {
    if (deviceTypeConfig?.fetchMetadata && deviceTypeConfig?.initAppDb) {
      return InitializationType.Full;
    }

    return InitializationType.Bare;
  }, [deviceTypeConfig]);

  const isLoaded = useMemo(() => {
    if (!hasTerminalConfig || !hasInitializedDeviceSettings) return false;

    switch (initializationType) {
      case InitializationType.Bare:
        return true;

      case InitializationType.Full:
        return hasMetadata && hasInitializedAppDb;

      default:
        return false;
    }
  }, [
    hasInitializedAppDb,
    hasInitializedDeviceSettings,
    hasMetadata,
    hasTerminalConfig,
    initializationType,
  ]);

  return (
    <SchedulerProvider>
      <ConfigManager />
      <LanguageDetector />
      <AppUpdatesHandler />

      <Theme>
        <GlobalErrorHandler>
          <KeyboardSlot />

          {!isLoaded && <OfflineView />}
          {isLoaded && (
            <>
              <UnshippedCheckoutsManager />
              <ScannerProvider>
                <CartMutationProvider>
                  <DialogSlot />
                  <ToastManager />
                  <LedDebugProvider>
                    <LayoutView />
                  </LedDebugProvider>
                </CartMutationProvider>
              </ScannerProvider>
            </>
          )}
        </GlobalErrorHandler>
      </Theme>
    </SchedulerProvider>
  );
}
