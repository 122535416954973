import { useDebouncedState } from '@mantine/hooks';
import React, { useEffect, useRef } from 'react';
import Router, { RouterProps } from './Router';

const DEFAULT_DELAY = 500;

export interface DebouncedRouterProps extends RouterProps {
  delay?: number;
}

/**
 * The `DebouncedRouter` component provides a in-memory router that delays the
 * route change based on the change of passed `state` property to prevent
 * flickering or too sudden view changes.
 */
export default function DebouncedRouter({
  children,
  state,
  delay = DEFAULT_DELAY,
}: DebouncedRouterProps) {
  const [currentState, setCurrentState] = useDebouncedState(state, delay);
  const isMountedRef = useRef(false);

  useEffect(() => {
    // Prevents the effect hook from being invoked when the component is mounted
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return;
    }

    setCurrentState(state);
  }, [state, setCurrentState]);

  return (
    <Router state={currentState}>
      {children}
    </Router>
  );
}
