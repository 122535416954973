import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { RootState } from '../../store';
import addErrorItem from '../helpers/addErrorItem';
import applyChanges from '../content/modification/applyChanges';
import CartItemChangeSet from '../content/modification/CartItemChangeSet';
import CheckoutInfoError from '../../checkout-info/CheckoutInfoError';
import clearCartItems from '../helpers/clearCartItems';
import createCheckoutInfo from '../../checkout-info/api/createCheckoutInfo';
import CustomerCard from '../../customer-card/CustomerCard';
import ErrorItem from '../content/ErrorItem';
import extractProductsFromCartItemsAndChanges from '../content/mapping/product/extractProductsFromCartItemsAndChanges';
import groupViolationsByReference from '../../checkout-info/groupViolationsByReference';
import isCheckoutInfoErrorData from '../../checkout-info/isCheckoutInfoErrorData';
import mapLineItemsToCartItems from '../content/mapping/mapLineItemsToCartItems';
import mapScannedItemInfos from '../content/mapping/mapScannedItemInfos';
import requireTerminalConfig from '../../terminal/requireTerminalConfig';
import resolveCompatiblePaymentMethods, { resolveAcceptedPaymentOrigins } from '../../payment-method/resolveCompatiblePaymentMethods';
import SessionState from '../SessionState';
import SessionStatus from '../SessionStatus';
import ExternalBilling from '../../external-billing/ExternalBilling';
import hasNullPriceItems from '../../checkout-info/hasNullPriceItems';

interface ChangeCartOptions {
  itemChanges: CartItemChangeSet;
  customerCard: CustomerCard | undefined;
  externalBilling: ExternalBilling | undefined;
}

enum ChangeCartResultStatus {
  Unchanged = 'unchanged',
  ChangedWithItems = 'changedWithItems',
  ChangedWithoutItems = 'changedWithoutItems',
}

const changeCart = createAsyncThunk(
  'session/changeCart',
  async ({
    itemChanges,
    customerCard,
    externalBilling,
  }: ChangeCartOptions, {
    getState, rejectWithValue, signal,
  }) => {
    const state = getState() as RootState;

    const {
      id: checkoutDeviceId,
      project,
      shop: shopId,
    } = requireTerminalConfig(state);

    const customerCardChanged = !isEqual(
      state.session.content.customerCard,
      customerCard,
    );

    // HACK Leinweber needs the externalBilling in the loyaltyCard field
    let externalBillingChanged = false;
    let customerCardCodeOverwrite;
    if (project === 'leinweber-baucentrum-5cvbt954') {
      externalBillingChanged = !isEqual(
        state.session.externalBilling,
        externalBilling,
      );
      customerCardCodeOverwrite = externalBilling?.code;
    }

    if (itemChanges.length === 0 && !customerCardChanged && !externalBillingChanged) {
      return { status: ChangeCartResultStatus.Unchanged };
    }

    const items = applyChanges(state.session.content.cartItems, itemChanges);

    // when no line items are available, the API does not allow to create a new
    // checkout info
    if (items.length === 0) {
      return { status: ChangeCartResultStatus.ChangedWithoutItems };
    }

    try {
      const signedCheckoutInfo = await createCheckoutInfo({
        clientToken: state.token.main,
        params: {
          checkoutDeviceID: checkoutDeviceId,
          items,
          session: state.session.id,
          shopId,
          customer: {
            loyaltyCard: (customerCard?.code || customerCardCodeOverwrite),
          },
        },
        project,
        signal,
      });

      const error = hasNullPriceItems(signedCheckoutInfo.checkoutInfo.lineItems);
      if (error) throw new CheckoutInfoError(error);

      return {
        status: ChangeCartResultStatus.ChangedWithItems,
        signedCheckoutInfo,
      };
    } catch (e) {
      if (e instanceof CheckoutInfoError) {
        return rejectWithValue(e.data);
      }
      throw e;
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      return state.session.status === SessionStatus.Cart;
    },
  },
);

export default changeCart;

export function registerChangeCartReducers(builder: ActionReducerMapBuilder<SessionState>) {
  /* eslint-disable no-param-reassign */
  builder
    .addCase(changeCart.pending, (state) => {
      state.loadingCount += 1;
    })
    .addCase(changeCart.fulfilled, (state, action) => {
      state.loadingCount -= 1;

      const { status } = action.payload;

      if (status === ChangeCartResultStatus.Unchanged) {
        return;
      }

      const { itemChanges, customerCard } = action.meta.arg;

      state.content.customerCard = customerCard;

      if (status === ChangeCartResultStatus.ChangedWithoutItems) {
        clearCartItems(state);
        return;
      }

      const itemInfos = mapScannedItemInfos(state.content.cartItems, itemChanges);
      const signedCheckoutInfo = action.payload.signedCheckoutInfo!;

      if (!signedCheckoutInfo) {
        return;
      }

      const { checkoutInfo } = signedCheckoutInfo;
      const violations = groupViolationsByReference(checkoutInfo.violations ?? []);

      state.content.signedCheckoutInfo = signedCheckoutInfo;
      state.content.cartItems = mapLineItemsToCartItems(
        checkoutInfo.lineItems,
        itemInfos,
        violations,
      );
      state.content.totalPrice = checkoutInfo.price.price;
      state.content.availablePaymentMethods =
        resolveCompatiblePaymentMethods(checkoutInfo.availableMethods);
      state.content.acceptedPaymentOrigins =
        resolveAcceptedPaymentOrigins(checkoutInfo.paymentMethods);
    })
    .addCase(changeCart.rejected, (state, action) => {
      state.loadingCount -= 1;

      const { itemChanges, customerCard } = action.meta.arg;
      let changedErrorItems: ErrorItem[] | undefined;

      if (isCheckoutInfoErrorData(action.payload)) {
        const products = extractProductsFromCartItemsAndChanges(
          state.content.cartItems,
          itemChanges,
        );

        action.payload.details.forEach((detail) => {
          const product = products.find(p => p.sku === detail.sku);
          if (!product) return;

          const { hasChanged, items } = addErrorItem(state.content.errorItems, {
            name: product.name,
            reason: detail.type,
            unique: true,
          });

          if (hasChanged) {
            changedErrorItems = items;
          }
        });
      }

      const customerCardChanged =
        !isEqual(state.content.customerCard, customerCard);

      if (customerCardChanged) {
        state.content.customerCard = customerCard;
      }

      if (changedErrorItems) {
        state.content.errorItems = changedErrorItems;
      }
    });
  /* eslint-enable no-param-reassign */
}
