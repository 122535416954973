import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import LanguageSwitch from '../language/LanguageSwitch';
import { LedState, useLed } from '../led';
import Headline from '../typography/Headline';
import Text from '../typography/Text';

export default function OfflineView() {
  const { t } = useTranslation();

  useLed(LedState.Off);

  return (
    <div className="offline">
      <LanguageSwitch />
      <div className="offline__content">
        <Headline level="1">{t('offline.headline')}</Headline>
        <Text>{t('offline.text')}</Text>
      </div>
    </div>
  );
}
