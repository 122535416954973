function findCheck(checks, type) {
  return (checks || []).find(check => check.type.indexOf(type) !== -1);
}

function requiredAge(check) {
  if (!check) return 0;
  return check.requiredAge || 0;
}

function paymentMethodVerifiesAge(check) {
  return !!check && check.state === 'postponed';
}

const allocatingFulfillment = state => ['open', 'allocating'].indexOf(state) !== -1;
const pendingFulfillment = state => ['allocated', 'processing'].indexOf(state) !== -1;
const failedFulfillment = state => ['aborted', 'allocationFailed', 'allocationTimedOut', 'failed'].indexOf(state) !== -1;
const succeededFulfillment = state => state === 'processed';

function fulfillmentState(fulfillments) {
  if (!fulfillments || !fulfillments.length) return '';

  let hasAllocating = false;
  let hasPending = false;
  let hasFailed = false;
  let succeeded = 0;

  fulfillments.forEach((fulfillment) => {
    if (allocatingFulfillment(fulfillment.state)) {
      hasAllocating = true;
    } else if (pendingFulfillment(fulfillment.state)) {
      hasPending = true;
    } else if (failedFulfillment(fulfillment.state)) {
      hasFailed = true;
    } else if (succeededFulfillment(fulfillment.state)) {
      succeeded += 1;
    }
  });

  if (hasAllocating) {
    return 'allocating';
  }

  if (hasPending) {
    return 'pending';
  }

  if (hasFailed) {
    return 'failed';
  }

  if (succeeded === fulfillments.length) {
    return 'succeeded';
  }
  // should not happen o_O
  return '';
}


function parseCheckoutProcess(process) {
  const { links, fulfillments } = process;
  const ageCheck = findCheck(process.checks, 'min_age');

  return {
    id: process.id,
    price: process.price,
    state: process.state,
    initialized: true,
    isLoading: false,
    hasInfoError: false,
    paymentMethod: process.paymentMethod,
    clientID: process.clientID,
    checks: process.checks,
    requiredAge: requiredAge(ageCheck),
    requiresUserAgeVerification: !!ageCheck,
    paymentMethodVerifiesAge: paymentMethodVerifiesAge(ageCheck),
    fulfillmentState: fulfillmentState(fulfillments),
    fulfillments: fulfillments || [],
    links: {
      self: links && links.self ? links.self.href : null,
      receipt: links && links.receipt ? links.receipt.href : null,
      originCandidate: links && links.originCandidate ? links.originCandidate.href : null,
      paymentStateProcessing: links && links.paymentProcessing ?
        links.paymentProcessing.href : null,
      paymentStateFailed: links && links.paymentFailed ? links.paymentFailed.href : null,
      paymentStateSucceeded: links && links.paymentSuccessful ? links.paymentSuccessful.href : null,
    },
  };
}

export {
  parseCheckoutProcess, fulfillmentState, requiredAge, findCheck, paymentMethodVerifiesAge,
};
