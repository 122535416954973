import { v4 as uuidv4 } from 'uuid';
import SessionContent, { initialSessionContent } from './SessionContent';
import ExternalBilling from '../external-billing/ExternalBilling';
import CheckoutProcess from '../checkout-process/CheckoutProcess';
import SessionStatus from './SessionStatus';
import SessionOrigin from './SessionOrigin';
import TerminalFailureCause from '../terminal/TerminalFailureCause';

export default interface SessionState {
  /**
   * The checkout process that is associated with this session. Is only defined
   * when the session status is `SessionStatus.Checkout`.
   */
  checkoutProcess: CheckoutProcess | undefined;

  /**
   * The session content such as cart items and errors.
   */
  content: SessionContent;

  /**
   * Contains the unformatted customer receipt string when the current session
   * is ready to provide a receipt.
   */
  customerReceipt: string | undefined;

  /**
   * Contains information about external billing that the user has selected for
   * the current session.
   */
  externalBilling: ExternalBilling | undefined;

  /**
   *
   */
  failureCause: TerminalFailureCause | undefined,

  /**
   * The IBAN that is used for payment.
   */
  iban: string | undefined;

  /**
   * The session ID. Is reset after each checkout.
   */
  id: string;

  /**
   * The number of loading data related to the session actions.
   */
  loadingCount: number;

  /**
   * The way the session has been initiated. Is `SessionOrigin.SCO` by default.
   */
  origin: SessionOrigin;

  /**
   * The session status. Is `SessionStatus.Cart` by default.
   */
  status: SessionStatus;
}

export const initialState: SessionState = {
  checkoutProcess: undefined,
  content: initialSessionContent,
  customerReceipt: undefined,
  externalBilling: undefined,
  failureCause: undefined,
  iban: undefined,
  id: uuidv4(),
  loadingCount: 0,
  origin: SessionOrigin.SCO,
  status: SessionStatus.Cart,
};
