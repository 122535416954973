import ScannedProductInfo from './ScannedProductInfo';
import resolveEncodingUnit from './resolveEncodingUnit';

export default function extractUnits(productInfo: ScannedProductInfo) {
  let units: number | undefined;
  const encodingUnit = resolveEncodingUnit(productInfo);

  if (!encodingUnit) {
    return undefined;
  }

  if (encodingUnit === 'piece') {
    units = productInfo.scannedCode.embed;
  }

  if (!units) {
    return undefined;
  }

  return units;
}
