import React, { ReactNode, useState } from 'react';
import OFF_COLOR from '../offColor';
import LedColorPreview from './LedColorPreview';
import LedDebugContext from './LedDebugContext';
import './led-color-preview.css';

const hasPreview = process.env.NODE_ENV === 'development';

interface LedDebugProviderProps {
  children: ReactNode;
}

export default function LedDebugProvider({ children }: LedDebugProviderProps) {
  const [color, setColor] = useState(OFF_COLOR);

  return (
    <LedDebugContext.Provider value={{ setColor }}>
      {hasPreview && <LedColorPreview color={color} />}
      {children}
    </LedDebugContext.Provider>
  );
}
