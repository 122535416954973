import CheckoutInfoItemParams from '../../../checkout-info/params/CheckoutInfoItemParams';
import CartItem, { isProductCartItem } from '../CartItem';
import mapCouponCartItemToParams from './coupon/mapCouponCartItemToParams';
import mapProductCartItemToParams from './product/mapProductCartItemToParams';

export default function mapCartItemToParams(item: CartItem): CheckoutInfoItemParams | undefined {
  if (isProductCartItem(item)) {
    return mapProductCartItemToParams(item);
  }

  return mapCouponCartItemToParams(item);
}
