import ScannedCouponInfo from '../coupon/ScannedCouponInfo';
import ScannedProductInfo from '../product/ScannedProductInfo';

type ScannedItemInfo = ScannedProductInfo | ScannedCouponInfo;

export function isScannedProductInfo(info: ScannedItemInfo): info is ScannedProductInfo {
  return 'product' in info;
}

export function isScannedCouponInfo(info: ScannedItemInfo): info is ScannedCouponInfo {
  return 'coupon' in info;
}

export default ScannedItemInfo;
