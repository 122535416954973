import axios from 'axios';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import client from '../../api/client';
import loggedError from '../../api/loggedError';
import CheckoutProcess from '../CheckoutProcess';
import CheckoutProcessState from '../CheckoutProcessState';

export interface PatchCheckoutParams {
  checkoutDeviceID: string;
  clientID: string;
  state: CheckoutProcessState;
}

export interface PatchCheckoutOptions extends AuthenticatedApiOptions {
  url: string;
  params: Partial<PatchCheckoutParams>;
}

export default async function patchCheckout({
  clientToken,
  url,
  params,
  signal,
}: PatchCheckoutOptions) {
  try {
    const response = await client.patch<CheckoutProcess>(
      url,
      params,
      {
        headers: {
          'Client-Token': clientToken,
        },
        signal,
      },
    );
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      throw loggedError(
        'process_not_found',
        'Checkout process not found',
      );
    }

    throw loggedError(
      'process_not_patchable',
      'Checkout process could not be patched',
      e,
    );
  }
}
