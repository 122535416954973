import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Translate from '../components/Translate';
import Button from '../button/Button';
import { ReactComponent as ErrorIcon } from '../images/sco-image-error.svg';
import Headline from '../typography/Headline';
import Text from '../typography/Text';

// TODO: Migrate all places using this ErrorView to src/error/ErrorView.tsx
const ErrorView = ({
  detail, goBack, text, timeOut,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      goBack();
    }, timeOut);

    return () => {
      clearTimeout(timer);
    };
  }, [goBack, timeOut]);

  return (
    <div className="default">
      <div className="default__content">
        <ErrorIcon className="default__image" />
        <Headline level="1"><Translate id="error.headline" /></Headline>
        <Text><Translate id={text} /></Text>
        {!!detail && <Text><Translate id="error.code" data={{ detail }} /></Text>}
        <Button
          variant="outlined"
          className="error__btn"
          onClick={() => { goBack(); }}>
          <Translate id="btn.back" />
        </Button>
      </div>
    </div>
  );
};

ErrorView.defaultProps = {
  detail: null,
  text: 'error.text',
  timeOut: 60000,
};

ErrorView.propTypes = {
  detail: PropTypes.string,
  text: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  timeOut: PropTypes.number,
};

export default ErrorView;
