import client from '../../api/client';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';

export interface GetPrintableReceiptContentOptions extends AuthenticatedApiOptions {
  url: string;
}

export default async function getPrintableReceiptContent({
  url,
  clientToken,
  signal,
}: GetPrintableReceiptContentOptions) {
  const response = await client.get<string>(url, {
    headers: {
      Accept: 'text/plain',
      'Client-Token': clientToken,
    },
    signal,
  });
  return response.data;
}
