import { connect } from 'react-redux';
import AssetSVG from './AssetSVG';
import lookUpInManifest from '../helper/assets';
import mapLegacyRootState from '../containers/mapLegacyRootState';

const AssetSVGContainer = connect(mapLegacyRootState((state, ownProps) => ({
  src: lookUpInManifest(
    state.assets.files,
    'svg',
    true,
    ownProps,
  ),
})))(AssetSVG);

export default AssetSVGContainer;
