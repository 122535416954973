import formatPrice from '../../price/formatPrice';
import { FallbackReceiptOptions } from './FallbackReceiptOptions';

export default function buildFallbackReceipt({
  checkoutId,
  checkoutState,
  paymentStatus,
  paymentMethod,
  priceOptions,
  customerReceipt,
}: FallbackReceiptOptions) {
  return `
%%Due to a technical problem, we can%%
%%currently only print the payment part of%%
%%the receipt.%%

%%snabble Checkout ID:%%
%%${checkoutId}%%
%%Payment state: ${paymentStatus}%%
%%Checkout state: ${checkoutState}%%
%%Payment method: ${paymentMethod}%%
%%Total: ${formatPrice(priceOptions)}%%

${customerReceipt}
`;
}
