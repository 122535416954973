import { RootState } from '../../store';

/**
 * Selector that determines whether the cart is currently being updated.
 *
 * @param state The root state.
 * @returns `true` when the cart is currently being updated, `false` when not.
 */
export default function isLoadingCart(state: RootState) {
  return state.session.loadingCount > 0;
}
