import { createElement, ReactNode } from 'react';
import './text.scss';

interface TextProps {
  children: ReactNode;
  className?: string;
  tagName?: string;
  handleLineBreaks?: boolean;
}

export default function Text({
  children,
  className,
  tagName = 'p',
  handleLineBreaks = false,
}: TextProps) {
  return createElement(tagName, {
    className: `text text--default ${className || ''}`,
    style: handleLineBreaks ? { whiteSpace: 'pre-line' } : {},
  }, children);
}
