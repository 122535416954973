import padStart from 'lodash/padStart';
import trimStart from 'lodash/trimStart';
import uniq from 'lodash/uniq';

const CODE_LENGTHS = [13, 12, 8];
const FILL_CHAR = '0';

/**
 * Generates different versions of the same input code (most likely GTIN of some
 * kind), applying trimming and padding of excess zeroes at the front.
 *
 * @param code - The input code
 *
 * @returns A list of generated code variants, including the input code itself
 */
export default function generateCodeVariants(code: string): string[] {
  const items = CODE_LENGTHS.map(codeLength =>
    padStart(trimStart(code, FILL_CHAR), codeLength, FILL_CHAR));
  items.unshift(code);
  return uniq(items);
}
