import React, { ReactNode } from 'react';
import keyBy from 'lodash/keyBy';
import Registry from '../registry/Registry';
import projectID from '../ProjectID';

// @ts-ignore
import AssetSVG from '../asset-svg/AssetSVGContainer';
// @ts-ignore
import { ReactComponent as FlagDe } from '../images/lang-de.svg';
// @ts-ignore
import { ReactComponent as FlagEn } from '../images/lang-en.svg';
// @ts-ignore
import { ReactComponent as FlagCh } from '../images/lang-ch.svg';

import de from '../locales/de/translation.json';
import ch from '../locales/ch/translation.json';
import en from '../locales/en/translation.json';
import pl from '../locales/pl/translation.json';
import deTegut from '../locales/de-tegut-eixae6/translation.json';
import enTegut from '../locales/en-tegut-eixae6/translation.json';
import plTegut from '../locales/pl-tegut-eixae6/translation.json';
import deGooods from '../locales/de-ch-migrolino-9ef12e/translation.json';
import enGooods from '../locales/en-migrolino-9ef12e/translation.json';
import deTobaccoland from '../locales/de-tobaccoland-4257p2/translation.json';
import enPanOston from '../locales/en-pan-oston-6093v2/translation.json';
import chTchibo from '../locales/ch-tchibo-3583dee/translation.json';
import enTchibo from '../locales/en-tchibo-3583dee/translation.json';
import deLeinweber from '../locales/de-leinweber-baucentrum-5cvbt954/translation.json';
import enLeinweber from '../locales/en-leinweber-baucentrum-5cvbt954/translation.json';

interface LanguageConfig {
  key: string
  icon?: ReactNode;
  translation: any;
}

interface ProjectLocalizationConfig {
  id: string;
  languages: LanguageConfig[]
}

const CONFIG: ProjectLocalizationConfig[] = [
  {
    id: 'default',
    languages: [
      { key: 'de', icon: <FlagDe />, translation: de },
      { key: 'en', icon: <FlagEn />, translation: en },
    ],
  },
  {
    id: projectID.tegut,
    languages: [
      { key: `de-${projectID.tegut}`, icon: <AssetSVG src="lang-de" />, translation: deTegut },
      { key: `en-${projectID.tegut}`, icon: <AssetSVG src="lang-en" />, translation: enTegut },
      // NOTE The icon is not set so non-tegut-/base-polish can not be selected from the selector
      // But the base polish translation file must be loaded so the base translations are available
      { key: 'pl', icon: undefined, translation: pl },
      { key: `pl-${projectID.tegut}`, icon: <AssetSVG src="lang-pl" />, translation: plTegut },
    ],
  },
  {
    id: projectID.tegutDemo,
    languages: [
      { key: `de-${projectID.tegut}`, icon: <AssetSVG src="lang-de" />, translation: deTegut },
      { key: `en-${projectID.tegut}`, icon: <AssetSVG src="lang-en" />, translation: enTegut },
    ],
  },
  {
    id: projectID.gooods,
    languages: [
      { key: `de-ch-${projectID.gooods}`, icon: <FlagCh />, translation: deGooods },
      { key: `en-${projectID.gooods}`, icon: <FlagEn />, translation: enGooods },
    ],
  },
  {
    id: projectID.tobaccoland,
    languages: [{ key: `de-${projectID.tobaccoland}`, translation: deTobaccoland }],
  },
  {
    id: projectID.panOston,
    languages: [
      { key: `en-${projectID.panOston}`, icon: <FlagEn />, translation: enPanOston },
    ],
  },
  {
    id: projectID.snabbleSupermarket,
    languages: [
      { key: 'en', icon: <FlagEn />, translation: en },
    ],
  },
  {
    id: projectID.demo,
    languages: [
      { key: 'en', icon: <FlagEn />, translation: en },
    ],
  },
  {
    id: projectID.tchibo,
    languages: [
      { key: `ch-${projectID.tchibo}`, icon: <FlagCh />, translation: chTchibo },
      { key: `en-${projectID.tchibo}`, icon: <FlagEn />, translation: enTchibo },
      // NOTE The icon is not set so ch can not be selected from the selector
      // But the base translation file must be loaded so the base translations are available
      { key: 'ch', icon: undefined, translation: ch },
    ],
  },
  {
    id: projectID.tchiboDemo,
    languages: [
      { key: `ch-${projectID.tchibo}`, icon: <FlagCh />, translation: chTchibo },
      { key: `en-${projectID.tchibo}`, icon: <FlagEn />, translation: enTchibo },
      // NOTE The icon is not set so ch can not be selected from the selector
      // But the base translation file must be loaded so the base translations are available
      { key: 'ch', icon: undefined, translation: ch },
    ],
  },
  {
    id: projectID.leinweber,
    languages: [
      { key: `de-${projectID.leinweber}`, icon: <FlagDe />, translation: deLeinweber },
      { key: `en-${projectID.leinweber}`, icon: <FlagEn />, translation: enLeinweber },
    ],
  },
];

// do not edit below this line

export interface LanguageDefinition {
  languageSwitchable: boolean;
  defaultLanguage: string;
  languages: Record<string, LanguageConfig>;
}

const registry = new Registry<LanguageDefinition>({ defaultKey: 'default' });

CONFIG.forEach((config) => {
  registry.set(config.id, {
    languageSwitchable: config.languages.length > 1,
    defaultLanguage: config.languages[0].key,
    languages: keyBy(config.languages, language => language.key),
  });
});

export default registry.freeze();
