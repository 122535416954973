import React, { ReactNode, useMemo } from 'react';
import { useAppSelector } from '../store';
import AnimationType from '../theme/AnimationType';
import CssAnimation from './CssAnimation';

export interface SlideAnimationProps {
  children: ReactNode;
}

export default function SlideAnimation({
  children,
}: SlideAnimationProps) {
  const animationType = useAppSelector(s => s.checkoutDevice.config.animationType);
  const configPrefersReducedMotion = useMemo(() => (
    animationType === AnimationType.Less
  ), [animationType]);

  return (
    <CssAnimation
      reduceMotion={configPrefersReducedMotion}
      inAnimation={{
        backgroundColor: '#ffffff',
        durationSeconds: 0.35,
        opacity: 0,
        scale: 1.1,
        translateY: '-20px',
      }}
      outAnimation={{
        backgroundColor: '#ffffff00',
        durationSeconds: 0.2,
        opacity: 0,
        scale: 1.1,
        translateY: '-20px',
      }}
    >
      {children}
    </CssAnimation>
  );
}
