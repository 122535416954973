import { Draft } from '@reduxjs/toolkit';
import SessionState from '../SessionState';

/**
 * This helper is used when all cart items are removed from a cart. In this
 * case, we are unable to request another checkout info, as it will fail when no
 * items are in the cart. As a workaround we just locally reset all fields that
 * are in some way related to the checkout info.
 *
 * @param state A draft of the cart state.
 */
export default function clearCartItems(state: Draft<SessionState>) {
  /* eslint-disable no-param-reassign */
  state.content.availablePaymentMethods = [];
  state.content.cartItems = [];
  state.content.totalPrice = 0;
  state.content.signedCheckoutInfo = undefined;
  /* eslint-enable no-param-reassign */
}
