import client from '../../api/client';
import CheckoutDeviceMergedConfig from '../CheckoutDeviceMergedConfig';
import FetchCheckoutDeviceOptions from './FetchCheckoutDeviceOptions';

export default async function fetchCheckoutDeviceConfig({
  url,
  clientToken,
  signal,
}: FetchCheckoutDeviceOptions): Promise<CheckoutDeviceMergedConfig> {
  const response = await client.get(url, {
    headers: {
      'Client-Token': clientToken,
    },
    signal,
  });
  return response.data;
}
