import Dexie, { Table } from 'dexie';
import { CheckoutProcessLinks } from '../../checkout-process/CheckoutProcessLinks';
import { UpdateCheckoutPaymentStatusOptions } from '../../session/actions/updateCheckoutPaymentStatus';

export interface UnshippedProcess {
  checkoutProcessId: string;
  id?: number;
  links: CheckoutProcessLinks;
  paymentStatusUpdate: UpdateCheckoutPaymentStatusOptions;
  projectId: string;
  shopId: string;
}

export class UnshippedCheckoutsDb extends Dexie {
  // 'processes' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  processes!: Table<UnshippedProcess>;

  constructor() {
    super('snabble-unshipped-checkouts');
    this.version(2).stores({
      processes: '++id, [checkoutProcessId+projectId], projectId', // Primary key and indexed props
    });
  }
}

export const unshippedCheckoutsDb = new UnshippedCheckoutsDb();
