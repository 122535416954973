import logger from '../../logging';
import { unshippedCheckoutsDb } from './unshippedCheckoutsDb';

export interface DeleteUnshippedCheckoutOptions {
  checkoutProcessId: string;
  projectId: string;
}

export default async function deleteUnshippedCheckout({
  checkoutProcessId,
  projectId,
}: DeleteUnshippedCheckoutOptions) {
  try {
    await unshippedCheckoutsDb.processes
      .where({ checkoutProcessId, projectId })
      .delete();
  } catch (e) {
    logger.warning(`Unable to delete shipped checkout from db. Checkout process id: "${checkoutProcessId}", project id: "${projectId}"`, { tag: 'Checkout' }); // TODO move to Sentry
  }
}
