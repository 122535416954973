import { useCallback, useMemo } from 'react';
import { duration } from 'moment';
import { useAppDispatch, useAppSelector } from '../store';
import useRefresher from '../schedule/useRefresher';
import SessionStatus from '../session/SessionStatus';
import { refreshCheckoutDeviceCategories } from '../checkout-device/checkoutDeviceSlice';
import LandingPageType from '../wizard/LandingPageType';

export const DEFAULT_REFRESH_INTERVAL = duration(15, 'minutes');
export const ERROR_REFRESH_INTERVAL = duration(2, 'minutes');

export default function ProjectCategoriesRefresher() {
  const mainToken = useAppSelector(state => state.token.main);

  const hasTerminalConfig = useAppSelector(state =>
    !!state.root.terminal.config);

  const landingPageType = useAppSelector(state => state.checkoutDevice.config.landingPageType);

  const isLandingPageWithCategories = useMemo(() => (
    landingPageType === LandingPageType.Default ||
      landingPageType === LandingPageType.CoffeeHouse
  ), [landingPageType]);

  const isInCheckout = useAppSelector(state =>
    state.session.status === SessionStatus.Checkout);

  const dispatch = useAppDispatch();

  const handleRefresh = useCallback(
    async () => {
      await dispatch(refreshCheckoutDeviceCategories()).unwrap();
    },
    [dispatch],
  );

  const disabled =
    !mainToken ||
    !hasTerminalConfig ||
    isInCheckout ||
    !isLandingPageWithCategories;

  useRefresher({
    onRefresh: handleRefresh,
    interval: DEFAULT_REFRESH_INTERVAL,
    errorInterval: ERROR_REFRESH_INTERVAL,
    disabled,
  });

  return null;
}
