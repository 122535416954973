import { useCallback } from 'react';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import handleDispatchAbort from '../handleDispatchAbort';
import useAppDispatch from './useAppDispatch';

/**
 * A hook that provides a callback to dispatch an async store action that allows
 * manual cancelation.
 */
export default function useAbortableAppDispatch() {
  const dispatch = useAppDispatch();

  return useCallback(
    <TReturn, TArg>(
      action: AsyncThunkAction<TReturn, TArg, any>,
      signal?: AbortSignal,
    ) => {
      const result = dispatch(action);
      if (signal) {
        handleDispatchAbort(result, signal);
      }
      return result;
    },
    [dispatch],
  );
}
