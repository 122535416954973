import { StringMap, TOptions } from 'i18next';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

export interface TranslateProps {
  id: string;
  data?: TOptions<StringMap> | string;
}

export default function Translate({ id, data }: TranslateProps) {
  const { t } = useTranslation();

  return (
    <Suspense fallback={id}>
      {t(id, data)}
    </Suspense>
  );
}
