import React, { ReactNode } from 'react';
// @ts-ignore
import Button from '../button/Button';

interface LanguageSwitchButtonProps {
  children: ReactNode;
  disabled?: boolean;
  onSelect: () => void;
}

export default function LanguageSwitchButton({
  children,
  disabled = false,
  onSelect,
}: LanguageSwitchButtonProps) {
  return (
    <Button
      data-testid="languageSwitchButton"
      className="language-switch__button"
      onClick={onSelect}
      disabled={disabled}
      variant="plain"
    >
      {children}
    </Button>
  );
}
