import { Draft } from '@reduxjs/toolkit';
import SessionState from '../SessionState';
import clearCartItems from '../helpers/clearCartItems';
import SessionStatus from '../SessionStatus';

/**
 * Clears all `items` and `errorItems` in the cart. Additionally resets
 * information related to the signed checkout info.
 *
 * Preserves the histories and session ID. To reset the session ID and histories
 * as well, use `resetSession` instead.
 *
 * Is a no-op for carts that are not in "open" state.
 *
 * @param state - A draft of the cart state
 */
export default function clearCart(state: Draft<SessionState>) {
  if (state.status !== SessionStatus.Cart) return;

  clearCartItems(state);
  // eslint-disable-next-line no-param-reassign
  state.content.errorItems = [];
}
