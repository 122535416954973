import axios from 'axios';
import { DEFAULT_HEADERS } from '../../api/createClient';
import SNABBLE_BASE_URL from '../../api/snabbleBaseUrl';

const DEFAULT_TIMEOUT = 10000;

export default axios.create({
  timeout: DEFAULT_TIMEOUT,
  headers: DEFAULT_HEADERS,
  baseURL: SNABBLE_BASE_URL,
});
