import { useCallback, useMemo } from 'react';
import { duration } from 'moment';
import { refreshMetadata } from './metadataSlice';
import { useAppDispatch, useAppSelector } from '../store';
import deviceTypeConfig from '../device-type/config';
import useRefresher from '../schedule/useRefresher';
import SessionStatus from '../session/SessionStatus';

const DEFAULT_REFRESH_INTERVAL = duration(15, 'minutes');
const ERROR_REFRESH_INTERVAL = duration(5, 'minutes');

export default function MetadataRefresher() {
  const dispatch = useAppDispatch();

  const deviceType = useAppSelector(state => state.root.terminal.config?.type);
  const hasMainToken = useAppSelector(state => !!state.token.main);

  const isInCheckout = useAppSelector(state =>
    state.session.status === SessionStatus.Checkout);

  const deviceConfig = useMemo(
    () => {
      if (!deviceType) return undefined;
      return deviceTypeConfig.get(deviceType);
    },
    [deviceType],
  );

  const handleRefresh = useCallback(async () => {
    await dispatch(refreshMetadata()).unwrap();
  }, [dispatch]);

  const disabled = (
    isInCheckout ||
    !hasMainToken ||
    !deviceConfig ||
    !deviceConfig?.fetchMetadata
  );

  useRefresher({
    onRefresh: handleRefresh,
    interval: DEFAULT_REFRESH_INTERVAL,
    errorInterval: ERROR_REFRESH_INTERVAL,
    disabled,
  });

  return null;
}
