import { isScannedProductInfo } from '../../../../scan-info/ScannedItemInfo';
import CartItem from '../../CartItem';
import { isCartItemChangeWithScanInfo } from '../../modification/CartItemChange';
import CartItemChangeSet from '../../modification/CartItemChangeSet';

export default function extractProductsFromCartItemsAndChanges(
  items: CartItem[],
  changes: CartItemChangeSet,
) {
  return items
    .map(item => item.info)
    .concat(changes.filter(isCartItemChangeWithScanInfo).map(c => c.info))
    .filter(isScannedProductInfo)
    .map(info => info.product);
}
