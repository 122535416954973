import isEqual from 'lodash/isEqual';
import { useCallback, useContext, useEffect } from 'react';
import useAbortSignal from '../abort/useAbortSignal';
import { useAppSelector } from '../store';
import setLedColor from './api/setLedColor';
import LedDebugContext from './debug/LedDebugContext';
import LedState from './LedState';
import OFF_COLOR from './offColor';

/**
 * A hook to change LED color. Additionally returns a callback that can be used
 * to set the LED colors based on the device configuration.
 */
export default function useLed(initialState?: LedState) {
  const abortSignal = useAbortSignal();

  const { setColor: setDebugColor } = useContext(LedDebugContext);

  const settingsLoaded = useAppSelector(state =>
    state.checkoutDevice.initialized);

  const colors = useAppSelector(
    state => state.checkoutDevice.config.ledColors,
    isEqual,
  );

  const setLedState = useCallback(async (state: LedState) => {
    if (!settingsLoaded) return false;

    let color: string | null;
    if (state === LedState.Off) {
      color = OFF_COLOR;
    } else {
      color = colors[state];
    }

    if (!color) return false;

    setDebugColor(color);
    return setLedColor({ color, signal: abortSignal });
  }, [abortSignal, colors, setDebugColor, settingsLoaded]);

  useEffect(() => {
    if (!initialState) return;
    setLedState(initialState);
  }, [initialState, setLedState]);

  return setLedState;
}
