import { RootState } from '../../store';
import PaymentMethod from '../../payment-method/PaymentMethod';

/**
 * A function that builds a selector that checks whether the given payment
 * method is supported considering the current cart content.
 *
 * @param paymentMethod The payment method to check.
 * @returns A root state selector.
 */
export default function cartSupportsPaymentMethod(paymentMethod: PaymentMethod) {
  return (state: RootState) =>
    state.session.content.availablePaymentMethods.includes(paymentMethod);
}

export function cartSupportsExternalBilling() {
  return (state: RootState) =>
    state.session.content.availablePaymentMethods.includes(PaymentMethod.ExternalBilling) ||
    state.session.content.availablePaymentMethods.includes(PaymentMethod.GatekeeperExternalBilling);
}
