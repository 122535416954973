import ProjectID from '../ProjectID';
import Registry from '../registry/Registry';
import ProjectFeature from './ProjectFeature';

export enum Environment {
  Testing = 'testing',
  Staging = 'staging',
  Prod = 'prod',
}

export type ProjectFeaturesConfig =
  Partial<Record<Environment, Partial<Record<ProjectFeature, boolean>>>>;

const config = new Registry<ProjectFeaturesConfig>();

// NOTE these 'features' are mostly hacks that never went away and won't do so
// in the near future. So instead of inserting more *Ifs* that check for projects
// and environments somewhere in the code we now list them here.

config.set(
  ProjectID.leinweber,
  {
    [Environment.Testing]: {
      [ProjectFeature.ShowExternalBillingDialogAfterCheckout]: false,
      [ProjectFeature.UseLoginForExternalBilling]: false,
      [ProjectFeature.AllowPluLookupInProductSearch]: true,
    },
    [Environment.Staging]: {
      [ProjectFeature.ShowExternalBillingDialogAfterCheckout]: true,
      [ProjectFeature.UseLoginForExternalBilling]: true,
      [ProjectFeature.AllowPluLookupInProductSearch]: true,
    },
    [Environment.Prod]: {
      [ProjectFeature.ShowExternalBillingDialogAfterCheckout]: true,
      [ProjectFeature.UseLoginForExternalBilling]: true,
      [ProjectFeature.AllowPluLookupInProductSearch]: true,
    },
  },
);

config.set(
  'test-admin-portal-wrx8s8',
  {
    [Environment.Testing]: {
      [ProjectFeature.AllowPluLookupInProductSearch]: true,
    },
    [Environment.Staging]: {
      [ProjectFeature.AllowPluLookupInProductSearch]: true,
    },
  },
);

config.set(
  ProjectID.tegut,
  {
    [Environment.Testing]: {
      [ProjectFeature.ShowCigaretteWarnImage]: true,
    },
    [Environment.Staging]: {
      [ProjectFeature.ShowCigaretteWarnImage]: true,
    },
    [Environment.Prod]: {
      [ProjectFeature.ShowCigaretteWarnImage]: true,
    },
  },
);

export default config.freeze();
