import { Draft, PayloadAction } from '@reduxjs/toolkit';
import TerminalPayment from '../../terminal/TerminalPayment';
import TerminalPaymentStatus from '../../terminal/TerminalPaymentStatus';
import SessionState from '../SessionState';

/**
 * Enriches the cart with terminal payment information when the payment status
 * is successful.
 *
 * @param state - A draft of the cart state
 * @param action - The payload containing the terminal payment information
 */
export default function enrichCartFromTerminalPayment(
  state: Draft<SessionState>,
  action: PayloadAction<TerminalPayment>,
) {
  if (action.payload.status !== TerminalPaymentStatus.Successful) return;

  /* eslint-disable no-param-reassign */
  state.iban = action.payload.iban;
  state.customerReceipt = action.payload.terminalResult?.customerReceipt;
  /* eslint-enable */
}
