// @ts-ignore
import { whichEnvironment } from '../helper/urls';

const BASE_URLS: Record<string, string> = {
  prod: 'https://api.snabble.io',
  staging: 'https://api.snabble-staging.io',
  testing: 'https://api.snabble-testing.io',
};

const SNABBLE_BASE_URL =
  process.env.REACT_APP_API_BASE_URL
  ?? BASE_URLS[whichEnvironment(window.location)]
  ?? BASE_URLS.testing;

export default SNABBLE_BASE_URL;

export function prependSnabbleAPIBaseUrl(path: string) {
  return `${SNABBLE_BASE_URL}${path}`;
}
