import projectID from '../ProjectID';
import { matchesTegut, matchesTegutPaymentCard } from './customer-card-matcher/tegut';
import matchesLeinweberCustomerNumber from './customer-card-matcher/leinweber';
import { matchesPayback, toPaybackValue } from './customer-card-matcher/payback';

const CARD_TYPES = {
  payback: 'payback',
  tegut: 'tegut',
  leinweber: 'leinweber-customer-id',
};

export default class CustomerCards {
  constructor(metadata) {
    this.setAccepted(metadata);
  }

  setAccepted(metadata) {
    const cards = metadata?.customerCards;
    this.projectID = metadata?.id;
    this.accepted = cards?.accepted;
    this.areAvailable = !!cards?.accepted?.length;
  }

  isPaymentMethod(value) {
    if (!this.areAvailable || !value || !this.isValidNumber(value)) return false;
    switch (this.projectID) {
      case projectID.tegut:
        return matchesTegutPaymentCard(value);
      case projectID.leinweber:
        return matchesLeinweberCustomerNumber(value);
      default:
        return false;
    }
  }

  isCustomerCard(value) {
    if (!this.areAvailable || !value || !this.isValidNumber(value)) return false;
    if (this.projectID === projectID.tegut) {
      // for tegut we are not allowed to add payment cards as customer cards
      return !matchesTegutPaymentCard(value);
    }
    return true;
  }

  isValidNumber(value) {
    if (!this.areAvailable || !value) return false;

    const matches = (this.accepted).find((identifier) => {
      switch (identifier) {
        case CARD_TYPES.tegut:
          return matchesTegut(value);
        case CARD_TYPES.leinweber:
          return matchesLeinweberCustomerNumber(value);
        case CARD_TYPES.payback:
          return matchesPayback(value);
        default:
          return false;
      }
    });

    return !!matches;
  }

  getSendableCode(value) {
    if (!this.areAvailable || !value) return null;

    let sendableCode;
    (this.accepted).find((identifier) => {
      switch (identifier) {
        case CARD_TYPES.payback:
          sendableCode = toPaybackValue(value);
          return true;
        case CARD_TYPES.tegut:
        case CARD_TYPES.leinweber:
        default:
          sendableCode = value;
          return true;
      }
    });
    return sendableCode;
  }
}
