import deepFreeze from 'deep-freeze';
import { formatPrice, toBig } from '../helper/price';

export default class Product {
  static type = Object.freeze({
    article: 0,
    preWeighed: 1,
    userWeighed: 2,
  });

  static availability = Object.freeze({
    inStock: 0,
    listed: 1,
    notAvailable: 2,
  });

  constructor(build) {
    this.sku = build.sku;
    this.name = build.name;
    this.subtitle = build.subtitle;
    this.description = build.description;
    this.imageUrl = build.imageUrl;
    this.depositProduct = build.depositProduct;
    this.isDeposit = build.isDeposit;
    this.type = build.type;
    this.scannableCodes = build.scannableCodes || [];
    this.saleRestriction = build.saleRestriction;
    this.saleStop = build.saleStop;
    this.referenceUnit = build.referenceUnit;
    this.encodingUnit = build.encodingUnit;
    this.availability = build.availability;
    this.scanMessage = build.scanMessage;
    this.listPrice = build.listPrice;
    this.discountedPrice = build.discountedPrice;
    this.customerCardPrice = build.customerCardPrice;
    this.basePrice = build.basePrice;
    this.currency = build.currency;
    this.rounding = build.rounding;
    this.decimalDigits = build.decimalDigits;
    this.notForSale = build.notForSale;
    this.specifiedQuantity = build.specifiedQuantity;
  }

  currencyFormatter(price) {
    return formatPrice(price, this.decimalDigits, this.currency);
  }

  getFormattedPrice() {
    // TODO: works for simple products only
    if (!this.getPrice()) return '';

    return this.currencyFormatter(toBig(this.getPrice(), this.decimalDigits, this.rounding));
  }

  getRestriction() {
    switch (this.saleRestriction) {
      case 5377:
        return '21';
      case 4609:
        return '18';
      case 4097:
        return '16';
      case 3585:
        return '14';
      case 3073:
        return '12';
      case 1537:
        return '6';
      default:
        return '';
    }
  }

  getPrice() {
    if (this.discountedPrice !== null && this.discountedPrice >= 0) {
      return this.discountedPrice;
    }

    return this.listPrice;
  }

  getDepositPrice() {
    if (!this.depositProduct) return null;
    if (this.depositProduct.discountedPrice !== null && this.depositProduct.discountedPrice >= 0) {
      return this.depositProduct.discountedPrice;
    }

    return this.depositProduct.listPrice;
  }

  getEncodingUnit(scannedCode) {
    const code = this.scannableCodes.find(c => c.lookupCode === scannedCode.code);
    if (code && code.encodingUnit) {
      return code.encodingUnit;
    }
    return this.encodingUnit;
  }

  getSpecifiedQuantity(scannedCode) {
    const code = this.scannableCodes.find(c => c.lookupCode === scannedCode.code);
    if (code && code.specifiedQuantity) {
      return code.specifiedQuantity;
    }
    return this.specifiedQuantity;
  }

  getTransmissionCode(scannedCode) {
    // if the code has an embed no transmission code should be used
    if (scannedCode.embed) {
      return null;
    }

    // check if product has primary code
    const primaryCode = this.scannableCodes.find(c => c.isPrimary);
    if (primaryCode) {
      // if the code has transmissionCode, use it
      if (primaryCode.transmissionCode) {
        return primaryCode.transmissionCode;
      }
      // return lookupCode if not
      return primaryCode.lookupCode;
    }

    // check if the code scanned has a transmission code
    const codeOnProduct = this.scannableCodes.find(c => c.lookupCode === scannedCode.code);
    if (codeOnProduct && codeOnProduct.transmissionCode) {
      return codeOnProduct.transmissionCode;
    }

    // no transimission code defined
    return null;
  }

  getPriceForQuantity(quantity, embed, encodingUnit) {
    let price;
    switch (encodingUnit) {
      case 'price':
        price = quantity * embed;
        break;
      default:
        price = quantity * embed * this.getPrice();
        break;
    }
    return Number.isNaN(price) ? 0 : price;
  }

  getDepositPriceForQuantity(quantity) {
    const price = this.getDepositPrice();
    if (!price) return null;
    return quantity * price;
  }

  static get Builder() {
    class Builder {
      withSku(sku) {
        this.sku = sku;
        return this;
      }

      withName(name) {
        this.name = name;
        return this;
      }

      withSubtitle(subtitle) {
        this.subtitle = subtitle;
        return this;
      }

      withDescription(description) {
        this.description = description;
        return this;
      }

      withImageUrl(imageUrl) {
        this.imageUrl = imageUrl;
        return this;
      }

      withDepositProduct(depositProduct) {
        this.depositProduct = depositProduct;
        return this;
      }

      withIsDeposit(isDeposit) {
        this.isDeposit = isDeposit;
        return this;
      }

      withType(type) {
        this.type = type;
        return this;
      }

      withScannableCodes(scannableCodes) {
        this.scannableCodes = scannableCodes;
        return this;
      }

      withSaleRestriction(saleRestriction) {
        this.saleRestriction = saleRestriction;
        return this;
      }

      withSaleStop(saleStop) {
        this.saleStop = saleStop;
        return this;
      }

      withReferenceUnit(referenceUnit) {
        this.referenceUnit = referenceUnit;
        return this;
      }

      withEncodingUnit(encodingUnit) {
        this.encodingUnit = encodingUnit;
        return this;
      }

      withScanMessage(scanMessage) {
        this.scanMessage = scanMessage;
        return this;
      }

      withAvailability(availability) {
        this.availability = parseInt(availability, 10);
        return this;
      }

      withListPrice(listPrice) {
        this.listPrice = listPrice;
        return this;
      }

      withDiscountedPrice(discountedPrice) {
        this.discountedPrice = discountedPrice;
        return this;
      }

      withCustomerCardPrice(customerCardPrice) {
        this.customerCardPrice = customerCardPrice;
        return this;
      }

      withBasePrice(basePrice) {
        this.basePrice = basePrice;
        return this;
      }

      withCurrencyFormattingInformation(currency, rounding, decimalDigits) {
        this.currency = currency;
        this.rounding = rounding;
        this.decimalDigits = decimalDigits;
      }

      withNotForSale(notForSale) {
        this.notForSale = !!notForSale;
        return this;
      }

      build() {
        const product = new Product(this);
        deepFreeze(product); // immutability
        return product;
      }
    }
    return Builder;
  }
}
