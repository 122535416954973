import client from '../../api/client';
import CheckoutDeviceCategories from '../CheckoutDeviceCategories';
import FetchCheckoutDeviceOptions from './FetchCheckoutDeviceOptions';

export default async function fetchCheckoutDeviceCategories({
  url,
  clientToken,
  signal,
}: FetchCheckoutDeviceOptions): Promise<CheckoutDeviceCategories | undefined> {
  try {
    const response = await client.get(url, {
      headers: {
        'Client-Token': clientToken,
      },
      signal,
    });

    return response.data;
  } catch (e) {
    // we do not throw error as missing categories are fine for us
    return undefined;
  }
}
