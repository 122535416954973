import { useCallback } from 'react';
import { duration } from 'moment';
import { refreshMainToken } from './tokenSlice';
import { useAppSelector, useAppDispatch } from '../store';
import { useRefresher } from '../schedule';

export const DEFAULT_REFRESH_INTERVAL = duration(1.5, 'hours');
export const ERROR_REFRESH_INTERVAL = duration(3, 'minutes');

export default function TokenRefresher() {
  const dispatch = useAppDispatch();
  const hasTerminalConfig = useAppSelector(state => !!state.root.terminal.config);

  const handleRefresh = useCallback(
    async () => {
      await dispatch(refreshMainToken()).unwrap();
    },
    [dispatch],
  );

  useRefresher({
    onRefresh: handleRefresh,
    interval: DEFAULT_REFRESH_INTERVAL,
    errorInterval: ERROR_REFRESH_INTERVAL,
    disabled: !hasTerminalConfig,
  });

  return null;
}
