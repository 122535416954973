import React, { useCallback } from 'react';
import { reopenCart, resetSession } from '../session';
import { useAppDispatch } from '../store';
import ErrorView from '../error/ErrorView';

interface BackToCartViewProps {
  /**
   * The key of the translated text to display.
   */
  messageId: string;

  /**
   * Indicates whether the session is terminated when the back button is clicked
   * (`true`) or whether the cart is reopened for further manipulation
   * (`false`). Defaults to `false`.
   */
  terminate?: boolean;
}

export default function BackToCartView({
  messageId,
  terminate = false,
}: BackToCartViewProps) {
  const dispatch = useAppDispatch();

  const handleBackClick = useCallback(() => {
    if (terminate) {
      dispatch(resetSession());
    } else {
      dispatch(reopenCart());
    }
  }, [dispatch, terminate]);

  return ((
    <ErrorView
      messageId={messageId}
      onBack={handleBackClick}
    />
  ));
}
