import ReferringLineItem from '../../../../checkout-info/line-item/ReferringLineItem';
import AdditionalItem from '../../AdditionalItem';

export default function mapReferringLineItemToAdditionalItem(lineItem: ReferringLineItem):
AdditionalItem {
  return {
    id: lineItem.id,
    name: lineItem.name,
    amount: lineItem.amount,
    price: lineItem.price,
    totalPrice: lineItem.totalPrice,
    type: lineItem.type,
  };
}
