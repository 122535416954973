import { useContext, useEffect, useId } from 'react';
import { Feature, useHasFeatureFlag } from '../feature';
import ScannerContext from './ScannerContext';
import ScannerSubscription from './ScannerSubscription';

export interface UseScannerOptions extends ScannerSubscription {
  /**
   * Determines whether the scanner hook should have an active subscription to
   * the `ScannerProvider` (`false`, default) or delete all subscriptions
   * (`true`).
   */
  disabled?: boolean;
}

/**
 * A hook that provides callbacks for interacting with the keyboard-based
 * scanner.
 *
 * Note that `useScanner` can only be used in components that are children of a
 * `ScannerProvider`.
 */
export default function useScanner({
  disabled = false,
  onEnter,
  onEntered,
  onTimeout,
}: UseScannerOptions = {}) {
  const globallyDisabled = useHasFeatureFlag(Feature.DisableScanner);
  const { subscriptions } = useContext(ScannerContext);
  const subscriberId = useId();

  useEffect(() => {
    if (disabled || globallyDisabled) return () => {};

    subscriptions.set(subscriberId, { onEnter, onEntered, onTimeout });

    return () => {
      subscriptions.delete(subscriberId);
    };
  }, [
    disabled,
    globallyDisabled,
    onEnter,
    onEntered,
    onTimeout,
    subscriberId,
    subscriptions,
  ]);
}
