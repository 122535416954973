import { Draft, PayloadAction } from '@reduxjs/toolkit';
import SessionState from '../SessionState';
import addErrorItem, { AddErrorItemOptions } from '../helpers/addErrorItem';

export type { AddErrorItemOptions } from '../helpers/addErrorItem';

export default function addErrorItemToCart(
  state: Draft<SessionState>,
  action: PayloadAction<AddErrorItemOptions>,
) {
  const { hasChanged, items } = addErrorItem(
    state.content.errorItems,
    action.payload,
  );

  if (!hasChanged) return;

  // eslint-disable-next-line no-param-reassign
  state.content.errorItems = items;
}
