import React, { useCallback, useMemo, useState } from 'react';
import { Route, Router } from '../../routing';
import BackToCartView from '../BackToCartView';
import calculateSummarizedFulfillmentState from '../../fulfillment/calculateSummarizedFulfillmentState';
import CheckoutFulfillmentsView from './CheckoutFulfillmentsView';
import CheckoutStoreIbanView from './CheckoutStoreIbanView';
import CheckoutSuccessHintView from './CheckoutSuccessHintView';
import logger from '../../logging';
import SummarizedFulfillmentState from '../../fulfillment/SummarizedFulfillmentState';
import useCheckoutProcess from '../useCheckoutProcess';
import useCheckoutProcessPoller from '../useCheckoutProcessPoller';
import FeedbackInput from '../../Feedback/FeedbackInput';

enum View {
  Success,
  FulfillmentError,
}

export default function AppCheckoutSucceededView() {
  const [hasError, setHasError] = useState(false);

  const handleMaxAttemptsExceededToGetFulfillments = useCallback(async () => {
    setHasError(true);
    logger.warning('Max number exceeded to get fulfillments');
  }, []);

  const checkoutProcess = useCheckoutProcess();

  const canSaveIBAN = useMemo(
    () => !!checkoutProcess?.links.originCandidate?.href,
    [checkoutProcess],
  );

  const fulfillmentState = useMemo(() => {
    if (!checkoutProcess?.fulfillments) return undefined;
    return calculateSummarizedFulfillmentState(checkoutProcess.fulfillments);
  }, [checkoutProcess]);

  const hasAllocatingOrPendingFulfillment =
    fulfillmentState === SummarizedFulfillmentState.Allocating ||
    fulfillmentState === SummarizedFulfillmentState.Pending;

  useCheckoutProcessPoller({
    disabled: !hasAllocatingOrPendingFulfillment,
    interval: 1000,
    maxAttempts: 100,
    onMaxAttempts: handleMaxAttemptsExceededToGetFulfillments,
    immediate: true,
  });

  const currentView = useMemo(
    () => (hasError ? View.FulfillmentError : View.Success),
    [hasError],
  );

  return ((
    <Router state={currentView}>
      <Route when={View.Success}>
        {fulfillmentState && (
          <CheckoutFulfillmentsView
            fulfillmentState={fulfillmentState}
          />
        )}
        {canSaveIBAN && <CheckoutStoreIbanView />}
        <CheckoutSuccessHintView
          containerClass="approval-result"
          textId="succeeded-instruction-terminal"
          autoTerminate
        >
          <FeedbackInput />
        </CheckoutSuccessHintView>
      </Route>
      <Route when={View.FulfillmentError}>
        <BackToCartView messageId="error.fulfillment" terminate />
      </Route>
    </Router>
  ));
}
