import React, { useCallback, useState } from 'react';
import LoadingView from '../../loading/LoadingView';
import logger from '../../logging';
import { Route, Router } from '../../routing';
import { resetSession } from '../../session';
import { useAppDispatch } from '../../store';
// @ts-ignore
import ErrorView from '../../views/ErrorView';
import useCheckoutProcessPoller from '../useCheckoutProcessPoller';

enum View {
  Error,
  Pending,
}

export default function CheckoutProcessingExternalPaymentView() {
  const dispatch = useAppDispatch();
  const [currentView, setCurrentView] = useState(View.Pending);
  const [pollerDisabled, setPollerDisabled] = useState(false);

  const handleMaxAttemptsExceededToChangeCheckoutState = useCallback(
    () => {
      logger.warning('Max attempts exceeded to change checkout state', { tag: 'Checkout' });
      setCurrentView(View.Error);
      setPollerDisabled(true);
    },
    [],
  );

  useCheckoutProcessPoller({
    interval: 3000,
    // should wait about 1 minute before displaying error
    // TODO/FIXME: Is this long enough?
    maxAttempts: 20,
    onMaxAttempts: handleMaxAttemptsExceededToChangeCheckoutState,
    disabled: pollerDisabled,
    immediate: true,
  });

  const handleGoBackToStart = useCallback(() => {
    dispatch(resetSession());
  }, [dispatch]);

  return (
    <Router state={currentView}>
      <Route when={View.Error}>
        {/* TODO: Pass reason to error view */}
        <ErrorView goBack={handleGoBackToStart} />
      </Route>
      <Route when={View.Pending}>
        {/* TODO: Use correct text/view */}
        <LoadingView />
      </Route>
    </Router>
  );
}
