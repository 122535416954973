import parseInt from 'lodash/parseInt';

// sample card numbers:
// 10-digit customer number: 5010961962
// EAN-13: 2405010961965
// 16-digit: 3083425010961962
//
// see https://docs.google.com/document/d/1abdqdIAJ1yN2pXwmOEXMes5dlYZ5nTvT

const prefix = '308342';
const eanPrefix = '240';

const isEven = value => value % 2 === 0;
const isNumber = value => value.match(/^\d+$/);

function calcLuhnChecksum(code) {
  if (!isNumber(code)) return '';

  const len = code.length;
  let sum = 0;

  for (let index = len - 1; index >= 0; index -= 1) {
    let d = parseInt(code.charAt(index));

    if (isEven(index)) {
      d *= 2;
    }

    if (d > 9) {
      d -= 9;
    }

    sum += d;
  }
  sum %= 10;
  return (sum === 0 ? 0 : 10 - sum).toString();
}

const isLuhnChecksumValid = (value) => {
  const plainNumber = value.slice(0, -1);
  const expectedNumberWithChecksum = `${plainNumber}${calcLuhnChecksum(plainNumber)}`;
  return expectedNumberWithChecksum === value;
};

const matchesPayback = (code) => {
  switch (code.length) {
    case 10:
      return isLuhnChecksumValid(prefix + code);
    case 13:
      return code.startsWith(eanPrefix);
    case 16:
      return code.startsWith(prefix) && isLuhnChecksumValid(code);
    default:
      return false;
  }
};

const toPaybackValue = (code) => {
  if (!matchesPayback(code)) return null;

  let fullCardNumber = code;

  switch (code.length) {
    case 10:
      return `${prefix}${fullCardNumber}`;
    case 13:
      fullCardNumber = fullCardNumber.slice(3);
      fullCardNumber = fullCardNumber.slice(0, -1);
      fullCardNumber = `${prefix}${fullCardNumber}`;
      return fullCardNumber + calcLuhnChecksum(fullCardNumber);
    case 16:
      return fullCardNumber;
    default:
      return null;
  }
};

export { matchesPayback, calcLuhnChecksum, isLuhnChecksumValid, toPaybackValue };
