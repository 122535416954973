import moment, { Duration } from 'moment';
import { useCallback, useRef, useState } from 'react';
import { isDevEnv } from '../env';
import { useScheduled } from '../schedule';

export interface RefresherOptions {
  /**
   * A callback defining the task to perform.
   */
  onRefresh: () => void | Promise<void>;

  /**
   * The time at which the `onRefresh` callback will be invoked.
   */
  interval: number | Duration;

  /**
   * The time at which the `onRefresh` callback will be invoked after an error
   * occurred.
   */
  errorInterval?: number | Duration;

  /**
   * Indicates whether the timer is disabled. Defaults to `false`.
   */
  disabled?: boolean;

  /**
   * Controls whether the callback is invoked right after the refresher is being
   * enabled. When set to `false`, the callback is first invoked after the
   * specified `interval`. Defaults to `true`.
   */
  immediate?: boolean;
}

/**
 * A hook that works similar to `usePoller`, except that the next run time is
 * calculated based on the passed `lastRunAt` and `after` props. These can be
 * used to achieve (store-based) persistence to your refreshing logic even if
 * the component that uses `useRefresher` was unmounted at some time.
 *
 * **It is considered the safer variant of `usePoller` and should be preferred
 * especially for longer running intervals.**
 */
export default function useRefresher({
  onRefresh,
  interval,
  errorInterval,
  disabled = false,
  immediate = true,
}: RefresherOptions) {
  const lockRef = useRef(false);

  const [refreshAt, setRefreshAt] =
    useState(() => moment().add(immediate ? 0 : interval));

  const handleElapsed = useCallback(
    async () => {
      if (lockRef.current) return;
      lockRef.current = true;

      try {
        await onRefresh();
        setRefreshAt(moment().add(interval));
      } catch (e) {
        if (isDevEnv) {
          // eslint-disable-next-line no-console
          console.error('[refresher]', e);
        }
        setRefreshAt(moment().add(errorInterval ?? interval));
      } finally {
        lockRef.current = false;
      }
    },
    [errorInterval, interval, onRefresh],
  );

  useScheduled({
    onElapse: handleElapsed,
    disabled,
    at: refreshAt,
  });
}
