import queryString from 'query-string';
import ApiOptions from '../../api/ApiOptions';
import client from '../../api/client';
import loggedError from '../../api/loggedError';
import DeviceRole from '../../device-type/DeviceRole';
import Token from '../Token';

export interface GetTokenOptions extends ApiOptions {
  id: string;
  projectId: string;
  roles: DeviceRole[];
  secret: string;
  axiosOptions?: any;
}

export default async function getToken({
  id,
  projectId,
  roles,
  secret,
  signal,
  axiosOptions,
}: GetTokenOptions): Promise<Token> {
  const query = queryString.stringify({ role: roles });

  try {
    const response = await client.get(`/${projectId}/tokens?${query}`, {
      headers: {
        Authorization: `Basic ${window.btoa(`${id}:${secret}`)}`,
      },
      signal,
      ...(axiosOptions || {}),
    });
    return response.data;
  } catch (e) {
    throw loggedError('token_not_fetchable', 'Token could not be fetched', e);
  }
}
