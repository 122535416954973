import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import remove from 'lodash/remove';
import uniqueId from 'lodash/uniqueId';
import ToastOptions from './ToastOptions';

export interface ToastItem {
  id: string;
  options: ToastOptions;
}

export interface ToastState {
  items: ToastItem[];
}

const initialState: ToastState = {
  items: [],
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast(state, action: PayloadAction<ToastOptions>) {
      // Ensures only the current and the next toast are in the items list.
      // This way we can prevent showing a possibly infinite amount of toasts
      // to the user.
      state.items.splice(1, state.items.length - 1, {
        id: uniqueId(),
        options: action.payload,
      });
    },
    expireAllToasts(state) {
      state.items.forEach((item) => {
        // When there is no time left to display the item, the item will
        // immediately slide out

        // eslint-disable-next-line no-param-reassign
        item.options.duration = 0;
      });
    },
    // removeToast should never be called directly as it breaks animation,
    // use expireAllToasts instead
    removeToast(state, action: PayloadAction<string>) {
      remove(state.items, item => item.id === action.payload);
    },
  },
});

/* eslint-enable */

export const { addToast, expireAllToasts, removeToast } = toastSlice.actions;

export default toastSlice.reducer;
