import { DefaultRootState } from 'react-redux';

type MapperFn = (state: DefaultRootState, ownProps: any) => any;

/**
 * @deprecated Prefer migrating a container using `mapLegacyRootState` to a
 * functional component and the associated store properties to a Redux Toolkit
 * slice
 */
export default function mapLegacyRootState(mapper: MapperFn) {
  return (state: { root: any }, ownProps: any) =>
    mapper(state.root ?? {}, ownProps);
}
