import React, { ReactNode, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useBem } from '../styles/bem';
import ToastTheme from './ToastTheme';
import './toast.scss';

export interface ToastProps {
  children: ReactNode;
  show?: boolean;
  theme?: ToastTheme;
  duration?: number;
  onHidden?: () => void;
  onHide?: () => void;
}

export default function Toast({
  children,
  show = false,
  theme = ToastTheme.Default,
  duration,
  onHide,
  onHidden,
}: ToastProps) {
  useEffect(() => {
    if (!show || !duration) return () => {};

    const timeout = setTimeout(() => {
      onHide?.();
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [duration, show, onHide]);

  const bem = useBem('toast');

  return (
    <CSSTransition
      in={show}
      classNames={{
        enterActive: bem.block('opening'),
        enterDone: bem.block('open'),
        exitActive: bem.block('closing'),
      }}
      onExited={onHidden}
      timeout={500}
    >
      <div className={bem.block({ [`theme-${theme}`]: theme })}>
        {children}
      </div>
    </CSSTransition>
  );
}
