import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import Toast from './Toast';
import ToastOptions from './ToastOptions';
import { removeToast } from './toastSlice';

const DEFAULT_DISPLAY_DURATION = 3000;

export default function ToastManager() {
  const items = useAppSelector(state => state.toast.items);
  const dispatch = useAppDispatch();

  const [showToast, setShowToast] = useState(false);
  const [currentToastId, setCurrentToastId] = useState<string>();

  const currentToast = useMemo<ToastOptions | undefined>(
    () => items.find(item => item.id === currentToastId)?.options,
    [items, currentToastId],
  );

  const setCurrentToastIdToNext = useCallback(() => {
    const id = items[0]?.id;
    setCurrentToastId(id);

    if (!id) return;

    setShowToast(true);
  }, [items]);

  const handleHideToast = () => {
    setShowToast(false);
  };

  const handleHiddenToast = useCallback(() => {
    if (!currentToastId) return;

    dispatch(removeToast(currentToastId));
    setCurrentToastId(undefined);
  }, [currentToastId, dispatch]);

  useEffect(() => {
    handleHideToast();
  }, [items]);

  useEffect(() => {
    if (currentToastId) return;

    setCurrentToastIdToNext();
  }, [currentToastId, setCurrentToastIdToNext]);

  const displayDuration = useMemo(
    () => currentToast?.duration ?? DEFAULT_DISPLAY_DURATION,
    [currentToast],
  );

  return (
    <Toast
      show={showToast}
      duration={displayDuration}
      theme={currentToast?.theme}
      onHide={handleHideToast}
      onHidden={handleHiddenToast}
    >
      {currentToast?.content}
    </Toast>
  );
}
