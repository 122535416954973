import React, { Suspense, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { Feature, useHasFeatureFlag } from '../feature';
import { isEmptyCart as emptyCartSelector, resetSession } from '../session';
import { LedState, useLed } from '../led';
import { ReadonlyRegistry } from '../registry';
import { useAppDispatch, useAppSelector } from '../store';
import LandingPageType from './LandingPageType';
import LoadingView from '../loading/LoadingView';
import { Route, Router } from '../routing';
import useCodeScanner from '../code/useCodeScanner';
import useProject from '../useProject';
import WizardConfig from './WizardConfig';
import landingPageWizardRegistry from './config';
import projectWizardRegistry from './project';
import useDeviceTypeConfig from '../device-type/useDeviceTypeConfig';

enum View {
  Splash,
  Cart,
}

interface WizardViewProps {
  landingPageRegistry?: ReadonlyRegistry<WizardConfig>;
  projectRegistry?: ReadonlyRegistry<WizardConfig>;
}

export default function WizardView({
  landingPageRegistry = landingPageWizardRegistry,
  projectRegistry = projectWizardRegistry,
}: WizardViewProps) {
  useLed(LedState.PrimaryColor);

  const project = useProject();
  const deviceType = useDeviceTypeConfig();
  const skipSplashScreen = useHasFeatureFlag(Feature.SkipSplashScreen);
  const isEmptyCart = useAppSelector(emptyCartSelector);

  const landingPageType = useAppSelector(state =>
    state.checkoutDevice.config.landingPageType);

  const config = useMemo(() => {
    const key = landingPageType;
    if (!key || key === LandingPageType.Default) {
      return projectRegistry.get(project!) || landingPageRegistry.get(LandingPageType.Default);
    }
    return landingPageRegistry.get(key);
  }, [landingPageType, project, projectRegistry, landingPageRegistry]);

  const startView = deviceType?.hasCart && skipSplashScreen ? View.Cart : View.Splash;

  // Keep the start view in place and prevent flickering when lazy component
  // not yet loaded when start button clicked, see:
  // https://reactjs.org/docs/code-splitting.html#avoiding-fallbacks
  // Quote: "If some state update causes a component to suspend, that state
  // update should be wrapped in a transition."
  const [isPending, startTransition] = useTransition();

  const [currentView, setCurrentView] =
    useState(isEmptyCart || !deviceType?.hasCart ? startView : View.Cart);

  useEffect(() => {
    // Do not set current view to start if cart goes empty.
    // We use confirmation dialogs before changing from cart to start.
    // Updating the view immediately after "isEmptyCart" changes to true would be unexpected.
    if (!deviceType?.hasCart || isEmptyCart) return;

    setCurrentView(View.Cart);
  }, [deviceType, isEmptyCart, startView]);

  // Views
  const StartView = useMemo(
    () => config?.startView,
    [config?.startView],
  );

  const CartView = useMemo(
    () => config?.cartView,
    [config?.cartView],
  );

  // Scanner
  const dispatch = useAppDispatch();

  const handleContentScanned = useCallback(() => {
    if (!CartView || !deviceType?.hasCart) return;

    startTransition(() => {
      setCurrentView(View.Cart);
    });
  }, [CartView, deviceType]);

  useCodeScanner({
    onEntered: handleContentScanned,
  });

  // Handlers
  const handleStart = useCallback(() => {
    if (!CartView || !deviceType?.hasCart) return;

    startTransition(() => {
      setCurrentView(View.Cart);
    });
  }, [CartView, deviceType]);

  const handleReset = useCallback(() => {
    dispatch(resetSession());

    startTransition(() => {
      setCurrentView(startView);
    });
  }, [dispatch, startView]);

  return ((
    <div data-testid="wizardView">
      <Suspense fallback={<LoadingView />}>
        <Router state={currentView}>
          {StartView &&
            <Route when={View.Splash}>
              <StartView
                interactionDisabled={isPending}
                onStart={handleStart}
              />
            </Route>
          }

          {CartView &&
            <Route when={View.Cart}>
              <CartView onReset={handleReset} />
            </Route>
          }
        </Router>
      </Suspense>
    </div>
  ));
}
