import { useCallback } from 'react';
import { useAppDispatch } from '../store';
import ToastOptions from './ToastOptions';
import { addToast, expireAllToasts } from './toastSlice';

export interface UseToast {
  showToast: (options: ToastOptions) => void;
  hideAllToasts: () => void;
}

export default function useToast(): UseToast {
  const dispatch = useAppDispatch();

  const showToast = useCallback((options: ToastOptions) => {
    dispatch(addToast(options));
  }, [dispatch]);

  const hideAllToasts = useCallback(() => {
    dispatch(expireAllToasts());
  }, [dispatch]);

  return {
    showToast,
    hideAllToasts,
  };
}
