import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDidUpdate } from '@mantine/hooks';
import { AxiosError, AxiosResponse } from 'axios';
import {
  captureException,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/react';
import client from '../api/client';
import isAbortedRequestError from '../api/isAbortedRequestError';
import isNetworkError from '../api/isNetworkError';
import logger from '../logging';
import OfflineView from './OfflineView';
import restart from '../helper/restart';
import UnhandledErrorInterceptor from './UnhandledErrorInterceptor';
import useHealthCheck from '../health/useHealthCheck';
import useResponseInterceptor from '../api/useResponseInterceptor';
import { useAppSelector } from '../store';
import SessionStatus from '../session/SessionStatus';
import SentryErrorView from './SentryErrorView';
import HealthStatus from '../health/HealthStatus';
import useHealthStatus from '../health/api/useHealthStatus';

const CLOSED_INTERVAL = 1000;
const DEFAULT_INTERVAL = 120000;
const FATAL_RESPONSE_STATUS_CODES = [401, 403, 500];

function isResponseErrorLeadingToShutdown(error: AxiosError): error is AxiosError {
  if (isAbortedRequestError(error)) return false;
  if (isNetworkError(error)) return true;

  const status = error.response?.status;
  return !!status && FATAL_RESPONSE_STATUS_CODES.includes(status);
}

interface GlobalErrorHandlerProps {
  children: ReactNode;
}

/**
 * A component that globally intercepts and handles component runtime errors and
 * unhandled promise rejection errors. Displays a "store closed" view for errors
 * that can not be recovered from.
 */
export default function GlobalErrorHandler({ children }: GlobalErrorHandlerProps) {
  const [offlineReason, setOfflineReason] = useState<string | null>(null);

  const isInCheckout = useAppSelector(state =>
    state.session.status === SessionStatus.Checkout);

  const { postOnline, postOffline } = useHealthStatus();

  // While the user is in checkout we should not switch to offline view.
  // It is possible that the user has already paid and in this case
  // we have to show a message which explains what happened.
  const isOffline = !isInCheckout && !!offlineReason;

  // Response Interceptor

  const handleResponseFulfilled = useCallback((response: AxiosResponse) => {
    setOfflineReason(null);
    return response;
  }, []);

  // Response Error Handler

  const handleResponseRejected = useCallback((error: any) => {
    if (isResponseErrorLeadingToShutdown(error)) {
      setOfflineReason(error.message);
      captureException(error);
      logger.error(error.message, {
        code: error.code,
        responseStatus: error.response?.status,
      });
    }

    throw error;
  }, []);

  useResponseInterceptor({
    client,
    onFulfilled: handleResponseFulfilled,
    onRejected: handleResponseRejected,
  });

  // Health Check

  const healthCheckInterval =
    offlineReason
      ? CLOSED_INTERVAL
      : DEFAULT_INTERVAL;

  const handleHealthStatusUpdate = useCallback(async (status: HealthStatus) => {
    if (status !== HealthStatus.Offline) {
      setOfflineReason(null);
    } else {
      setOfflineReason('Health check failed');
    }
  }, []);

  useEffect(() => {
    if (offlineReason) {
      postOffline({ reason: offlineReason });
    } else {
      postOnline();
    }
  }, [offlineReason, postOffline, postOnline]);

  useHealthCheck({
    interval: healthCheckInterval,
    onTick: handleHealthStatusUpdate,
  });

  // Re-open handler

  useDidUpdate(() => {
    if (!isOffline) {
      restart();
    }
  }, [isOffline]);

  return (
    <>
      <UnhandledErrorInterceptor />
      <SentryErrorBoundary fallback={<SentryErrorView />}>
        {isOffline ? <OfflineView /> : children}
      </SentryErrorBoundary>
    </>
  );
}
