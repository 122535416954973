import axios from 'axios';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import client from '../../api/client';
import ErrorWithCode from '../../error/ErrorWithCode';

export interface DeleteIbanCandidateOptions extends AuthenticatedApiOptions {
  url: string;
}

export default async function deleteIbanCandidate({
  clientToken,
  signal,
  url,
}: DeleteIbanCandidateOptions) {
  try {
    await client.delete(
      url,
      {
        headers: {
          'Client-Token': clientToken,
        },
        signal,
      },
    );
    return true;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return false;
    }

    throw new ErrorWithCode(
      'iban_not_deletable',
      'IBAN could not be deleted',
      e,
    );
  }
}
