import { RootState } from '../../store';
import SessionState from '../SessionState';
import PaymentMethod from '../../payment-method/PaymentMethod';
import isEmptyCart from './isEmptyCart';
import isLoadingCart from './isLoadingCart';
import SessionStatus from '../SessionStatus';
import cartHasErrorItems from './cartHasErrorItems';
import cartHasViolations from './cartHasViolations';

function isDesiredPaymentMethodAvailable(state: SessionState) {
  const { availablePaymentMethods } = state.content;

  if (state.externalBilling) {
    return availablePaymentMethods.includes(PaymentMethod.GatekeeperExternalBilling) ||
      availablePaymentMethods.includes(PaymentMethod.ExternalBilling);
  }

  return availablePaymentMethods.includes(PaymentMethod.GatekeeperTerminal);
}
/**
 * Selector that indicates whether the cart is submittable. The cart is
 * considered submittable when it is currently not loading anything, non-empty,
 * contains no error items and when an appropriate payment method is available.
 *
 * @param state The root state.
 */
export default function isSubmittableCart(state: RootState) {
  return !isLoadingCart(state) &&
    !isEmptyCart(state) &&
    !!state.token.payment &&
    state.session.status === SessionStatus.Cart &&
    !cartHasErrorItems(state) &&
    !cartHasViolations(state) &&
    isDesiredPaymentMethodAvailable(state.session);
}
