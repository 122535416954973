import CustomerCardProvider from './CustomerCardProvider';
import paybackProvider from './payback';
import tchiboProvider from './tchibo';

const PROVIDERS: CustomerCardProvider[] = [
  paybackProvider,
  tchiboProvider,
];

export default PROVIDERS;

export function findCustomerCardProvidersByNames(names: string[]) {
  const providers: CustomerCardProvider[] = [];

  names.forEach((providerName) => {
    const provider = PROVIDERS.find(p => p.name === providerName);
    if (!provider) return;

    providers.push(provider);
  });

  return providers;
}
