import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReadonlyRegistry } from '../registry';
import languageRegistry, { LanguageDefinition } from './config';
import useProject from '../useProject';

interface LanguageDetectorProps {
  registry?: ReadonlyRegistry<LanguageDefinition>
}

export default function LanguageDetector({
  registry = languageRegistry,
}: LanguageDetectorProps) {
  const project = useProject();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!project) return;

    const languageConfig = registry.get(project);
    if (!languageConfig) return;

    i18n.changeLanguage(languageConfig.defaultLanguage);
  }, [i18n, project, registry]);

  return null;
}
