import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { Feature, useHasFeatureFlag } from '../feature';
import useDebounced from '../useDebounced';
import ScannerContext from './ScannerContext';
import ScannerSubscription from './ScannerSubscription';

export interface ScannerProviderProps {
  children: ReactNode;
  inputTimeout?: number;
  submitTimeout?: number;
}

export const DEFAULT_INPUT_TIMEOUT = 1000;
export const DEFAULT_SUBMIT_TIMEOUT = 300;
const SUBMIT_CHAR = 'Enter';

function isInputElement(target: any) {
  return target instanceof HTMLInputElement ||
    target instanceof HTMLTextAreaElement ||
    target instanceof HTMLSelectElement;
}

/**
 * The `ScannerProvider` provides the same scanning functionality for all child
 * components subscribing via the `useScanner` hook.
 */
export default function ScannerProvider({
  children,
  inputTimeout = DEFAULT_INPUT_TIMEOUT,
  submitTimeout = DEFAULT_SUBMIT_TIMEOUT,
}: ScannerProviderProps) {
  const subscriptionsRef = useRef(new Map<string, ScannerSubscription>());
  const codesRef = useRef<string[]>([]);
  const disabled = useHasFeatureFlag(Feature.DisableScanner);

  const getPresentCodes = () => codesRef.current.filter(Boolean);
  const prependEmptyCode = () => { codesRef.current.unshift(''); };

  const handleEnter = useCallback(() => {
    subscriptionsRef.current.forEach(({ onEnter }) => {
      onEnter?.();
    });
  }, []);

  const handleEntered = useCallback(() => {
    const codes = getPresentCodes();
    if (codes.length === 0) return;

    const content = codes.reverse().join('\n');

    subscriptionsRef.current.forEach(({ onEntered }) => {
      onEntered?.(content);
    });

    codesRef.current = [];
  }, []);

  const handleTimeout = useCallback(() => {
    subscriptionsRef.current.forEach(({ onTimeout }) => {
      onTimeout?.();
    });
    codesRef.current = [];
  }, []);

  const handleEnteredDebounced = useDebounced(handleEntered, submitTimeout);
  const handleTimeoutDebounced = useDebounced(handleTimeout, inputTimeout);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    // In case we want to be able to use inputs normally, we need to pass
    // through keystrokes on them.
    if (isInputElement(event.target)) return;

    // No need for all the fuss when there is nobody listening anyway.
    if (subscriptionsRef.current.size === 0) return;

    event.preventDefault();

    // Abort pending timeout events when another input is coming in.
    handleTimeoutDebounced.cancel();

    // Enter adds another string at the beginning that other incoming chars will
    // be added to. Additionally we trigger the enter event that will only be
    // emitted if no other keys are pressed within the configured
    // SUBMIT_TIMEOUT.
    if (event.code === SUBMIT_CHAR) {
      prependEmptyCode();
      handleEnteredDebounced();
      return;
    }

    // Cancel pending enter events when another key is received.
    handleEnteredDebounced.cancel();

    // If the value list is still empty, we consider this the first event of a
    // scan.
    if (getPresentCodes().length === 0) {
      handleEnter();
      prependEmptyCode();
    }

    // Append char to the most recent (first) value in the list.
    let value = codesRef.current[0];
    value += event.key;
    codesRef.current[0] = value;

    handleTimeoutDebounced();
  }, [handleEnter, handleEnteredDebounced, handleTimeoutDebounced]);

  const handlePaste = useCallback((event: ClipboardEvent) => {
    if (isInputElement(document.activeElement)) return;

    event.preventDefault();

    const content = event.clipboardData?.getData('text');
    if (!content) return;

    subscriptionsRef.current.forEach(({ onEnter, onEntered }) => {
      onEnter?.();
      onEntered?.(content);
    });
  }, []);

  useEffect(() => {
    if (disabled) return () => {};

    document.addEventListener('keypress', handleKeyPress);
    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
      document.removeEventListener('paste', handlePaste);
    };
  }, [disabled, handleKeyPress, handlePaste]);

  return (
    <ScannerContext.Provider
      value={{ subscriptions: subscriptionsRef.current }}
    >
      {children}
    </ScannerContext.Provider>
  );
}
