import ExternalBillingProvider from './ExternalBillingProvider';
import leinweberProvider from './leinweber';
import tegutProvider from './tegut';

const PROVIDERS: ExternalBillingProvider[] = [
  leinweberProvider,
  tegutProvider,
];

export default PROVIDERS;

export function findExternalBillingByProject(project: string) {
  return PROVIDERS.find(p => p.project === project);
}
