import isUndefined from 'lodash/isUndefined';
import remove from 'lodash/remove';
import CheckoutInfoItemParams from '../../../checkout-info/params/CheckoutInfoItemParams';
import CartItem from '../CartItem';
import mapCartItemsToParams from '../mapping/mapCartItemsToParams';
import { CartItemChangeAdd, CartItemChangeUpdate } from './CartItemChange';
import CartItemChangeSet from './CartItemChangeSet';
import CartItemChangeType from './CartItemChangeType';

function add(paramItems: CheckoutInfoItemParams[], change: CartItemChangeAdd) {
  if (!isUndefined(change.index) && change.index >= 0) {
    paramItems.splice(change.index, 0, change.params);
  } else {
    paramItems.push(change.params);
  }
}

function update(paramItems: CheckoutInfoItemParams[], change: CartItemChangeUpdate) {
  const item = paramItems.find(i => i.id === change.id);
  if (!item) return;
  Object.assign(item, change.params);
}

export default function applyChanges(
  items: CartItem[],
  changes: CartItemChangeSet,
): CheckoutInfoItemParams[] {
  const paramItems = mapCartItemsToParams(items);

  changes.forEach((change) => {
    switch (change.type) {
      case CartItemChangeType.Add:
        add(paramItems, change);
        break;
      case CartItemChangeType.Update:
        update(paramItems, change);
        break;
      case CartItemChangeType.Remove:
        remove(paramItems, i => i.id === change.id);
        break;
      default:
    }
  });

  return paramItems;
}
