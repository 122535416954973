import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import QRCode from 'qrcode.react';
// @ts-ignore
import { ReactComponent as SadSmiley } from '../images/smiley-1.svg';
// @ts-ignore
import { ReactComponent as HappySmiley } from '../images/smiley-2.svg';
// @ts-ignore
import { ReactComponent as HeartSmiley } from '../images/smiley-3.svg';
import './feedback-input.scss';
import PopUp from '../popUp/PopUp';
import useFeedbackApi from './useFeedbackApi';
import FeedbackRating from './FeedbackRatings';
import { useAppSelector } from '../store';

export default function FeedbackInput() {
  const qrCodeUrl = useAppSelector(state => state.checkoutDevice.config.feedbackURL);

  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedRating, setSelectedRating] = useState<FeedbackRating | undefined>(undefined);

  const { t } = useTranslation();
  const { sendRating } = useFeedbackApi();

  const handleRating = useCallback((value: FeedbackRating) => {
    // only allow rating one-time
    if (selectedRating !== undefined) return;

    sendRating(value);
    setSelectedRating(value);

    if (value === FeedbackRating.Bad || value === FeedbackRating.Mediocre) setShowPopUp(true);
  }, [selectedRating, sendRating]);

  const handleClosePopUp = useCallback(() => {
    setShowPopUp(false);
  }, []);

  return (
    <div className="feedback-input">
      <div className="feedback-input__title">{t('feedback.hint')}</div>
      <div className="feedback-input__smiley-list">
        <button
          className={cn('feedback-input__smiley-button', selectedRating === FeedbackRating.Bad && 'feedback-input__smiley-button--selected')}
          onClick={() => handleRating(FeedbackRating.Bad)}
        >
          <SadSmiley />
        </button>
        <button
          className={cn('feedback-input__smiley-button', selectedRating === FeedbackRating.Mediocre && 'feedback-input__smiley-button--selected')}
          onClick={() => handleRating(FeedbackRating.Mediocre)}
        >
          <HappySmiley />
        </button>
        <button
          className={cn('feedback-input__smiley-button', selectedRating === FeedbackRating.Good && 'feedback-input__smiley-button--selected')}
          onClick={() => handleRating(FeedbackRating.Good)}
        >
          <HeartSmiley />
        </button>
      </div>
      {qrCodeUrl && (
        <PopUp open={showPopUp} onClose={handleClosePopUp}>
          <div>{t('feedback.popup-text')}</div>
          <div data-testid="feedback-popup-qrcode">
            <QRCode
              value={qrCodeUrl}
              size={250}
              includeMargin
            />
          </div>
        </PopUp>
      )}
    </div>
  );
}
