import ErrorWithCode from '../error/ErrorWithCode';
import { RootState } from '../store';

export default function requireMetadata(state: RootState) {
  const metadata = state.metadata.data;
  if (!metadata) {
    throw new ErrorWithCode('missing_metadata', 'No metadata loaded');
  }

  return metadata;
}
