enum Feature {
  DigitalReceipt = 'useDigitalReceipt',
  DisableScanner = 'disableScanner',
  ManualCartItemQuantityInput = 'manualCartItemQuantityInput',
  SkipSplashScreen = 'skipStartPage',
  SoundOnCartAbort = 'soundOnCartAbort',
  FullTextSearch = 'fullTextSearch',
  ConfirmCustomerCard = 'confirmCustomerCard',
  ConfirmCart = 'confirmCart',
}

export default Feature;
