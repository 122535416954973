enum SessionStatus {
  /**
   * The user can add items to the cart and manipulate contained items.
   */
  Cart = 'cart',

  /**
   * The user cannot manipulate the cart anymore. The payment process is
   * initiated.
   */
  Checkout = 'checkout',
}

export default SessionStatus;
