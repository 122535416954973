import ScannedProductInfo from '../../../product/ScannedProductInfo';
import ScannedCouponInfo from '../../../coupon/ScannedCouponInfo';
import CheckoutInfoProductItemParams from '../../../checkout-info/params/CheckoutInfoProductItemParams';
import CheckoutInfoCouponItemParams from '../../../checkout-info/params/CheckoutInfoCouponItemParams';
import CheckoutInfoItemParams from '../../../checkout-info/params/CheckoutInfoItemParams';
import CartItemChangeType from './CartItemChangeType';
import ScannedItemInfo from '../../../scan-info/ScannedItemInfo';

interface CartItemChangeAddBase<
TItemParams extends CheckoutInfoItemParams,
TInfo extends ScannedItemInfo,
> {
  type: CartItemChangeType.Add;
  params: TItemParams;
  info: TInfo;
  index?: number;
}

export type CartItemChangeAddProduct =
  CartItemChangeAddBase<CheckoutInfoProductItemParams, ScannedProductInfo>;

export type CartItemChangeAddCoupon =
  CartItemChangeAddBase<CheckoutInfoCouponItemParams, ScannedCouponInfo>;

export type CartItemChangeAdd =
  | CartItemChangeAddProduct
  | CartItemChangeAddCoupon;

export interface CartItemChangeUpdate {
  type: CartItemChangeType.Update;
  id: string;
  params: Partial<CheckoutInfoItemParams>;
}

export interface CartItemChangeRemove {
  type: CartItemChangeType.Remove;
  id: string;
}

export type CartItemChangeProduct =
  | CartItemChangeAddProduct
  | CartItemChangeUpdate
  | CartItemChangeRemove;

export type CartItemChangeCoupon =
  | CartItemChangeAddCoupon
  | CartItemChangeRemove;

type CartItemChange = CartItemChangeProduct | CartItemChangeCoupon;

type CartItemChangeWithScanInfo = CartItemChange & { info: ScannedItemInfo };

export function isCartItemChangeWithScanInfo(change: CartItemChange):
change is CartItemChangeWithScanInfo {
  return 'info' in change;
}

export default CartItemChange;
