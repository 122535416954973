import ScannedProductInfo from './ScannedProductInfo';
import resolveEncodingUnit from './resolveEncodingUnit';
import EncodingUnit from './EncodingUnit';

export default function calculateWeight(productInfo: ScannedProductInfo) {
  let weight: number | undefined;
  const encodingUnit = resolveEncodingUnit(productInfo);

  if (
    !encodingUnit ||
    encodingUnit === EncodingUnit.Price ||
    encodingUnit === EncodingUnit.Piece
  ) {
    return undefined;
  }

  if (productInfo.scannedCode.embed) {
    weight = productInfo.scannedCode.embed;
  }

  if (!weight) {
    return undefined;
  }

  return weight;
}
