import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import handleError from './handleError';

const errorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) => next => (action) => {
    handleError(action, dispatch);
    return next(action);
  };

export default errorMiddleware;
