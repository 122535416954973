import CustomerCardProvider from './CustomerCardProvider';

const CODE_REGEX = /^2447(\d{9})$/;

const provider: CustomerCardProvider = {
  name: 'tchibo-card',
  validateCode: (code: string) => CODE_REGEX.test(code),
};

export default provider;
