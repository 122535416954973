enum TerminalPaymentStatus {
  /**
   * The terminal waits for a payment card which can verify the user's age.
   */
  AgeVerificationPending = 'ageVerificationPending',

  /**
   * The users card does not support age verifications.
   */
  AgeVerificationNotSupportedByCard = 'ageVerificationNotSupportedByCard',

  /**
   * The user is not old enough.
   */
  AgeVerificationFailed = 'ageVerificationFailed',

  /**
 * The terminal is awaiting the payment.
 */
  Pending = 'pending',

  /**
   * The terminal errored.
   */
  Error = 'error',

  /**
   * The payment failed.
   */
  Failed = 'failed',

  /**
   * The payment succeeded.
   */
  Successful = 'successful',

  /**
   * The user aborted the payment.
   */
  Aborted = 'aborted',
}

export default TerminalPaymentStatus;
