// @ts-ignore
import ProductDb from '../db/ProductDb';

export interface UpdateAppDbOptions {
  authToken: string;
}

export default function updateAppDb({
  authToken,
}: UpdateAppDbOptions) {
  return new Promise<void>((resolve, reject) => {
    ProductDb.update(
      () => { resolve(); },
      () => {
        reject(new Error('App DB could not be updated'));
      },
      authToken,
    );
  });
}
