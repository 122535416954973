import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { duration } from 'moment';
import { enrichCartFromTerminalPayment, resetSession } from '../../session';
import { useAppDispatch, useAppSelector } from '../../store';
import CheckoutPaymentStatus from '../../checkout-process/CheckoutPaymentStatus';
import CheckoutTerminalPaymentProcessingView from './CheckoutTerminalPaymentProcessingView';
import CheckoutTerminalPendingAgeVerificationView from './CheckoutTerminalPendingAgeVerificationView';
import createPayment from '../../terminal/api/createPayment';
import DebouncedRouter from '../../routing/DebouncedRouter';
import extractRequiredAgeFromChecks from '../../check/extractRequiredAgeFromChecks';
import getPayment from '../../terminal/api/getPayment';
import LoadingView from '../../loading/LoadingView';
import logger from '../../logging';
import printContent from '../../terminal/api/printContent';
import Route from '../../routing/Route';
import TerminalPayment from '../../terminal/TerminalPayment';
import TerminalPaymentStatus from '../../terminal/TerminalPaymentStatus';
import updateCheckoutPaymentStatus from '../../session/actions/updateCheckoutPaymentStatus';
import useAbortSignal from '../../abort/useAbortSignal';
import useCheckoutProcess from '../useCheckoutProcess';
import useGetFallbackReceiptContent from '../useGetFallbackReceiptContent';
import { usePoller } from '../../polling';
import addUnshippedCheckout from '../unshipped/addUnshippedCheckout';
import useProject from '../../useProject';
import ErrorView from '../../error/ErrorView';
import isAbortedRequestError from '../../api/isAbortedRequestError';
import isNetworkError from '../../api/isNetworkError';
import mapPaymentStateToStatusUpdateOptions from './mapPaymentStateToStatusUpdateOptions';
import TerminalFailureCause from '../../terminal/TerminalFailureCause';
import CheckoutSuccessHintView from '../succeeded/CheckoutSuccessHintView';
import FeedbackInput from '../../Feedback/FeedbackInput';
// @ts-ignore
import { ReactComponent as ErrorIcon } from '../../images/error.svg';
import styles from './CheckoutProcessingTerminalPaymentView.module.scss';

enum View {
  Error,
  Loading,
  PaymentProcessing,
  VerifyingAge,
}

export default function CheckoutProcessingTerminalPaymentView() {
  const { t } = useTranslation();
  const abortSignal = useAbortSignal();
  const checkoutProcess = useCheckoutProcess()!;
  const { clientID, checkoutDeviceID, fulfillments } = checkoutProcess;
  const hasFulfillments = !!fulfillments;

  const projectId = useProject()!;
  const dispatch = useAppDispatch();

  const [isTerminalBusy, setIsTerminalBusy] = useState(false);
  const [isPollingTerminalPayment, setIsPollingTerminalPayment] = useState(false);
  const [cannotUpdatePaymentStatus, setCannotUpdatePaymentStatus] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [showSuccessViewDespiteError, setShowSuccessViewDespiteError] = useState(false);

  const handleBackToStart = () => {
    dispatch(resetSession());
  };

  // This is only kind of a last resort if the terminal client does not respond
  // with any valid response. For example, if the payment could not be created,
  // retrieving the payment failed or max retries have been exceeded
  const updatePaymentStatusToFailed = useCallback(
    async () => {
      setIsPollingTerminalPayment(false);

      await dispatch(updateCheckoutPaymentStatus({
        status: CheckoutPaymentStatus.Failed,
        params: {
          clientID,
          checkoutDeviceID,
          result: {
            // This is only invoked in cases where we have no terminal payment
            // result (yet), so we only set an error cause
            failureCause: TerminalFailureCause.TerminalErrored,
          },
        },
      }));
    },
    [checkoutDeviceID, clientID, dispatch],
  );

  const initiateTerminalPayment = useCallback(async () => {
    if (isTerminalBusy) return;

    setIsTerminalBusy(true);

    try {
      logger.info(`Sending create terminal payment for "${checkoutProcess.id}"`, { tag: 'Checkout' });

      await createPayment({
        params: {
          checkoutId: checkoutProcess.id,
          minimumAge: extractRequiredAgeFromChecks(checkoutProcess.checks),
          totalPrice: checkoutProcess.price,
        },
        signal: abortSignal,
      });
      setIsPollingTerminalPayment(true);

      logger.info(`Created terminal payment for "${checkoutProcess.id}"`, { tag: 'Checkout' });
    } catch (e) {
      await updatePaymentStatusToFailed();
    }
  }, [abortSignal, checkoutProcess, isTerminalBusy, updatePaymentStatusToFailed]);

  useEffect(() => {
    initiateTerminalPayment();
  }, [initiateTerminalPayment]);

  const getFallbackReceiptContent = useGetFallbackReceiptContent();

  const [paymentStatus, setPaymentStatus] = useState<TerminalPaymentStatus>();

  const verifiedAge = useAppSelector(
    state => extractRequiredAgeFromChecks(state.session.checkoutProcess?.checks ?? []),
    isEqual,
  );

  const updatePaymentStatus = useCallback(async (payment: TerminalPayment) => {
    const opts = mapPaymentStateToStatusUpdateOptions(payment, {
      checkoutDeviceID,
      clientID,
      verifiedAge,
    });
    if (!opts) return;

    setIsPollingTerminalPayment(false);
    dispatch(enrichCartFromTerminalPayment(payment));

    try {
      await dispatch(updateCheckoutPaymentStatus(opts)).unwrap();
    } catch (e) {
      setCannotUpdatePaymentStatus(true);

      await addUnshippedCheckout({
        checkout: checkoutProcess,
        paymentStatusUpdate: opts,
        projectId,
      });

      logger.error(`Unable to update payment status to "${payment.status}" for "${checkoutProcess.id}" (original error: ${(e as any).message})`, { tag: 'Checkout' });

      if (payment.status === TerminalPaymentStatus.Successful) {
        // We are not able to report the payment status to the backend. This also means we are not
        // able to give a receipt to the user. So we generate a receipt with the information that
        // we are able to give to her / him.
        logger.info(`Printing fallback receipt for "${checkoutProcess.id}"`, { tag: 'Checkout' });
        try {
          await printContent({
            content: getFallbackReceiptContent(payment.terminalResult?.customerReceipt),
          });
          if (hasFulfillments) {
            setPaymentErrorMessage('offline-error.payment-success-fulfillments');
          } else {
            setPaymentErrorMessage('offline-error.payment-success');
          }
          setShowSuccessViewDespiteError(true);
        } catch (printError) {
          if (hasFulfillments) {
            setPaymentErrorMessage('offline-error.payment-success-printing-fails-fulfillments');
          } else {
            setPaymentErrorMessage('offline-error.payment-success-printing-fails');
          }
          setShowSuccessViewDespiteError(true);
          logger.error(`Unable to print fallback receipt: "${printError}"`, { tag: 'Checkout' });
        }
      } else {
        setPaymentErrorMessage('offline-error.general');
      }
    }
  }, [
    checkoutProcess,
    checkoutDeviceID,
    clientID,
    dispatch,
    getFallbackReceiptContent,
    projectId,
    verifiedAge,
    hasFulfillments,
  ]);

  const prevPaymentStatusRef = useRef<TerminalPaymentStatus>();

  const handleRefreshPaymentStatus = useCallback(async (signal: AbortSignal) => {
    try {
      logger.info(`Refreshing payment status for "${checkoutProcess.id}"`, { tag: 'Checkout' });

      const payment = await getPayment({
        checkoutId: checkoutProcess.id,
        retries: 0,
        signal,
      });

      if (payment.status === prevPaymentStatusRef.current) return;

      logger.info(`Received updated terminal payment status from "${prevPaymentStatusRef.current}" to "${payment.status}" for "${checkoutProcess.id}"`, { tag: 'Checkout' });

      setPaymentStatus(payment.status);
      prevPaymentStatusRef.current = payment.status;

      if (
        payment.checkoutId && // check if id is given, to support older terminal versions
        checkoutProcess.id !== payment.checkoutId
      ) {
        // We have to check whether our checkoutId matches the terminals checkoutId.
        // Before 12/10/2022 we had a bug which returned the terminal result from the
        // previous checkout. This happened because the terminal cleared the result in a go-func
        // and as our SCO polls the status immediately after creating we marked payments as
        // successful which were not made at all.
        // The terminal gateway clears the result earlier now. It also returns the checkout id which
        // belongs to the result and checks the checkout ids as well. If we are still receiving
        // succeeding results for other checkouts there has to be another bug
        // https://github.com/snabble/terminal-gateway/commit/57f963ab657cc10c54009a3c56161fd94da63d74
        logger.error(`Mismatching checkout process id. Ours: "${checkoutProcess.id}", terminal result for checkout process id: "${payment.checkoutId}"`, { tag: 'Checkout' });
        await updatePaymentStatusToFailed();
        return;
      }

      await updatePaymentStatus(payment);
    } catch (e) {
      // Network errors don't matter here as this function is only invoked in
      // the poller and we retry in a second. All error handling is performed
      // in the max attempts handler. Also, never act when the request has been
      // aborted.
      if (isNetworkError(e) || isAbortedRequestError(e)) return;

      await updatePaymentStatusToFailed();
    }
  }, [checkoutProcess.id, updatePaymentStatus, updatePaymentStatusToFailed]);

  const handleMaxAttemptsExceededToRefreshPaymentStatus = useCallback(async () => {
    logger.info(`Max attempts exceeded to refresh payment status for "${checkoutProcess.id}"`, { tag: 'Checkout' });
    await updatePaymentStatusToFailed();
  }, [checkoutProcess, updatePaymentStatusToFailed]);

  usePoller({
    onTick: handleRefreshPaymentStatus,
    interval: 1000,
    disabled: !isPollingTerminalPayment,
    // wait 5 minutes before cancelling payment
    maxAttempts: 300,
    onMaxAttempts: handleMaxAttemptsExceededToRefreshPaymentStatus,
    immediate: true,
  });

  const currentView = useMemo(() => {
    if (cannotUpdatePaymentStatus) {
      return View.Error;
    }

    if (paymentStatus === TerminalPaymentStatus.AgeVerificationPending) {
      return View.VerifyingAge;
    }

    if (paymentStatus === TerminalPaymentStatus.Pending) {
      return View.PaymentProcessing;
    }

    return View.Loading;
  }, [cannotUpdatePaymentStatus, paymentStatus]);

  return (
    <DebouncedRouter state={currentView}>
      <Route when={View.VerifyingAge}>
        <CheckoutTerminalPendingAgeVerificationView />
      </Route>
      <Route when={View.PaymentProcessing}>
        <CheckoutTerminalPaymentProcessingView />
      </Route>
      <Route when={View.Loading}>
        <LoadingView />
      </Route>
      <Route when={View.Error}>
        {showSuccessViewDespiteError ? (
          <CheckoutSuccessHintView
            textId="succeeded-instruction"
            autoTerminate
            autoTerminateAfter={duration(2, 'minutes')}
            containerClass="approval-result"
          >
            <FeedbackInput />
            <div className={cn('approval-result__receipt', 'approval-result__receipt--no-qr-code', 'approval-result__receipt--no-qr-code--error')}>
              <ErrorIcon className={styles.erroricon} />
              <div className="approval-result__receipt__hint" data-testid="offline-error-payment-success">
                {t(paymentErrorMessage)}
              </div>
            </div>
          </CheckoutSuccessHintView>
        ) : (
          <ErrorView
            messageId={paymentErrorMessage}
            onBack={handleBackToStart}
            timeout={duration(2, 'minutes').asMilliseconds()}
          />
        )}
      </Route>
    </DebouncedRouter>
  );
}
