import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { duration } from 'moment';
import { useTranslation } from 'react-i18next';
import { Route, Router } from '../../routing';
import CheckoutSuccessHintView from './CheckoutSuccessHintView';
import getPrinterStatus from '../../terminal/api/getPrinterStatus';
import isAvailablePrinterStatus from '../../terminal/isAvailablePrinterStatus';
import LoadingView from '../../loading/LoadingView';
import logger from '../../logging';
import ReceiptDeliveryType from '../../receipt/ReceiptDeliveryType';
import FeedbackInput from '../../Feedback/FeedbackInput';
import checkoutRegistry from '../config';
import useProject from '../../useProject';

const DIGITAL_RECEIPT_INACTIVITY_TIMEOUT = duration(2, 'minutes');

enum View {
  Loading,
  Success,
}

export interface CheckoutReceiptViewProps {
  deliveryType?: ReceiptDeliveryType;
  digitalReceiptUrl?: string;
  onDemandPrint?: () => Promise<void>;
}

export default function CheckoutReceiptView({
  deliveryType,
  digitalReceiptUrl,
  onDemandPrint,
}: CheckoutReceiptViewProps) {
  const { t } = useTranslation();
  const project = useProject();

  const printerStatusDeterminedRef = useRef(false);
  const [hasAvailablePrinter, setHasAvailablePrinter] = useState(false);

  useEffect(() => {
    if (
      printerStatusDeterminedRef.current ||
      deliveryType === ReceiptDeliveryType.Printed
    ) return () => {};

    const abortController = new AbortController();

    (async () => {
      let available: boolean;
      try {
        const printerStatus = await getPrinterStatus({
          signal: abortController.signal,
        });
        available = isAvailablePrinterStatus(printerStatus);
      } catch (e) {
        available = false;
      }
      setHasAvailablePrinter(available);
      printerStatusDeterminedRef.current = true;
    })();

    return () => {
      abortController.abort();
    };
  }, [deliveryType]);

  const [isDemandingPrint, setIsDemandingPrint] = useState(false);

  const handlePrintReceipt = useCallback(async () => {
    if (!onDemandPrint) {
      logger.info('Failed to manually demand receipt printing (callback missing)', { tag: 'Checkout' });
      return;
    }

    setIsDemandingPrint(true);

    try {
      await onDemandPrint();
      setIsDemandingPrint(false);
    } catch (e) {
      logger.info('Failed to manually demand receipt printing (error)', { tag: 'Checkout' });
      setIsDemandingPrint(false);
      throw e;
    }
  }, [onDemandPrint]);

  const autoTerminateAfter = deliveryType === ReceiptDeliveryType.Digital
    ? DIGITAL_RECEIPT_INACTIVITY_TIMEOUT
    : undefined;

  const currentView =
    (!deliveryType || deliveryType === ReceiptDeliveryType.Unknown)
      ? View.Loading
      : View.Success;

  const SelectedReceiptCard = useMemo(() => {
    if (!project) return null;
    return checkoutRegistry.get(project)?.receiptCard;
  }, [project]);

  return ((
    <Router state={currentView}>
      <Route when={View.Success}>
        <CheckoutSuccessHintView
          textId="succeeded-instruction"
          autoTerminate
          autoTerminateAfter={autoTerminateAfter}
          containerClass="approval-result"
        >
          <FeedbackInput />
          {deliveryType === ReceiptDeliveryType.Digital && SelectedReceiptCard && (
            <SelectedReceiptCard
              digitalReceiptUrl={digitalReceiptUrl}
              handleRequestPrint={handlePrintReceipt}
              buttonDisable={isDemandingPrint || !hasAvailablePrinter}
              buttonDescription={hasAvailablePrinter ? t('approval-result.print-receipt') : t('approval-result.printer-not-available')}
            />
          )}
        </CheckoutSuccessHintView>
      </Route>

      <Route when={View.Loading}>
        <LoadingView />
      </Route>
    </Router>
  ));
}
