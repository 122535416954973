import { createContext } from 'react';
import { AddCartItemOptions } from '../session/content/changesets/add';

export interface CartMutator {
  addCartItems: (options: AddCartItemOptions[]) => Promise<void>;
  updateCartItemQuantity: (id: string, amount: number) => Promise<void>;
  removeCartItem: (id: string) => Promise<void>;
}

export default createContext<CartMutator>({
  addCartItems: () => Promise.resolve(),
  updateCartItemQuantity: () => Promise.resolve(),
  removeCartItem: () => Promise.resolve(),
});
