import { useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';

type AnyFunction = (...args: any[]) => any;

/**
 * Wraps a the specified callback in a debounced function. The debounced
 * function is canceled when the implementation changes or the component is
 * unmounted.
 *
 * Note that you should almost **never** define the function inline as it will
 * be redefined on each rerender, but instead pass functions defined with the
 * `useCallback` hook.
 *
 * @param func - The callback to wrap in a debounced function.
 *
 * @param wait - The amount of milliseconds to wait before the function is
 * invoked.
 *
 * @returns The debounced function.
 */
export default function useDebounced<T extends AnyFunction>(
  func: T,
  wait: number,
) {
  const debouncedFunc = useMemo(() => debounce(func, wait), [func, wait]);

  useEffect(() => debouncedFunc.cancel, [debouncedFunc]);

  return debouncedFunc;
}
