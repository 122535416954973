import { v4 as uuidv4 } from 'uuid';
import ErrorItem from '../content/ErrorItem';

const UNIQUENESS_CHECK_FIELDS: (keyof ErrorItem)[] = [
  'name',
  'reason',
];

export interface AddErrorItemOptions {
  name: string;
  reason: string;
  unique?: boolean;
}

interface AddErrorItemResult {
  items: ErrorItem[];
  hasChanged: boolean;
}

export default function addErrorItem(
  items: ErrorItem[],
  { unique, ...options }: AddErrorItemOptions,
): AddErrorItemResult {
  if (
    unique &&
    items.some(item =>
      UNIQUENESS_CHECK_FIELDS.every(field =>
        item[field] === (options as ErrorItem)[field]))
  ) {
    return { hasChanged: false, items };
  }

  return {
    hasChanged: true,
    items: [
      ...items,
      {
        id: uuidv4(),
        ...options,
      },
    ],
  };
}
