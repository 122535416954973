enum CodeType {
  /**
   * Process from app where the sco is used to handle the payment
   * (payment method: gatekeeperTerminal)
   */
  AppToTerminal = 'appToTerminal',
  /**
   * Product barcodes (e.q. ean13, ean8, ...)
   */
  Barcodes = 'barcodes',
  /**
   * Process from app where the sco is a sort of touch point which approves
   * checkouts with other payment methods than gatekeeperTerminal
   */
  Gatekeeper = 'gatekeeper',
  /**
   * Admin command to trigger terminal reconciliation
   */
  AdminReconciliation = 'adminReconciliation',
}

export default CodeType;
