import axios from 'axios';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import client from '../../api/client';
import loggedError from '../../api/loggedError';
import { isSuccessfulOrCustomResponse } from '../../api/statusValidator';
import CheckoutProcess from '../CheckoutProcess';
import CheckoutProcessState from '../CheckoutProcessState';
import getCheckout from './getCheckout';

export interface MergePatchCheckoutParams {
  state?: CheckoutProcessState;
}

export interface MergePatchCheckoutOptions extends AuthenticatedApiOptions {
  project: string;
  id: string;
  params?: MergePatchCheckoutParams;
}

const SHOP_MISMATCH_RESPONSE_STATUS_CODE = 403;

class ShopMismatchError extends Error {}

export default async function mergePatchCheckout({
  clientToken,
  id,
  project,
  params,
  signal,
}: MergePatchCheckoutOptions) {
  const url = `/${project}/sco/process/${id}`;

  try {
    const response = await client.patch<CheckoutProcess>(
      url,
      params,
      {
        headers: {
          'Client-Token': clientToken,
          'Content-Type': 'application/merge-patch+json',
        },
        signal,
        validateStatus: status =>
          isSuccessfulOrCustomResponse(status, [SHOP_MISMATCH_RESPONSE_STATUS_CODE]),
      },
    );
    if (response.status === SHOP_MISMATCH_RESPONSE_STATUS_CODE) throw new ShopMismatchError();
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 409) {
      return getCheckout({ clientToken, url, signal });
    }

    if (axios.isAxiosError(e) && e.response?.status === 404) {
      throw loggedError(
        'process_not_found',
        'Checkout process not found',
      );
    }

    if (e instanceof ShopMismatchError) {
      throw loggedError(
        'shop_mismatch',
        'Unable to update checkout process due to mismatching shop',
      );
    }

    throw loggedError(
      'process_not_patchable',
      'Checkout process could not be patched',
      e,
    );
  }
}
