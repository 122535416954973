import ScannedCodeInfo from '../barcode/ScannedCodeInfo';
import Coupon from './Coupon';
import ScannedCouponInfo from './ScannedCouponInfo';

export default function findCouponInfoForCodes(
  availableCoupons: Coupon[],
  codes: ScannedCodeInfo[],
): ScannedCouponInfo | undefined {
  let scannedCode!: ScannedCodeInfo;

  const coupon = availableCoupons.find((cp) => {
    if (!cp.codes) return false;

    return cp.codes.some(couponCode =>
      codes.some((codeInfo) => {
        const isMatch =
          codeInfo.code === couponCode.code &&
          codeInfo.template === couponCode.template;
        if (isMatch) scannedCode = codeInfo;
        return isMatch;
      }));
  });

  if (!coupon) return undefined;

  return { coupon, scannedCode };
}
