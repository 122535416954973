import { IAxiosRetryConfig } from 'axios-retry';
import merge from 'lodash/merge';
import { DEFAULT_RETRY_CONFIG } from './createClient';

/**
 * Helper to merge the default retry config with the given options.
 */
export default function mergeRetryConfigs(options: IAxiosRetryConfig):
IAxiosRetryConfig | undefined {
  return merge({}, DEFAULT_RETRY_CONFIG, options);
}
