import client from '../../api/client';
import Coupon from '../Coupon';

export interface FetchCouponsOptions {
  url: string;
  clientToken: string;
}

export default async function fetchCoupons({
  url,
  clientToken,
}: FetchCouponsOptions): Promise<Coupon[]> {
  const response = await client.get(url, {
    headers: {
      'Client-Token': clientToken,
    },
  });
  return response.data.coupons;
}
