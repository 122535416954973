import { findCustomerCardProvidersByNames } from './providers';
import CustomerCard from './CustomerCard';

export default function findCustomerCard(
  providerNames: string[],
  code: string,
): CustomerCard | undefined {
  const providers = findCustomerCardProvidersByNames(providerNames);
  const provider = providers.find(p => p.validateCode(code));
  if (!provider) return undefined;

  return {
    providerName: provider.name,
    code: provider.sanitizeCode?.(code) ?? code,
  };
}
