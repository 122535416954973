import resolveScannableCodeFromProduct from '../product/resolveScannableCodeFromProduct';
import ScannedProductInfo from './ScannedProductInfo';

export interface ScannedCodeSelectionResult {
  code: string;
  amount: number;
}

const DEFAULT_AMOUNT = 1;

export default function selectScannedCode(info: ScannedProductInfo): ScannedCodeSelectionResult {
  if (info.scannedCode.embed) {
    return {
      code: info.scannedCode.scannedByUser,
      amount: DEFAULT_AMOUNT,
    };
  }

  const primaryCode = info.product.scannableCodes.find(c => c.isPrimary);
  const productCode = resolveScannableCodeFromProduct(info);

  if (primaryCode) {
    return {
      code:
        primaryCode.transmissionCode ||
        primaryCode.lookupCode ||
        info.scannedCode.scannedByUser,
      amount: productCode?.specifiedQuantity ?? DEFAULT_AMOUNT,
    };
  }

  if (productCode?.transmissionCode) {
    return {
      code: productCode.transmissionCode,
      amount: DEFAULT_AMOUNT,
    };
  }

  return {
    code: info.scannedCode.scannedByUser,
    amount: DEFAULT_AMOUNT,
  };
}
