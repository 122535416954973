import { parseCheckoutProcess } from '../helper/scoProcess';

const defaultValues = {
  cartErrors: [],
  id: null,
  initialized: false,
  isLoading: false,
  hasError: false,
  hasInfoError: false,
  errorDetails: null,
  info: null,
  checks: null,
  state: null,
  didOriginRequest: false,
  clientID: '',
  isUpdatingCheck: false,
  retryCount: 0,
  fulfillmentState: '',
  paymentMethodVerifiesAge: false,
  fulfillments: [],
  links: {
    self: null,
    receipt: null,
    originCandidate: null,
    paymentStateProcessing: null,
    paymentStateFailed: null,
    paymentStateSucceeded: null,
  },
};

export default (checkout, action) => {
  switch (action.type) {
    case 'CREATING_CHECKOUT_INFO': {
      return Object.assign(
        {}, checkout,
        {
          cartErrors: [],
          hasInfoError: false,
          initialized: true,
          isLoading: true,
        },
      );
    }

    case 'RECEIVED_CHECKOUT_INFO': {
      return Object.assign(
        {}, checkout,
        {
          info: action.payload,
          isLoading: false,
          hasInfoError: false,
        },
      );
    }

    case 'UPDATING_CHECK': {
      return Object.assign(
        {}, checkout,
        {
          isUpdatingCheck: true,
        },
      );
    }

    case 'UPDATED_CHECK': {
      return Object.assign(
        {}, checkout,
        {
          isUpdatingCheck: false,
        },
      );
    }

    case 'CREATING_CHECKOUT_PROCESS':
    case 'GETTING_CHECKOUT_PROCESS': {
      return Object.assign(
        {}, checkout,
        { isLoading: true },
      );
    }

    case 'CREATED_CHECKOUT_PROCESS':
    case 'GOT_CHECKOUT_PROCESS': {
      const process = action.payload;
      return Object.assign({}, checkout, parseCheckoutProcess(process));
    }

    case 'RECEIVED_CHECK_ERROR': {
      return Object.assign({}, checkout, {
        hasFailedCheck: true,
        isUpdatingCheck: false,
      });
    }

    case 'RECEIVED_CART_ITEM_ERROR': {
      return Object.assign(
        {}, checkout,
        {
          cartErrors: action.payload,
          initialized: false,
          isLoading: false,
          succeeded: false,
          hasInfoError: false,
        },
      );
    }

    case 'RECEIVED_SCO_INFO_ERROR': {
      return Object.assign(
        {}, checkout,
        {
          initialized: true,
          isLoading: false,
          hasInfoError: true,
        },
      );
    }

    case 'RECEIVED_SCO_ERROR': {
      return Object.assign(
        {}, checkout,
        {
          errorDetails: action.payload,
          initialized: true,
          isLoading: false,
          hasError: true,
        },
      );
    }

    case 'RESET_CHECKOUT': {
      return Object.assign({}, checkout, defaultValues);
    }

    case 'INCREASE_RETRY_COUNT': {
      const count = 1 + checkout.retryCount;
      return Object.assign({}, checkout, { retryCount: count });
    }

    case 'RESET_RETRY_COUNT': {
      return Object.assign({}, checkout, { retryCount: 0 });
    }

    case 'UPDATED_CHECKOUT_PROCESS_STATE': {
      return Object.assign(
        {}, checkout,
        { state: action.payload },
      );
    }

    case 'DOING_ORIGIN': {
      return checkout;
    }

    case 'DID_ORIGIN': {
      return Object.assign(
        {}, checkout,
        {
          didOriginRequest: true,
        },
      );
    }

    default:
      return checkout;
  }
};
