import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './SessionState';
import { registerAbortCheckOutCartReducers } from './actions/abortCheckOutCart';
import { registerAcquireCheckoutProcessReducers } from './actions/acquireCheckoutProcess';
import { registerChangeCartReducers } from './actions/changeCart';
import { registerCheckOutCartReducers } from './actions/checkOutCart';
import { registerRefreshCheckoutReducers } from './actions/refreshCheckout';
import { registerSetExternalBillingReducers } from './actions/setExternalBillingInCart';
import { registerUpdateCheckoutPaymentStatusReducers } from './actions/updateCheckoutPaymentStatus';
import * as reducers from './reducers';

export type { default as SessionState } from './SessionState';
export * from './actions';
export * from './selectors';

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers,
  extraReducers: (builder) => {
    registerAbortCheckOutCartReducers(builder);
    registerAcquireCheckoutProcessReducers(builder);
    registerChangeCartReducers(builder);
    registerCheckOutCartReducers(builder);
    registerRefreshCheckoutReducers(builder);
    registerSetExternalBillingReducers(builder);
    registerUpdateCheckoutPaymentStatusReducers(builder);
  },
});

export default sessionSlice.reducer;

export const {
  addErrorItemToCart,
  clearCart,
  enrichCartFromTerminalPayment,
  manipulateCartItem,
  removeErrorItemFromCart,
  reopenCart,
  resetSession,
} = sessionSlice.actions;
