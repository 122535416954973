import { useCallback } from 'react';
import usePoller from '../polling/usePoller';
import { useAppSelector } from '../store';
import useProject from '../useProject';
import isNetworkError from '../api/isNetworkError';
// @ts-ignore
import postHealthStatus from './api/postHealthStatus';
import HealthStatus from './HealthStatus';

const DEFAULT_TIMEOUT = 10000;

export interface UseHealthCheckOptions {
  interval: number;
  onTick: (status: HealthStatus) => void;
}

/**
 * Checks connection health to the Snabble API ands when available, sends the
 * device status to the API.
 */
export default function useHealthCheck({
  interval,
  onTick,
}: UseHealthCheckOptions) {
  const project = useProject();
  const clientToken = useAppSelector(state => state.token.main);
  const checkoutDeviceId = useAppSelector(state => state.root.terminal.config?.id);
  const scoIsOnline = useAppSelector(state => state.appStatus.online);
  const scoErrors = useAppSelector(state => state.appStatus.errors);

  const handleTick = useCallback(async (signal: AbortSignal) => {
    try {
      await postHealthStatus({
        checkoutDeviceId: checkoutDeviceId!,
        clientToken,
        project: project!,
        signal,
        timeout: Math.min(interval, DEFAULT_TIMEOUT),
        isOnline: scoIsOnline,
        errors: scoErrors,
      });
      onTick(HealthStatus.Online);
    } catch (e) {
      if (isNetworkError(e)) {
        onTick(HealthStatus.Offline);
      } else {
        onTick(HealthStatus.Unknown);
      }
    }
  }, [checkoutDeviceId, clientToken, interval, project, onTick, scoErrors, scoIsOnline]);

  // When no project or checkout device ID is available, don't do any health
  // checks as those information are retrieved from the locally running
  // terminal service anyway
  const disabled = !project || !checkoutDeviceId || !clientToken;

  usePoller({
    onTick: handleTick,
    interval,
    disabled,
    immediate: true,
  });
}
