import { v4 as uuidv4 } from 'uuid';
import ScannedCodeInfo from '../../../barcode/ScannedCodeInfo';
import Coupon from '../../../coupon/Coupon';
import CartItemChangeType from '../modification/CartItemChangeType';
import CartItemChangeSet from '../modification/CartItemChangeSet';
import { RootState } from '../../../store';
import CartItemType from '../CartItemType';
import ErrorWithCode from '../../../error/ErrorWithCode';

interface ScannedCouponInfo {
  coupon: Coupon;
  scannedCode: ScannedCodeInfo;
}

export interface AddCouponCartItemOptions {
  couponInfo: ScannedCouponInfo
  index?: number;
}

export default function addCoupon(state: RootState, {
  couponInfo,
  index,
}: AddCouponCartItemOptions): CartItemChangeSet {
  // Prevent that the same coupon is added multiple times
  const couponId = couponInfo.coupon.id;

  if (
    state.session.content.cartItems.some(item => (
      item.type === CartItemType.Coupon &&
      item.couponID === couponId
    ))
  ) {
    throw new ErrorWithCode(
      'coupon_already_added',
      `The coupon with the ID '${couponId}' has already been added`,
    );
  }

  return [{
    type: CartItemChangeType.Add,
    params: {
      id: uuidv4(),
      amount: 1,
      couponID: couponId,
      scannedCode: couponInfo.scannedCode.scannedByUser,
      redeemed: false,
    },
    info: couponInfo,
    index,
  }];
}
