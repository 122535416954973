import ScannedItemInfo from '../../../scan-info/ScannedItemInfo';
import CartItem from '../CartItem';
import CartItemChangeSet from '../modification/CartItemChangeSet';

/**
 * Merges the item infos from the items that are currently in the cart with
 * the items that are going to be changed in the cart.
 *
 * @param items - The items currently in the cart
 *
 * @param changes - The changes that are going to be applied to the cart
 *
 * @returns A map where the keys are the IDs of the particular cart items,
 * including those who are going to be added, updated or removed.
 */
export default function mapScannedItemInfos(items: CartItem[], changes: CartItemChangeSet) {
  const itemInfos = new Map<string, ScannedItemInfo>();

  items.forEach((item) => {
    itemInfos.set(item.id, item.info);
  });

  changes.forEach((change) => {
    if ('info' in change && 'params' in change) {
      itemInfos.set(change.params.id, change.info);
    }
  });

  return itemInfos;
}
