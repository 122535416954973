import { Draft } from '@reduxjs/toolkit';
import isFinalizedCheckoutProcess from '../../checkout-process/isFinalizedCheckoutProcess';
import SessionState from '../SessionState';
import SessionStatus from '../SessionStatus';

export default function reopenCart(state: Draft<SessionState>) {
  if (
    !state.checkoutProcess ||
    !isFinalizedCheckoutProcess(state.checkoutProcess.state)
  ) return;

  /* eslint-disable no-param-reassign */
  state.failureCause = undefined;
  state.status = SessionStatus.Cart;
  state.checkoutProcess = undefined;
  /* eslint-enable */
}
