// @ts-ignore
import ProductDb from '../db/ProductDb';
import ScannedCodeInfo from '../barcode/ScannedCodeInfo';
import mapProductToPlainObject from './mapProductToPlainObject';
import ScannedProductInfo from './ScannedProductInfo';

export default function findProductInfoForCodes(codes: ScannedCodeInfo[]):
ScannedProductInfo | undefined {
  let product!: any;

  const scannedCode = codes.find((codeInfo) => {
    const p = ProductDb.findByCodeUsingTemplate(codeInfo);
    if (p) product = p;
    return !!p;
  });

  if (!scannedCode) return undefined;

  return {
    product: mapProductToPlainObject(product),
    scannedCode,
  };
}
