import useProject from '../useProject';
import ProjectFeature from './ProjectFeature';
import projectFeatureRegistry, { Environment } from './projectFeaturesConfig';
// @ts-ignore
import { whichEnvironment } from '../helper/urls';
import { useAppSelector } from '../store';

export default function useHasProjectFeature(feature: ProjectFeature) {
  const project = useProject();
  const terminalConfig = useAppSelector(state => state.root.terminal.config) as any;

  if (!project || !terminalConfig?.endpoint) return false;

  const { endpoint } = terminalConfig;
  const environment = whichEnvironment(new URL(endpoint)) as Environment;

  return !!projectFeatureRegistry.get(project)?.[environment]?.[feature];
}
