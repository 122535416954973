interface AbortablePromise {
  abort(): void;
  finally(cb: () => void): void;
}

export default function handleDispatchAbort<T extends AbortablePromise>(
  result: T,
  signal: AbortSignal,
) {
  const abortHandler = () => { result.abort(); };

  if (signal.aborted) {
    abortHandler();
  } else {
    signal.addEventListener('abort', abortHandler);
    result.finally(() => {
      signal.removeEventListener('abort', abortHandler);
    });
  }

  return result;
}
