import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCheckoutProcess from '../useCheckoutProcess';
import deleteIbanCandidate from '../../iban/api/deleteIbanCandidate';
import postIban from '../../iban/api/postIban';
import { useAppSelector } from '../../store';
import useAbortSignal from '../../abort/useAbortSignal';
// @ts-ignore
import Button from '../../button/Button';
// @ts-ignore
import { ReactComponent as TransferIBAN } from '../../images/sco-image-transfer-iban.svg';
import logger from '../../logging';

export default function CheckoutStoreIbanView() {
  const abortSignal = useAbortSignal();
  const checkoutProcess = useCheckoutProcess();
  const clientToken = useAppSelector(state => state.token.main);
  const { t } = useTranslation();

  const iban = useAppSelector(state => state.session.iban);

  const originCandidateUrl = useMemo(
    () => checkoutProcess?.links.originCandidate?.href,
    [checkoutProcess],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  const isVisible = useMemo(() => !!iban && !isDismissed, [iban, isDismissed]);

  const handleConfirmStoreIban = useCallback(async () => {
    if (!iban || !checkoutProcess || !originCandidateUrl) return;

    setIsLoading(true);

    logger.info(`Sending post iban request for "${checkoutProcess?.id}"`, { tag: 'Payment' });
    await postIban({
      url: originCandidateUrl,
      clientToken,
      signal: abortSignal,
      params: {
        origin: iban,
        clientID: checkoutProcess.clientID,
        id: checkoutProcess.id,
      },
    });

    setIsLoading(false);
    setIsDismissed(true);
  }, [abortSignal, checkoutProcess, clientToken, iban, originCandidateUrl]);

  const handleRejectStoreIban = useCallback(async () => {
    if (!originCandidateUrl) return;

    setIsLoading(true);

    logger.info(`Sending delete iban candidate request for "${checkoutProcess?.id}"`, { tag: 'Payment' });
    await deleteIbanCandidate({
      url: originCandidateUrl,
      clientToken,
      signal: abortSignal,
    });

    setIsLoading(false);
    setIsDismissed(true);
  }, [abortSignal, checkoutProcess, clientToken, originCandidateUrl]);

  const buttonsDisabled = useMemo(
    () => !checkoutProcess || !iban || !originCandidateUrl || isLoading,
    [checkoutProcess, iban, isLoading, originCandidateUrl],
  );

  // TODO: Perform some animation?
  if (!isVisible) return null;

  return ((
    <div className="default">
      <div className="default__content">
        <TransferIBAN className="default__image" />
        <p>{t('terminal.saveIBAN')}</p>

        <div className="default__actions">
          <Button
            className="default__btn"
            onClick={handleConfirmStoreIban}
            disabled={buttonsDisabled}
          >
            {t('btn.yes')}
          </Button>

          <Button
            className="default__btn"
            onClick={handleRejectStoreIban}
            disabled={buttonsDisabled}
          >
            {t('btn.no')}
          </Button>
        </div>
      </div>
    </div>
  ));
}
