import PrinterStatus from './PrinterStatus';

const AVAILABLE_PRINTER_STATUSES = [
  PrinterStatus.Busy,
  PrinterStatus.Initializing,
  PrinterStatus.PowerSafe,
  PrinterStatus.Processing,
  PrinterStatus.Ready,
  PrinterStatus.WarmingUp,
];

export default function isReadyPrinterStatus(status: PrinterStatus) {
  return AVAILABLE_PRINTER_STATUSES.includes(status);
}
