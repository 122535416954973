import CheckoutProcessState from './CheckoutProcessState';

const FINAL_STATES = [
  CheckoutProcessState.Aborted,
  CheckoutProcessState.Failed,
  CheckoutProcessState.Rejected,
  CheckoutProcessState.Succeeded,
];

export default function isFinalizedCheckoutProcess(state: CheckoutProcessState) {
  return FINAL_STATES.includes(state);
}
