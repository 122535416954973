const defaultTerminalState = {
  status: '',
  finished: false,
  created: false,
  customerReceipt: null,
  merchantReceipt: null,
  receiptPrintWidth: 42,
  iban: null,
  terminalResult: null,
  initializingReadCard: false,
  printerStatus: '',
  hasPrinterError: false,
};

const originType = {
  tegut: 'tegutEmployeeID',
};

export default (terminal, action) => {
  switch (action.type) {
    case 'GOT_TERMINAL_CONFIG': {
      return Object.assign(
        {}, terminal,
        {
          config: action.payload,
        },
      );
    }

    case 'WRONG_AUTHENTICATION': {
      return Object.assign(
        {}, terminal,
        {
          config: {},
        },
      );
    }

    case 'CREATED_TERMINAL_PROCESS': {
      return Object.assign(
        {}, terminal,
        {
          status: '',
          created: true,
        },
      );
    }

    case 'TERMINAL_ERROR': {
      return Object.assign(
        {}, terminal,
        {
          status: 'error',
          finished: false,
          created: false,
        },
      );
    }

    case 'GOT_TERMINAL_CARD_STATUS': {
      const response = action.payload;

      return Object.assign(
        {}, terminal,
        {
          initializingReadCard: false,
          cardStatus: response.status,
          card: response.card || {},
          type: originType[response.type],
        },
      );
    }

    case 'READING_CARD': {
      return Object.assign(
        {}, terminal,
        {
          initializingReadCard: true,
          cardStatus: '',
          card: {},
          type: '',
        },
      );
    }

    case 'REMOVE_CARD': {
      return Object.assign(
        {}, terminal,
        {
          initializingReadCard: false,
          cardStatus: '',
          card: {},
          type: '',
        },
      );
    }

    case 'GOT_TERMINAL_STATUS': {
      const response = action.payload;
      let { merchantReceipt, customerReceipt } = defaultTerminalState;

      if (response.terminalResult) {
        // eslint-disable-next-line
        merchantReceipt = response.terminalResult.merchantReceipt;
        // eslint-disable-next-line
        customerReceipt = response.terminalResult.customerReceipt;
      }

      return Object.assign(
        {}, terminal,
        {
          status: response.status,
          finished: ['pending', 'ready', 'ageVerificationPending'].indexOf(response.status) === -1,
          iban: response.iban,
          receiptPrintWidth: response.receiptPrintWidth || defaultTerminalState.receiptPrintWidth,
          terminalResult: response.terminalResult,
          customerReceipt,
          merchantReceipt,
        },
      );
    }

    case 'GOT_PRINTER_STATUS': {
      return Object.assign(
        {}, terminal,
        {
          printerStatus: action.payload,
          hasPrinterError: ['ready', 'busy', 'processing', 'initializing', 'power safe', 'warming up'].indexOf(action.payload) === -1,
        },
      );
    }

    case 'RESET_CHECKOUT': {
      return Object.assign(
        {}, terminal,
        defaultTerminalState,
      );
    }

    default:
      return terminal;
  }
};
