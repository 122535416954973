import Check from './Check';
import CheckState from './CheckState';
import CheckType from './CheckType';

export default function extractRequiredAgeFromChecks(checks: Check[]) {
  if (!checks?.length) return undefined;

  const ageCheck = checks.find(check => (
    check.type === CheckType.MinAge &&
    check.state === CheckState.Postponed
  ));
  if (!ageCheck) {
    return undefined;
  }

  return ageCheck.requiredAge;
}
