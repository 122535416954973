import axios from 'axios';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import client from '../../api/client';
import loggedError from '../../api/loggedError';
import mergeRetryConfigs from '../../api/mergeRetryConfigs';
import CheckoutProcess from '../CheckoutProcess';

export interface GetCheckoutOptions extends AuthenticatedApiOptions {
  url: string;
  retries?: number;
}

export default async function getCheckout({
  clientToken,
  retries,
  signal,
  url,
}: GetCheckoutOptions) {
  try {
    const response = await client.get<CheckoutProcess>(
      url,
      {
        'axios-retry': mergeRetryConfigs({ retries }),
        headers: {
          'Client-Token': clientToken,
        },
        signal,
      },
    );
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return undefined;
    }

    throw loggedError(
      'process_not_fetchable',
      'Checkout process could not be fetched',
      e,
    );
  }
}
