import { createContext } from 'react';
import ScannerSubscription from './ScannerSubscription';

interface ScannerContext {
  subscriptions: Map<string, ScannerSubscription>;
}

export default createContext<ScannerContext>({
  subscriptions: new Map<string, ScannerSubscription>(),
});
