/* eslint-disable max-len */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import findExternalBilling from '../../external-billing/findExternalBilling';
import requireTerminalConfig from '../../terminal/requireTerminalConfig';
import SessionState from '../SessionState';
import changeCart from './changeCart';
import ExternalBilling from '../../external-billing/ExternalBilling';

export interface ExternalBillingProps {
  code: string | undefined;
  // HACK password being optional is legacy
  password?: string;
  contactPersonID?: string;
}

export interface ExternalBillingSubjectProps {
  subject: string | undefined;
}

const setExternalBillingInCart = createAsyncThunk(
  'session/setExternalBillingInCart',
  async ({ code, password, contactPersonID }: ExternalBillingProps, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { project } = requireTerminalConfig(state);
    let externalBilling: ExternalBilling | undefined;
    // NOTE Do not let the linter fool you. This await has indeed an effect.
    // It breaks the setExternalBillingInCart and resetExternalBillingInCart
    // tests (for some reason).
    if (code) externalBilling = await findExternalBilling(project, code);
    if (externalBilling && password && contactPersonID) {
      externalBilling = { ...externalBilling, password, contactPersonID };
      // NOTE if password and contactPersonID are set that this must be contactPersonCredentials
      externalBilling.originType = 'contactPersonCredentials';
    }

    // HACK Leinweber needs the externalBilling in the loyaltyCard field
    // and that field needs to be signed through the checkoutInfo call
    if (project === 'leinweber-baucentrum-5cvbt954') {
      dispatch(changeCart({
        itemChanges: [],
        customerCard: state.session.content.customerCard,
        externalBilling,
      }));
    }

    return externalBilling;
  },
);

export default setExternalBillingInCart;

export const setExternalBillingSubject = createAsyncThunk(
  'session/setExternalBillingSubject ',
  ({ subject }: ExternalBillingSubjectProps, { getState }) => {
    const state = getState() as RootState;
    const externalBillingState = { ...state.session.externalBilling, subject: subject || '' } as ExternalBilling;
    return externalBillingState;
  },
);

export function registerSetExternalBillingReducers(builder: ActionReducerMapBuilder<SessionState>) {
  builder
    .addCase(setExternalBillingInCart.fulfilled, (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.externalBilling = action.payload;
    })
    .addCase(setExternalBillingSubject.fulfilled, (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.externalBilling = action.payload;
    });
}
