import { useEffect, useRef } from 'react';

/**
 * A hook that provides an `AbortSignal` that gets aborted when the component
 * unmounts.
 */
export default function useAbortSignal() {
  const controllerRef = useRef(new AbortController());

  // abort on unmount only
  useEffect(() => () => {
    controllerRef.current.abort();
  }, []);

  return controllerRef.current.signal;
}
