import React from 'react';
import { useAppSelector } from '../store';
import CheckoutGrantedView from './granted/CheckoutGrantedView';
import CheckoutProcessingView from './processing/CheckoutProcessingView';
import CheckoutPendingView from './pending/CheckoutPendingView';
import CheckoutAbortedView from './aborted/CheckoutAbortedView';
import CheckoutFailedView from './failed/CheckoutFailedView';
import CheckoutSucceededView from './succeeded/CheckoutSucceededView';
import CheckoutRejectedView from './rejected/CheckoutRejectedView';
import CheckoutProcessState from '../checkout-process/CheckoutProcessState';
import DebouncedRouter from '../routing/DebouncedRouter';
import Route from '../routing/Route';

export default function CheckoutLayoutView() {
  const state = useAppSelector(s => s.session.checkoutProcess?.state);

  return (
    <div data-testid="checkoutLayoutView">
      <DebouncedRouter state={state}>
        <Route when={CheckoutProcessState.Pending}>
          <CheckoutPendingView />
        </Route>
        <Route when={CheckoutProcessState.Granted}>
          <CheckoutGrantedView />
        </Route>
        <Route when={CheckoutProcessState.Processing}>
          <CheckoutProcessingView />
        </Route>
        <Route when={CheckoutProcessState.Aborted}>
          <CheckoutAbortedView />
        </Route>
        <Route when={CheckoutProcessState.Rejected}>
          <CheckoutRejectedView />
        </Route>
        <Route when={CheckoutProcessState.Failed}>
          <CheckoutFailedView />
        </Route>
        <Route when={CheckoutProcessState.Succeeded}>
          <CheckoutSucceededView />
        </Route>
      </DebouncedRouter>
    </div>
  );
}
