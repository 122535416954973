import React from 'react';
import AppDbRefresher from '../appdb/AppDbRefresher';
import CouponRefresher from '../coupon/CouponRefresher';
import ProjectConfigRefresher from '../checkout-device/ProjectConfigRefresher';
import TerminalConfigRefresher from '../terminal-config/TerminalConfigRefresher';
import TokenRefresher from '../token/TokenRefresher';
import MetadataRefresher from '../metadata/MetadataRefresher';
import CigaretteStockRefresher from '../cigarette-stock/CigaretteStockRefresher';
import ProjectCategoriesRefresher from '../checkout-device/ProjectCategoriesRefresher';
import PaymentTokenManager from '../token/PaymentTokenManager';

/**
 * The `ConfigManager` loads all required config from local and remote HTTP
 * endpoints and writes the data in the store. Additionally, some configs are
 * updated on a regular basis in the background when possible.
 *
 * **Caution: Please ensure this component is mounted at top level and never
 * gets unmounted conditionally as that will result in all timers being reset.
 * Hence, this may lead to an invalid or outdated application config.**
 */
export default function ConfigManager() {
  return (
    <>
      <TerminalConfigRefresher />
      <TokenRefresher />
      <PaymentTokenManager />
      <MetadataRefresher />
      <ProjectConfigRefresher />
      <ProjectCategoriesRefresher />
      <AppDbRefresher />
      <CigaretteStockRefresher />
      <CouponRefresher />
    </>
  );
}
