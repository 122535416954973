import CheckoutPaymentStatus from '../../checkout-process/CheckoutPaymentStatus';
import { UpdateCheckoutPaymentStatusOptions } from '../../session/actions/updateCheckoutPaymentStatus';
import mapTerminalPaymentStatusToFailureCause from '../../terminal/mapTerminalPaymentStatusToFailureCause';
import TerminalFailureCause from '../../terminal/TerminalFailureCause';
import TerminalPayment from '../../terminal/TerminalPayment';
import TerminalPaymentStatus from '../../terminal/TerminalPaymentStatus';
import TerminalResult from '../../terminal/TerminalResult';

export const DEFAULT_RECEIPT_PRINT_WIDTH = 42;

interface MapPaymentStateToStatusUpdateOptionsOptions {
  clientID: string;
  checkoutDeviceID: string;
  verifiedAge?: number;
}

export default function mapPaymentStateToStatusUpdateOptions(
  payment: TerminalPayment,
  opts: MapPaymentStateToStatusUpdateOptionsOptions,
): UpdateCheckoutPaymentStatusOptions | undefined {
  const result: TerminalResult = {
    ...payment.terminalResult,
    receiptPrintWidth: payment.receiptPrintWidth ?? DEFAULT_RECEIPT_PRINT_WIDTH,
  };

  const params = {
    checkoutDeviceID: opts.checkoutDeviceID,
    clientID: opts.clientID,
    result,
    verifiedAge: opts.verifiedAge,
  };

  if (payment.status === TerminalPaymentStatus.Successful) {
    return {
      status: CheckoutPaymentStatus.Successful,
      params,
    };
  }

  const failureCause = mapTerminalPaymentStatusToFailureCause(payment.status);
  if (!failureCause) {
    return undefined;
  }

  if (failureCause === TerminalFailureCause.UserAborted) {
    // no payment performed in case of user abort. As no receipt is printed,
    // receiptPrintWidth is not passed to the API. Also, there are no other
    // payment result attributes that we can pass.
    params.result = { failureCause };
  } else {
    result.failureCause = failureCause;
  }

  return {
    status: CheckoutPaymentStatus.Failed,
    params,
  };
}
