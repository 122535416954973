import { AcceptedPaymentOrigins } from '../checkout-info/CheckoutInfo';
import PaymentMethod from './PaymentMethod';

function isCompatiblePaymentMethod(value: string): value is PaymentMethod {
  return Object.values(PaymentMethod).includes(value as PaymentMethod);
}

export default function resolveCompatiblePaymentMethods(paymentMethods: string[]) {
  return paymentMethods.filter(isCompatiblePaymentMethod);
}

export function resolveAcceptedPaymentOrigins(paymentOrigins: AcceptedPaymentOrigins[]) {
  const acceptedOrigins: Record<string, string[]> = {};
  paymentOrigins?.forEach((p) => {
    acceptedOrigins[p.id] = p.acceptedOriginTypes || [];
  });
  return acceptedOrigins;
}
