import { RootState } from '../../store';

/**
 * Selector that returns all cart items.
 *
 * @param state The root state.
 */
export default function cartItems(state: RootState) {
  return state.session.content.cartItems;
}
