import BaseLineItem from './BaseLineItem';
import LineItem from './LineItem';
import LineItemType from './LineItemType';

export default interface ReferringLineItem extends BaseLineItem {
  type: LineItemType;
  refersTo: string;
  price: number;
  totalPrice: number;
}

export function isReferringLineItem(value: LineItem): value is ReferringLineItem {
  return !!value && 'refersTo' in value && !!value.refersTo;
}
