import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import SessionState from '../SessionState';
import postCheckoutPaymentStatus, {
  PostCheckoutPaymentStatusResult,
  PostCheckoutPaymentStatusParams,
} from '../../checkout-process/api/postCheckoutPaymentStatus';
import { CheckoutProcessLinks } from '../../checkout-process/CheckoutProcessLinks';
import ErrorWithCode from '../../error/ErrorWithCode';
import CheckoutPaymentStatus from '../../checkout-process/CheckoutPaymentStatus';
import logger from '../../logging';
import mergeRetryConfigs from '../../api/mergeRetryConfigs';
import isRetryableError from '../../api/isRetryableError';

export const STATUS_LINKS: Record<CheckoutPaymentStatus, keyof CheckoutProcessLinks> = {
  [CheckoutPaymentStatus.Failed]: 'paymentFailed',
  [CheckoutPaymentStatus.Processing]: 'paymentProcessing',
  [CheckoutPaymentStatus.Successful]: 'paymentSuccessful',
};

export type UpdateCheckoutPaymentStatusOptions =
  | { status: CheckoutPaymentStatus.Processing }
  | {
    status: CheckoutPaymentStatus.Successful | CheckoutPaymentStatus.Failed,
    params: PostCheckoutPaymentStatusParams
  };

const updateCheckoutPaymentStatus = createAsyncThunk(
  'session/updateCheckoutPaymentStatus',
  async (options: UpdateCheckoutPaymentStatusOptions, { getState, signal }) => {
    const state = getState() as RootState;

    const field = STATUS_LINKS[options.status];
    if (!field) {
      throw new ErrorWithCode(
        'invalid_payment_state',
        'Invalid payment state',
      );
    }

    const checkoutProcess = state.session.checkoutProcess!;

    const link = checkoutProcess.links[field];
    if (!link) {
      throw new ErrorWithCode(
        'missing_payment_link',
        `Missing payment link: ${field}`,
      );
    }

    let params: PostCheckoutPaymentStatusParams | undefined;
    if ('params' in options) {
      ({ params } = options);
    }

    const clientToken = state.token.payment;

    logger.info(`Updating checkout payment status to "${options.status}" for "${checkoutProcess.id}"`, { tag: 'Checkout' });

    const { result, checkout } = await postCheckoutPaymentStatus({
      clientToken,
      signal,
      url: link.href,
      params,
      axiosOptions: {
        'axios-retry': mergeRetryConfigs({
          retries: 2,
          retryCondition: (error: any) => (isRetryableError(error, true)),
        }),
      },
    });

    if (result !== PostCheckoutPaymentStatusResult.Ok) {
      return { result };
    }

    if (!checkout) {
      throw new ErrorWithCode(
        'process_not_found',
        'Checkout process not found',
      );
    }

    if (options.status === CheckoutPaymentStatus.Failed) {
      return { result, checkout, failureCause: options.params.result?.failureCause };
    }

    return { result, checkout };
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      return !!state.session.checkoutProcess;
    },
  },
);

export default updateCheckoutPaymentStatus;

// eslint-disable-next-line
export function registerUpdateCheckoutPaymentStatusReducers(
  builder: ActionReducerMapBuilder<SessionState>) {
  /* eslint-disable no-param-reassign */
  builder
    .addCase(updateCheckoutPaymentStatus.pending, (state) => {
      state.loadingCount += 1;
    })
    .addCase(updateCheckoutPaymentStatus.fulfilled, (state, action) => {
      state.loadingCount -= 1;
      if (action.payload.failureCause) {
        state.failureCause = action.payload.failureCause;
      }
      if (action.payload.checkout) {
        state.checkoutProcess = action.payload.checkout;
      }
    })
    .addCase(updateCheckoutPaymentStatus.rejected, (state) => {
      state.loadingCount -= 1;
    });
  /* eslint-enable no-param-reassign */
}
