import { useCallback } from 'react';
import useProject from '../../useProject';
import { useAppDispatch, useAppSelector } from '../../store';
import { setOnline, setOffline } from '../appStatusSlice';
import postHealthStatus from './postHealthStatus';
import logger from '../../logging';

export interface PostOfflineProps {
  reason?: string;
}

export default function useHealthStatus() {
  const project = useProject();
  const clientToken = useAppSelector(state => state.token.main);
  const checkoutDeviceId = useAppSelector(state => state.root.terminal.config?.id);
  const dispatch = useAppDispatch();

  const postOnline = useCallback(async () => {
    dispatch(setOnline());

    if (!project || !checkoutDeviceId) return;
    await postHealthStatus({
      checkoutDeviceId,
      clientToken,
      project,
    });
  }, [checkoutDeviceId, clientToken, dispatch, project]);

  const postOffline = useCallback(async ({ reason }: PostOfflineProps) => {
    const message = reason ? `ui component is offline (${reason})` : 'ui component is offline';
    logger.info(message, { tag: 'Http' });
    dispatch(setOffline([
      {
        type: 'ui_component_offline',
        message,
      },
    ]));

    if (!project || !checkoutDeviceId) return;
    await postHealthStatus({
      checkoutDeviceId,
      clientToken,
      project,
      isOnline: false,
      errors: [
        {
          type: 'ui_component_offline',
          message,
        },
      ],
    });
  }, [checkoutDeviceId, clientToken, dispatch, project]);

  return {
    postOnline,
    postOffline,
  };
}
