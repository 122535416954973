import { useEffect } from 'react';
// @ts-ignore
import { whichEnvironment } from '../helper/urls';
import logger from '../logging';
import { useAppSelector } from '../store';
import ReleaseChannel from './ReleaseChannel';

const DEFAULT_PROTOCOL = 'https:';
const DEFAULT_PORT = '443';

const SUBDOMAINS: Record<ReleaseChannel, string> = {
  [ReleaseChannel.Stable]: 'sco',
  [ReleaseChannel.Preview]: 'sco-preview',
};

function isRemoteHost(host: string) {
  return host.includes('snabble');
}

function getDomain(currentHost: string) {
  const env = whichEnvironment({ host: currentHost });
  if (env === 'prod') return 'snabble.io';

  return `snabble-${env}.io`;
}

export interface ReleaseChannelInterceptorProps {
  location?: { href: string }
}

/**
 * When needed, redirects the browser to sco-preview.snabble.io if the release
 * channel for this checkout device is set to `preview` in the config. Does also
 * work vice-versa.
 */
export default function ReleaseChannelInterceptor({
  location = document.location,
}: ReleaseChannelInterceptorProps) {
  const releaseChannel = useAppSelector(state =>
    state.checkoutDevice.config.uiReleaseChannel);

  useEffect(() => {
    if (!releaseChannel) return;

    const subdomain = SUBDOMAINS[releaseChannel];
    if (!subdomain) return;

    const currentHost = new URL(location.href).host;
    const desiredHost = `${subdomain}.${getDomain(currentHost)}`;
    const url = new URL(location.href);
    if (url.host === desiredHost) {
      return;
    }

    if (!isRemoteHost(url.host)) {
      // eslint-disable-next-line no-console
      console.info(`redirect to ${desiredHost} skipped as current host is considered local`);
      return;
    }

    url.protocol = DEFAULT_PROTOCOL;
    url.port = DEFAULT_PORT;
    url.host = desiredHost;

    logger.info(`Redirecting to ${releaseChannel} release channel: ${url} (from: ${location.href})`, { tag: 'App' });

    // eslint-disable-next-line no-param-reassign
    location.href = url.toString();
  }, [location, releaseChannel]);

  return null;
}
