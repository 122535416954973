import React from 'react';
import { useAppSelector } from '../../store';
import AppCheckoutSucceededView from './AppCheckoutSucceededView';
import { Route, Router } from '../../routing';
import ScoCheckoutSucceededView from './ScoCheckoutSucceededView';
import SessionOrigin from '../../session/SessionOrigin';

export default function CheckoutSucceededView() {
  const origin = useAppSelector(state => state.session.origin);

  return (
    <Router state={origin}>
      <Route when={SessionOrigin.App}>
        <AppCheckoutSucceededView />
      </Route>
      <Route when={SessionOrigin.SCO}>
        <ScoCheckoutSucceededView />
      </Route>
    </Router>
  );
}
