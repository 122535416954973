import { RootState } from '../../store';

/**
 * Determines whether the cart contains any violations.
 *
 * @param state The root state.
 * @returns `true` when the cart contains violations, `false` when it does not
 * not.
 */
export default function cartHasViolations(state: RootState) {
  const violations = state.session.content.signedCheckoutInfo?.checkoutInfo.violations;
  return !!violations && violations.length > 0;
}
