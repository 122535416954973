enum FulfillmentState {
  Open = 'open',
  Allocating = 'allocating',
  // pending states
  Allocated = 'allocated',
  Processing = 'processing',
  // final states
  Aborted = 'aborted',
  AllocationFailed = 'allocationFailed',
  AllocationTimedOut = 'allocationTimedOut',
  Failed = 'failed',
  Processed = 'processed',
}

export default FulfillmentState;
