import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Coupon from './Coupon';
import { RootState } from '../store';
import fetchCoupons from './api/fetchCoupons';

export interface CouponState {
  items: Coupon[];
  loadingCount: number;
}

const initialState: CouponState = {
  items: [],
  loadingCount: 0,
};

export const couponsAreRefreshing =
  (state: RootState) => state.coupon.loadingCount > 0;

export const refreshCoupons = createAsyncThunk(
  'coupon/refreshCoupons',
  (_, { getState }) => {
    const state = getState() as RootState;
    const { couponsURL } = state.checkoutDevice.config;
    if (!couponsURL) return null;

    return fetchCoupons({
      clientToken: state.token.main,
      url: couponsURL,
    });
  },
  {
    // Only allow to create a checkout info if no other checkout info is
    // currently being created
    condition: (_, { getState }) => !couponsAreRefreshing(getState() as RootState),
  },
);

/* eslint-disable no-param-reassign */

export const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(refreshCoupons.pending, (state) => {
        state.loadingCount += 1;
      })
      .addCase(refreshCoupons.fulfilled, (state, action) => {
        state.loadingCount -= 1;

        if (!action.payload) return;
        state.items = action.payload;
      })
      .addCase(refreshCoupons.rejected, (state) => {
        state.loadingCount -= 1;
      });
  },
});

/* eslint-enable */

export default couponSlice.reducer;
