import { useInterval } from '@mantine/hooks';
import { duration } from 'moment';
import { useCallback, useEffect } from 'react';
import useAbortSignal from '../../abort/useAbortSignal';
import isRetryableError from '../../api/isRetryableError';
import mergeRetryConfigs from '../../api/mergeRetryConfigs';
import abortCheckout from '../../checkout-process/api/abortCheckout';
import postCheckoutPaymentStatus from '../../checkout-process/api/postCheckoutPaymentStatus';
import CheckoutPaymentStatus from '../../checkout-process/CheckoutPaymentStatus';
import DeviceRole from '../../device-type/DeviceRole';
import useDeviceTypeConfig from '../../device-type/useDeviceTypeConfig';
import logging from '../../logging';
import { STATUS_LINKS } from '../../session/actions/updateCheckoutPaymentStatus';
import SessionStatus from '../../session/SessionStatus';
import { useAppSelector } from '../../store';
import getToken from '../../token/api/getToken';
import useProject from '../../useProject';
import deleteUnshippedCheckout from './deleteUnshippedCheckout';
import getUnshippedCheckouts from './getUnshippedCheckouts';
import { UnshippedProcess } from './unshippedCheckoutsDb';

// NOTE the cleanup interval should always be long enough so that all previous
// status posts are completed
export const CLEANUP_INTERVAL = duration('5', 'minutes').asMilliseconds();

export default function UnshippedCheckoutsManager() {
  const projectId = useProject();
  const abortSignal = useAbortSignal();
  const deviceType = useDeviceTypeConfig();
  const terminalConfig = useAppSelector(state => state.root.terminal.config);

  const customerIsInCheckout = useAppSelector(state =>
    state.session.status === SessionStatus.Checkout);

  const hasCart = deviceType?.hasCart ?? false;

  const updatePayments = useCallback(async (processes: UnshippedProcess[]) => {
    // NOTE: this log statement is propably a bit too verbose but as long as some SCO are not
    // retrying their payment status updates, we need to find out why.
    logging.info(`Handling ${processes?.length} unshipped processes. (Prequisites: terminalConfig: ${!!terminalConfig}, projectId: ${projectId}, customerIsInCheckout: ${customerIsInCheckout})`);
    if (processes.length === 0) return;
    if (!terminalConfig || !projectId) return;

    if (customerIsInCheckout) {
      logging.info('Skipping handling of unshipped processes while sco is in checkout.');
      return;
    }

    // we get our own payment only token here
    // as we only want to update the payment state
    try {
      logging.info(`Getting paymentSystem token to update payment status for ${processes?.length} unshipped processes.`);
      const paymentToken = await getToken({
        id: terminalConfig.id,
        projectId,
        roles: [DeviceRole.PaymentSystem, DeviceRole.PointOfSale],
        secret: terminalConfig.secret,
        signal: abortSignal,
        axiosOptions: {
          'axios-retry': mergeRetryConfigs({
            retries: 2,
            retryCondition: (error: any) => (isRetryableError(error, true)),
          }),
        },
      });

      await Promise.all(processes.map(async (process) => {
        const { status } = process.paymentStatusUpdate;
        const field = STATUS_LINKS[status];

        if (!field) {
          logging.error(`Unknown status "${status}" for "${process.checkoutProcessId}" while handling unshipped processes`);
          return;
        }

        const href = process.links[field]?.href;
        if (!href) {
          logging.error(`Missing link "${field}" for "${process.checkoutProcessId}" while handling unshipped processes`);
          return;
        }

        // this should not happen, as we only add failed / succeeded processes to db
        if (status === CheckoutPaymentStatus.Processing) {
          logging.error('Invalid unshipped checkout. Shipping checkout ' +
            'as "processing" should never happen, ' +
            `checkout id: ${process.checkoutProcessId}`);

          const res = Promise.all([
            deleteUnshippedCheckout({
              checkoutProcessId: process.checkoutProcessId,
              projectId,
            }),
            abortCheckout({
              clientToken: paymentToken.token,
              url: process.links.self.href,
              signal: abortSignal,
            }),
          ]);

          await res;

          return;
        }

        let willDeleteUnshippedCheckout = false;

        try {
          const { result } = await postCheckoutPaymentStatus({
            clientToken: paymentToken.token,
            signal: abortSignal,
            url: href,
            params: process.paymentStatusUpdate.params,
            isDeliveredLate: true,
            axiosOptions: {
              'axios-retry': mergeRetryConfigs({
                retries: 2,
                retryCondition: (error: any) => (isRetryableError(error, true)),
              }),
            },
          });

          willDeleteUnshippedCheckout = true;

          logging.info(`Successfully posted payment status "${status}" for "${process.checkoutProcessId}". Backend responded with "${result}"`);
        } catch (e) {
          logging.error('Cannot update checkout payment status to ' +
            `"${status}" for checkout "${process.checkoutProcessId}". ` +
            `Error: "${e}"`);
        }

        if (willDeleteUnshippedCheckout) {
          await deleteUnshippedCheckout({
            checkoutProcessId: process.checkoutProcessId,
            projectId,
          });
        }
      }));
    } catch (error) {
      logging.warning(`Cannot get payment token to ship processes. Error: "${error}"`);
    }
  }, [abortSignal, customerIsInCheckout, projectId, terminalConfig]);

  const refreshProcesses = useCallback(async () => {
    if (!projectId) return;
    await updatePayments(await getUnshippedCheckouts({ projectId }));
  }, [projectId, updatePayments]);

  const interval = useInterval(refreshProcesses, CLEANUP_INTERVAL);

  useEffect(() => {
    if (!projectId || !hasCart) return () => {};
    interval.start();

    return () => {
      interval.stop();
    };
  }, [hasCart, interval, projectId]);

  return null;
}
