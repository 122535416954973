// @ts-ignore
import ProductDb from '../db/ProductDb';
import logger from '../logging';
import Metadata from '../metadata/Metadata';

export interface InitAppDbOptions {
  authToken: string;
  metadata: Metadata;
  project: string;
  shopId: string;
}

export default function initAppDb({
  authToken,
  metadata,
  project,
  shopId,
}: InitAppDbOptions) {
  return new Promise<void>((resolve, reject) => {
    ProductDb.init(
      authToken,
      {
        ...metadata,
        project,
        shop: shopId,
      },
      () => { resolve(); },
      () => {
        reject(new Error('App DB could not be initialized'));
      },
      logger.info,
      logger.warning,
    );
  });
}
