export default (fulfillments, action) => {
  switch (action.type) {
    case 'GOT_FULFILLMENT_PRODUCTS': {
      return {
        ...fulfillments,
        cigarettes: action.payload,
      };
    }
    default:
      return fulfillments;
  }
};
