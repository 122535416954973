import React, { CSSProperties, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { useAppSelector } from '../store';
// @ts-ignore
import ThemeSpecificFonts from '../fonts/ThemeSpecificFonts';
// @ts-ignore
import lookUpInManifest from '../helper/assets';
import useProject from '../useProject';
import projectCSSVariables from '../styles/projectCSSVariables';

interface ThemeProps {
  children: ReactNode;
}

export default function Theme({ children }: ThemeProps) {
  const project = useProject();

  const assets = useAppSelector(state => state.root.assets.files);

  const landingPageType = useAppSelector(state =>
    state.checkoutDevice.config.landingPageType);

  const animationType = useAppSelector(state =>
    state.checkoutDevice.config.animationType);

  const projectThemeVars = project ? projectCSSVariables[project] : {};

  const backgroundAssets = useMemo(() => {
    const main = lookUpInManifest(
      assets,
      'png',
      false,
      { src: 'background-sco-main' },
    );

    const sub = lookUpInManifest(
      assets,
      'png',
      false,
      { src: 'background-sco-sub' },
    );

    return {
      sub, main,
    };
  }, [assets]);

  const backgroundVars = useMemo<CSSProperties>(() => {
    if (!backgroundAssets.main && !backgroundAssets.sub) return {};

    return {
      '--theme-background-image-main': `url(${backgroundAssets.main})`,
      '--theme-background-image-sub': `url(${backgroundAssets.sub})`,
    } as CSSProperties;
  }, [backgroundAssets]);

  return (
    <div
      data-testid="theme"
      className={cn(
        'theme',
        landingPageType && `theme--${landingPageType}`,
        project && `theme--${project}`,
        animationType && `theme--${animationType}-animation`,
      )}
      style={{ ...backgroundVars, ...projectThemeVars }}
    >
      {project && <ThemeSpecificFonts project={project} />}
      {children}
    </div>
  );
}
