import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
// @ts-ignore
import Button from '../../button/Button';
// @ts-ignore
import { ReactComponent as InProgressImage } from '../../images/sco-image-waiting.svg';
import Text from '../../typography/Text';
import './approval-pending.scss';

interface CheckoutApprovalPendingViewProps {
  goBack: () => void;
  id: string;
}

export default function CheckoutApprovalPendingView({
  goBack,
  id,
}: CheckoutApprovalPendingViewProps) {
  const { t } = useTranslation();

  return (
    <div className="approval-pending">
      <div className="approval-pending__content">
        <InProgressImage alt={t('approval-result.wait-instruction')} className="approval-pending__image" />
        <Text className="approval-pending__text">{t('approval-result.wait-instruction')}</Text>
        <QRCode
          value={id}
          size={160}
          includeMargin
        />
      </div>

      <div className="approval-pending__footer">
        <Button
          onClick={goBack}
          variant="outlined"
          className="approval-pending__btn"
        >{t('btn.back')}
        </Button>
      </div>
    </div>
  );
}
