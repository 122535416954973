import axios from 'axios';
import isUndefined from 'lodash/isUndefined';
import isAbortedRequestError from '../api/isAbortedRequestError';

export default class ErrorWithCode extends Error {
  code: string;

  inner: unknown;

  /**
   * Initializes a new error with containing a mandatory error code.
   *
   * @param code - A status code that can be used to identify the error.
   * Especially useful when throwing inside createAsyncThunk as the code will be
   * serialized automatically in the action payload.
   *
   * @param message - The error message.
   *
   * @param inner - The error that has been wrapped.
   */
  constructor(code: string, message?: string, inner?: unknown) {
    super(message);
    this.code = code;
    this.inner = inner;
  }

  /**
   * Determines whether the inner error indicates that a request has been
   * aborted.
   */
  get isAbortedRequest() {
    return !isUndefined(this.inner) && isAbortedRequestError(this.inner);
  }

  /**
   * The response HTTP status code from the inner error when available.
   */
  get httpStatusCode(): number | undefined {
    if (!this.inner || !axios.isAxiosError(this.inner)) return undefined;

    return this.inner.response?.status;
  }
}
