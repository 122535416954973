// TODO: replaceWithApi should rather recognize the endpoint property from the
// terminal config
const devApiBaseUrl =
  // this is still needed for legacy modules like ProductDb
  process.env.REACT_APP_API_BASE_URL
  ?? 'https://api.snabble-testing.io';

const whichEnvironment = (location) => {
  if (!location) return 'testing';
  const { host } = location;

  if (
    host === 'api.snabble.io' ||
    host === 'sco.snabble.io' ||
    host === 'sco-preview.snabble.io'
  ) {
    return 'prod';
  }

  if (
    host === 'api.snabble-staging.io' ||
    host === 'sco.snabble-staging.io' ||
    host === 'sco-preview.snabble-staging.io'
  ) {
    return 'staging';
  }

  return 'testing';
};

const replaceWithApi = (location, url) => {
  if (url.slice(0, 4) === 'http' || !url.startsWith('/') || !location.host) {
    return url;
  }

  if (location.host.indexOf('snabble') === -1) {
    return devApiBaseUrl + url;
  }

  const host = location.host.replace(/^sco(-preview)?./, 'api.');
  return `${location.protocol}//${host}${url}`;
};

const addApiHost = replaceWithApi.bind(window, window.location);

export { replaceWithApi, addApiHost, whichEnvironment };
