import RawMetadata from './RawMetadata';
import RoundingMode from './RoundingMode';
import Metadata from './Metadata';

const ROUNDING_MODE = {
  [RoundingMode.Commercial]: 1,
  [RoundingMode.Up]: 3,
};

export default function mapRawToDeviceMetadata(
  shopId: string,
  rawMetadata: RawMetadata,
): Metadata {
  const { project, gatewayCertificates } = rawMetadata;

  const deviceMetadata: Metadata = {
    codeTemplates: project.codeTemplates,
    currency: project.currency,
    locale: project.locale,
    customerCards: project.customerCards,
    decimalDigits: project.decimalDigits,
    priceOverrideCodes: project.priceOverrideCodes,
    rounding: ROUNDING_MODE[project.roundingMode],
    gatewayCertificates,
  };

  return deviceMetadata;
}
