import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-simple-keyboard/build/css/index.css';

// Leave Sentry on top so it is able to intercept all errors
import './sentry';

import './index.scss';
import * as serviceWorker from './serviceWorker';
import store from './store';
import App from './App';
import './i18n';
import ReleaseChannelInterceptor from './release/ReleaseChannelInterceptor';
import logger from './logging';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render((
  <Provider store={store}>
    <ReleaseChannelInterceptor />
    <App />
  </Provider>
));

function unloadCallback() {
  logger.info('Unmounting app', { tag: 'App' });

  // unmount root before reloading the app to avoid memory leaks
  root.unmount();
}

window.addEventListener('beforeunload', unloadCallback);

serviceWorker.register(store);
