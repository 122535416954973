import { AnyAction } from '@reduxjs/toolkit';
// @ts-ignore
import notification from './notification';
// @ts-ignore
import auth from './auth';
// @ts-ignore
import loadingIndicator from './loadingIndicator';
// @ts-ignore
import checkout from './checkout';
// @ts-ignore
import terminal from './terminal';
// @ts-ignore
import receipt from './receipt';
// @ts-ignore
import ShoppingCart from '../models/ShoppingCart';
// @ts-ignore
import assets from './assets';
// @ts-ignore
import fulfillments from './fulfillments';
import DeviceType from '../device-type/DeviceType';
import AssetFile from '../asset/AssetFile';

type Nullable<T> = null | T;

export interface TerminalConfig {
  id: string;
  secret: string;
  type: DeviceType;
  project: string;
  shop: string;
}

export interface Cigarette {
  code: string;
  itemsAvailable: number;
  imageURL?: string;
}

export interface ThemeStyles {
  [key: string]: string;
}

export function buildInitialState() {
  return {
    auth: {
      token: {},
      posToken: '',
      isAuthorized: false,
      paymentToken: '',
    },
    notification: {
      message: null,
      isVisible: false,
      isError: false,
    },
    loadingIndicator: {
      isVisible: false,
      type: '',
    },
    terminal: {
      config: null as Nullable<TerminalConfig>,
      created: false,
      finished: false,
      status: '',
      customerReceipt: '',
      merchantReceipt: '',
      receiptPrintWidth: 42,
      iban: null,
      card: {},
      type: null,
      terminalResult: null,
      cardStatus: '',
    },
    checkout: {
      cartErrors: [],
      id: null,
      initialized: false,
      isLoading: false,
      hasError: false,
      hasInfoError: false,
      info: null,
      checks: null,
      state: null,
      didOriginRequest: false,
      clientID: '',
      isUpdatingCheck: false,
      retryCount: 0,
      paymentMethodVerifiesAge: false,
      fulfillments: [],
      links: {
        self: null,
        receipt: null,
        originCandidate: null,
      },
    },
    receipt: {
      useFallback: false,
      isDownloaded: false,
      text: '',
      retryCount: 0,
      printed: false,
      printedFallback: false,
    },
    assets: {
      files: [] as AssetFile[],
    },
    fulfillments: {
      cigarettes: [] as Cigarette[],
    },
    shoppingCart: new ShoppingCart(),
  };
}

export type LegacyRootState = ReturnType<typeof buildInitialState>;

export default (s: LegacyRootState | undefined, action: AnyAction): LegacyRootState => {
  const state = s ?? buildInitialState();

  return {
    ...state,
    assets: assets(state.assets, action),
    auth: auth(state.auth, action),
    checkout: checkout(state.checkout, action),
    fulfillments: fulfillments(state.fulfillments, action),
    loadingIndicator: loadingIndicator(state.loadingIndicator, action),
    notification: notification(state.notification, action),
    receipt: receipt(state.receipt, action),
    terminal: terminal(state.terminal, action),
  };
};
