import { useMemo } from 'react';
import { useAppSelector } from '../store';
import config from './config';

/**
 * Retrieves the device type config using the type configured in the terminal
 * config. Returns `undefined` when the terminal config has not been loaded yet.
 * Returns the default config when the terminal type is unknown.
 */
export default function useDeviceTypeConfig() {
  const deviceType = useAppSelector(state => state.root.terminal.config?.type);

  return useMemo(() => {
    if (!deviceType) return undefined;

    return config.get(deviceType);
  }, [deviceType]);
}
