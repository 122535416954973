import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import CheckoutProcessState from '../../checkout-process/CheckoutProcessState';
import mergePatchCheckout from '../../checkout-process/api/mergePatchCheckout';
import resetSession from '../reducers/resetSession';
import SessionOrigin from '../SessionOrigin';
import SessionState from '../SessionState';
import SessionStatus from '../SessionStatus';
import requireTerminalConfig from '../../terminal/requireTerminalConfig';
import logger from '../../logging';

const acquireCheckoutProcess = createAsyncThunk(
  'session/acquireCheckoutProcess',
  (checkoutProcessId: string, { getState, signal }) => {
    const state = getState() as RootState;
    const { project } = requireTerminalConfig(state);
    const clientToken = state.token.main;

    logger.info(`Sending merge patch request for "${checkoutProcessId}"`, { tag: 'Checkout' });

    return mergePatchCheckout({
      clientToken,
      id: checkoutProcessId,
      project,
      params: {
        state: CheckoutProcessState.Granted,
      },
      signal,
    });
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      return state.session.status === SessionStatus.Cart;
    },
  },
);

export default acquireCheckoutProcess;

// eslint-disable-next-line function-paren-newline
export function registerAcquireCheckoutProcessReducers(
  builder: ActionReducerMapBuilder<SessionState>) {
  builder
    /* eslint-disable no-param-reassign */
    .addCase(acquireCheckoutProcess.pending, (state) => {
      state.loadingCount += 1;
    })
    .addCase(acquireCheckoutProcess.fulfilled, (state, action) => {
      state.loadingCount -= 1;

      resetSession(state);
      state.checkoutProcess = action.payload;
      state.status = SessionStatus.Checkout;
      state.origin = SessionOrigin.App;
    })
    .addCase(acquireCheckoutProcess.rejected, (state) => {
      state.loadingCount -= 1;
    });
  /* eslint-enable */
}
