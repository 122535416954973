import { useEffect, useMemo, useRef } from 'react';
import { duration } from 'moment';
import throttle from 'lodash/throttle';
import ActivityTrackerOptions from './ActivityTrackerOptions';
import durationToMilliseconds from '../time/durationToMilliseconds';

const DEFAULT_TIMEOUT = duration(30, 'seconds');
const HANDLER_THROTTLE = 500;

const EVENT_TYPES = [
  'click',
  'mousemove',
  'touchstart',
  'touchend',
  'touchcancel',
  'touchmove',
  'keypress',
];

export default function useActivityTracker({
  timeout = DEFAULT_TIMEOUT,
  disabled = false,
  onActive,
  onInactive,
  initiallyInactive = false,
}: ActivityTrackerOptions) {
  // we need this because the event handler is triggered on unmount
  // and restarts the timer after uninstall happened
  // we set it to true on unmount and return in handleActivity
  const stopEventRef = useRef(false);

  const activeRef = useRef(!initiallyInactive);

  const timeoutMs = useMemo(() => durationToMilliseconds(timeout), [timeout]);

  useEffect(() => {
    if (disabled || process.env.REACT_APP_ALWAYS_ACTIVE) return () => {};

    let timer: NodeJS.Timeout | undefined;

    const teardownTimer = () => {
      clearTimeout(timer);
      timer = undefined;
    };

    const handleInactivity = () => {
      if (!activeRef.current) return;

      onInactive?.();
      activeRef.current = false;
    };

    const resetTimer = () => {
      teardownTimer();

      timer = setTimeout(handleInactivity, timeoutMs);
    };

    const handleActivity = () => {
      if (stopEventRef.current) return;

      resetTimer();

      if (activeRef.current) return;

      onActive?.();
      activeRef.current = true;
    };

    const handleActivityThrottled = throttle(handleActivity, HANDLER_THROTTLE);

    const install = () => {
      stopEventRef.current = false;
      resetTimer();

      EVENT_TYPES.forEach((eventType) => {
        window.addEventListener(eventType, handleActivityThrottled, true);
      });
    };

    const uninstall = () => {
      stopEventRef.current = true;
      teardownTimer();

      EVENT_TYPES.forEach((eventType) => {
        window.removeEventListener(eventType, handleActivityThrottled, true);
      });
    };

    install();

    return () => {
      uninstall();
    };
  }, [disabled, onActive, onInactive, timeoutMs]);
}
