import { lazyWithRetry } from '../lazy';
import projectID from '../ProjectID';
import Registry from '../registry/Registry';
import CheckoutConfig from './CheckoutConfig';

const config = new Registry<CheckoutConfig>({ defaultKey: 'default' });

config.set(
  'default',
  {
    receiptCard: lazyWithRetry(() => import('./succeeded/ReceiptCard')),
  },
);

config.set(
  projectID.tchibo,
  {
    receiptCard: lazyWithRetry(() => import('./tchibo-3583dee/ReceiptCardWrapper')),
    additionalCheckoutSuccessHintViewContent: lazyWithRetry(() => import('./tchibo-3583dee/CoffeebarHint')),
  },
);

config.set(
  projectID.tchiboDemo,
  {
    receiptCard: lazyWithRetry(() => import('./tchibo-3583dee/ReceiptCardWrapper')),
    additionalCheckoutSuccessHintViewContent: lazyWithRetry(() => import('./tchibo-3583dee/CoffeebarHint')),
  },
);

export default config.freeze();
