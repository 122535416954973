import { useCallback } from 'react';
import { duration } from 'moment';
import { useAppDispatch, useAppSelector } from '../store';
// @ts-ignore
import { getAssetManifest } from '../actions';
import useRefresher from '../schedule/useRefresher';
import SessionStatus from '../session/SessionStatus';
import { refreshCheckoutDeviceConfig } from '../checkout-device/checkoutDeviceSlice';

export const DEFAULT_REFRESH_INTERVAL = duration(15, 'minutes');
export const ERROR_REFRESH_INTERVAL = duration(3, 'minutes');

export default function ProjectConfigRefresher() {
  const mainToken = useAppSelector(state => state.token.main);

  const hasTerminalConfig = useAppSelector(state =>
    !!state.root.terminal.config);

  const isInCheckout = useAppSelector(state =>
    state.session.status === SessionStatus.Checkout);

  const dispatch = useAppDispatch();

  const handleRefreshDeviceConfig = useCallback(
    async () => {
      await dispatch(refreshCheckoutDeviceConfig()).unwrap();
    },
    [dispatch],
  );

  // getAssetManifest is not an async thunk, so we can't really determine when
  // an error occurs.
  const handleRefreshAssetManifest = useCallback(() => {
    dispatch(getAssetManifest());
  }, [dispatch]);

  const disabled = !mainToken || !hasTerminalConfig || isInCheckout;

  useRefresher({
    onRefresh: handleRefreshDeviceConfig,
    interval: DEFAULT_REFRESH_INTERVAL,
    errorInterval: ERROR_REFRESH_INTERVAL,
    disabled,
  });

  useRefresher({
    onRefresh: handleRefreshAssetManifest,
    interval: DEFAULT_REFRESH_INTERVAL,
    disabled,
  });

  return null;
}
