import inRange from 'lodash/inRange';
import { AxiosError } from 'axios';
import isNetworkError from './isNetworkError';
import isNetworkErrorOrTimeout from './isNetworkErrorOrTimeout';

/**
 * A function that determines when to retry failed requests. Is true when the
 * passed error is a request error, a network error, or a 5xx status response.
 */
export default function isRetryableError(error: AxiosError, withTimeouts = false) {
  const status = error.response?.status;

  const networkError = withTimeouts
    ? isNetworkErrorOrTimeout(error)
    : isNetworkError(error);

  return networkError || (!!status && inRange(status, 500, 600));
}
