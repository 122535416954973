import { useCallback, useEffect, useState } from 'react';
import { duration } from 'moment';
import SessionStatus from '../session/SessionStatus';
import { useAppDispatch, useAppSelector } from '../store';
import restartApp from '../helper/restart';
import logger from '../logging';
import useActivityTracker from '../activity-tracking/useActivityTracker';
import { resetUpdatePending } from './appUpdatesSlice';

export const ACTIVITY_TIMEOUT = duration(1, 'minutes');

export default function AppUpdatesHandler() {
  const isInCheckout = useAppSelector(state =>
    state.session.status === SessionStatus.Checkout);
  const [isBusy, setIsBusy] = useState(false);

  const hasPendingUpdate = useAppSelector(s => s.appUpdates.hasPendingUpdate);
  const dispatch = useAppDispatch();

  const setToIdle = useCallback(() => {
    setIsBusy(false);
  }, []);

  const setToBusy = useCallback(() => {
    setIsBusy(true);
  }, []);

  useActivityTracker({
    disabled: isInCheckout,
    initiallyInactive: true,
    onActive: setToBusy,
    onInactive: setToIdle,
    timeout: ACTIVITY_TIMEOUT,
  });

  useEffect(() => {
    if (isBusy || isInCheckout || !hasPendingUpdate) return;

    logger.info('App marked ready for update, restarting...', { tag: 'Updater' });
    dispatch(resetUpdatePending());
    restartApp();
  }, [dispatch, hasPendingUpdate, isBusy, isInCheckout]);

  return null;
}
