import { ComponentType, lazy, LazyExoticComponent } from 'react';
import ComponentLoader from './ComponentLoader';
import RetryOptions from './RetryOptions';
import withRetry from './withRetry';

export default function lazyWithRetry<T = any>(
  factory: ComponentLoader<T>,
  options?: RetryOptions,
) : LazyExoticComponent<ComponentType<T>> {
  return lazy(withRetry(factory, options));
}
