import { useCallback, useRef } from 'react';
import { duration } from 'moment';
import { useAppDispatch, useAppSelector } from '../store';
import {
  decrementLoadingCount,
  incrementLoadingCount,
  markInitialized,
} from './appDbSlice';
import initAppDb from './initAppDb';
import updateAppDb from './updateAppDb';
import useDeviceTypeConfig from '../device-type/useDeviceTypeConfig';
import useMetadata from '../metadata/useMetadata';
import useProject from '../useProject';
import useShopId from '../useShopId';
import SessionStatus from '../session/SessionStatus';
import { usePoller } from '../polling';
import { useRefresher } from '../schedule';
import logger from '../logging';

export const DEFAULT_REFRESH_INTERVAL = duration(15, 'minutes');
export const ERROR_REFRESH_INTERVAL = duration(2, 'minutes');

export default function AppDbRefresher() {
  const dispatch = useAppDispatch();

  const authToken = useAppSelector(state => state.token.main);
  const deviceConfig = useDeviceTypeConfig();
  const metadata = useMetadata();

  const isInitialized = useAppSelector(state => state.appDb.isInitialized);
  const mayLoadDb = !!deviceConfig?.initAppDb && !!authToken && !!metadata;

  const isInCheckout = useAppSelector(state =>
    state.session.status === SessionStatus.Checkout);

  const project = useProject();
  const shopId = useShopId();

  const loadingRef = useRef(0);

  // Initialize

  const handleInitialize = useCallback(async () => {
    if (
      !project ||
      !shopId ||
      !authToken ||
      !metadata
    ) return;

    if (loadingRef.current > 0) {
      logger.info('App DB is already being downloaded, skipping concurrent initialization', { tag: 'Analytics' });
      return;
    }

    loadingRef.current += 1;
    dispatch(incrementLoadingCount());

    try {
      await initAppDb({
        project,
        shopId,
        authToken,
        metadata,
      });
      dispatch(markInitialized());
    } catch (e) {
      // ignore the error, is retried later
    }

    loadingRef.current -= 1;
    dispatch(decrementLoadingCount());
  }, [authToken, dispatch, metadata, project, shopId]);

  const retryInitializeDisabled =
    isInitialized ||
    isInCheckout ||
    !mayLoadDb ||
    !project ||
    !shopId ||
    !authToken;

  usePoller({
    onTick: handleInitialize,
    disabled: retryInitializeDisabled,
    // will only poll as long as the app DB is not initialized
    interval: ERROR_REFRESH_INTERVAL,
    immediate: true,
  });

  // Poll for updates

  const handleRefresh = useCallback(async () => {
    if (!isInitialized || !mayLoadDb) return;

    dispatch(incrementLoadingCount());

    try {
      await updateAppDb({ authToken });
    } finally {
      dispatch(decrementLoadingCount());
    }
  }, [authToken, dispatch, isInitialized, mayLoadDb]);

  const refreshDisabled = isInCheckout || !isInitialized || !mayLoadDb;

  useRefresher({
    onRefresh: handleRefresh,
    interval: DEFAULT_REFRESH_INTERVAL,
    errorInterval: ERROR_REFRESH_INTERVAL,
    disabled: refreshDisabled,
    immediate: false,
  });

  return null;
}
