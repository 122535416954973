import moment, { Moment } from 'moment';
import { useCallback, useContext, useEffect, useRef } from 'react';
import SchedulerContext from './SchedulerContext';
import TickCallback from './TickCallback';

export interface ScheduledOptions {
  /**
   * A callback defining the task to perform.
   */
  onElapse: () => void;

  /**
   * The time at which the task should be performed.
   */
  at: string | Date | Moment | undefined;

  /**
   * Indicates whether the timer is disabled. Defaults to `false`.
   */
  disabled?: boolean;
}

/**
 * Hook that runs the passed callback once at the scheduled time.
 */
export default function useScheduled({
  onElapse,
  at,
  disabled = false,
}: ScheduledOptions) {
  const { subscribe, unsubscribe } = useContext(SchedulerContext);
  const wasInvokedRef = useRef(false);

  const timestamp = moment(at).valueOf();
  const prevTimestampRef = useRef(timestamp);

  // this callback is called regularly (defaults to every second) by the
  // `SchedulerProvider` that this particular hook instance subscribes to. On
  // every tick we check whether the specified time is reached and invoke the
  // `onElapse` callback if it is. Otherwise we simply do nothing and wait for the
  // next interval. Additionally, we memoize the state of the hook being already
  // invoked to prevent multiple invocations of our callback.
  const handleTick: TickCallback = useCallback(() => {
    if (wasInvokedRef.current) return;

    const now = moment();
    const runAt = moment(at);
    if (runAt.isAfter(now)) return;

    try {
      onElapse();
    } finally {
      // wrapping try/finally prevents infinite loop when `onElapse` throws, but
      // we still bubble up the unhandled error
      wasInvokedRef.current = true;
    }
  }, [at, onElapse]);

  // reset invoked flag as soon as the time in `at` prop changes
  useEffect(() => {
    if (prevTimestampRef.current === timestamp) return;

    wasInvokedRef.current = false;
    prevTimestampRef.current = timestamp;
  }, [timestamp]);

  // subscribe to the `SchedulerProvider` that must be wrapped somewhere as
  // parent component
  useEffect(() => {
    if (disabled) return () => {};

    subscribe(handleTick);

    return () => {
      unsubscribe(handleTick);
    };
  }, [disabled, handleTick, subscribe, unsubscribe]);
}
