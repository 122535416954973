import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import createCheckout, { PaymentInformation } from '../../checkout-process/api/createCheckout';
import ErrorWithCode from '../../error/ErrorWithCode';
import PaymentMethod from '../../payment-method/PaymentMethod';
import requireTerminalConfig from '../../terminal/requireTerminalConfig';
import SessionState from '../SessionState';
import SessionStatus from '../SessionStatus';
import logger from '../../logging';
import encryptOrigin from '../../cart/external-billing/encryptOrigin';

function determinePaymentMethod(state: SessionState) {
  const { availablePaymentMethods } = state.content;

  if (state.externalBilling) {
    if (availablePaymentMethods.includes(PaymentMethod.GatekeeperExternalBilling)) {
      return PaymentMethod.GatekeeperExternalBilling;
    }
    if (availablePaymentMethods.includes(PaymentMethod.ExternalBilling)) {
      return PaymentMethod.ExternalBilling;
    }
    return undefined;
  }

  if (availablePaymentMethods.includes(PaymentMethod.GatekeeperTerminal)) {
    return PaymentMethod.GatekeeperTerminal;
  }
  return undefined;
}

const checkOutCart = createAsyncThunk(
  'session/checkOutCart',
  async (_, { getState, signal }) => {
    const state = getState() as RootState;
    const { project } = requireTerminalConfig(state);
    const certificate = state.metadata.data?.gatewayCertificates?.[0].value;

    const { signedCheckoutInfo } = state.session.content;
    if (!signedCheckoutInfo) {
      throw new ErrorWithCode(
        'incomplete_cart',
        'No signed checkout info found',
      );
    }

    const paymentMethod = determinePaymentMethod(state.session);
    if (!paymentMethod) {
      throw new ErrorWithCode(
        'unknown_payment_method',
        'No payment method available',
      );
    }

    let paymentInformation: PaymentInformation | undefined;
    const { externalBilling } = state.session;
    if (externalBilling) {
      paymentInformation = {
        originType: externalBilling.originType,
      };

      if (externalBilling.originType === 'contactPersonCredentials') {
        const { code: username, password, contactPersonID } = state.session.externalBilling!;
        paymentInformation.encryptedOrigin = encryptOrigin({
          username,
          password,
          contactPersonID,
        }, certificate);
      } else {
        paymentInformation.cardNumber = externalBilling.code;
      }

      if (externalBilling.subject) paymentInformation.subject = externalBilling.subject;
    }

    logger.info(
      `Creating checkout process for session id "${state.session.id}" with payment method "${paymentMethod}"`,
      { tag: 'Checkout' },
    );

    return createCheckout({
      clientToken: state.token.payment,
      params: {
        signedCheckoutInfo,
        paymentMethod,
        paymentInformation,
      },
      project,
      signal,
    });
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      return state.session.status === SessionStatus.Cart;
    },
  },
);

export default checkOutCart;

export function registerCheckOutCartReducers(builder: ActionReducerMapBuilder<SessionState>) {
  /* eslint-disable no-param-reassign */
  builder
    .addCase(checkOutCart.pending, (state) => {
      state.loadingCount += 1;
    })
    .addCase(checkOutCart.fulfilled, (state, action) => {
      state.loadingCount -= 1;
      state.checkoutProcess = action.payload;
      state.status = SessionStatus.Checkout;
    })
    .addCase(checkOutCart.rejected, (state) => {
      state.loadingCount -= 1;
    });
  /* eslint-enable no-param-reassign */
}
