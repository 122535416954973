import { SerializedError } from '@reduxjs/toolkit';
import isPlainObject from 'lodash/isPlainObject';

const FIELDS: Array<keyof SerializedError> = [
  'name',
  'message',
  'stack',
  'code',
];

export default function isSerializedError(value: any): value is SerializedError {
  return isPlainObject(value) && FIELDS.some(field => field in value);
}
