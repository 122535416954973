import { createSlice } from '@reduxjs/toolkit';

export interface AppUpdatesState {
  hasPendingUpdate: boolean;
}

const initialState: AppUpdatesState = {
  hasPendingUpdate: false,
};

/* eslint-disable no-param-reassign */
export const appUpdatesSlice = createSlice({
  name: 'appUpdates',
  initialState,
  reducers: {
    setUpdatePending(state) {
      state.hasPendingUpdate = true;
    },
    resetUpdatePending(state) {
      state.hasPendingUpdate = false;
    },
  },
});
/* eslint-enable */

export default appUpdatesSlice.reducer;

export const { setUpdatePending, resetUpdatePending } = appUpdatesSlice.actions;
