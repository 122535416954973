import Product from './Product';

const MAPPED_PROPS: Array<keyof Product> = [
  'availability',
  // 'basePrice',
  'currency',
  // 'customerCardPrice',
  'decimalDigits',
  // 'depositProduct',
  // 'description',
  'discountedPrice',
  'encodingUnit',
  'imageUrl',
  // 'isDeposit',
  'listPrice',
  'name',
  // 'notForSale',
  'referenceUnit',
  // 'rounding',
  'saleRestriction',
  'saleStop',
  'scanMessage',
  'scannableCodes',
  'sku',
  'specifiedQuantity',
  'subtitle',
  'type',
];

// TODO: Would be better if the ProductDb class returned the right interface in
// the first place rather than an instance of the Product class. By copying the
// object members we ensure that we have no methods, getters or setters left
// that are polluting the Product interface, making it easier for Redux to
// serialize the object.
export default function mapProductToPlainObject(product: any): Product {
  const mappedProduct = {} as any;
  MAPPED_PROPS.forEach((key) => {
    mappedProduct[key] = product[key];
  });
  return mappedProduct as Product;
}
