import * as Sentry from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import set from 'lodash/set';
import { RootState } from './store';

const REDACTED_VALUE = '[Filtered]';

const REDACTED_ACTIONS = [
  'GOT_ASSET_MANIFEST',
  'GOT_FULFILLMENT_PRODUCTS',
  'GOT_METADATA',
  'GOT_TERMINAL_CONFIG',
  'RECEIVED_CHECKOUT_INFO',
  'RECEIVED_PAYMENT_TOKEN',
  'RECEIVED_POS_TOKEN',
  'checkoutDevice/refreshConfig',
  'session/changeCart/fulfilled',
  'session/refreshCoupons/fulfilled',
  'session/setExternalBillingInCart/fulfilled',
  'token/refreshMainToken/fulfilled',
  'token/removePaymentToken',
  'token/requestPaymentToken/fulfilled',
];

// TODO:
const REDACTED_STATE_PATHS = [
  'coupon',
  'checkoutDevice',
  'root.assets',
  'root.auth',
  'root.fulfillments',
  'root.metadata',
  'root.shoppingCart.customerCard',
  'root.shoppingCart.paymentCard',
  'root.terminal.card',
  'root.terminal.config.secret',
  'root.terminal.iban',
  'session.content.customerCard.code',
  'session.content.signedCheckoutInfo.signature',
  'session.externalBilling.code',
  'toast',
  'token.main',
  'token.payment',
];

export default Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (REDACTED_ACTIONS.includes(action.type)) {
      return { ...action, payload: REDACTED_VALUE };
    }
    return action;
  },
  stateTransformer: (state: RootState) => {
    const transformedState = cloneDeep(state);
    REDACTED_STATE_PATHS.forEach((path) => {
      if (has(state, path)) {
        set(transformedState, path, REDACTED_VALUE);
      }
    });
    return transformedState;
  },
  configureScopeWithState: (scope, state: RootState) => {
    scope.setTag('project', state.root.terminal.config?.project);
    scope.setTag('shopId', state.root.terminal.config?.shop);
    scope.setTag('deviceId', state.root.terminal.config?.id);
  },
});
