import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import handleDispatchAbort from '../../store/handleDispatchAbort';
import Awaitable from '../../types/Awaitable';
import changeCart from '../actions/changeCart';
import CartItemChangeSet from '../content/modification/CartItemChangeSet';
import SessionStatus from '../SessionStatus';

export default function createChangeCartItemsThunk<T>(
  typePrefix: string,
  buildChangeset: (state: RootState, arg: T) => Awaitable<CartItemChangeSet>,
) {
  return createAsyncThunk(
    typePrefix,
    async (arg: T, {
      dispatch, getState, signal,
    }) => {
      const state = getState() as RootState;
      const itemChanges = await buildChangeset(state, arg);

      await handleDispatchAbort(dispatch(changeCart({
        itemChanges,
        customerCard: state.session.content.customerCard,
        externalBilling: state.session.externalBilling,
      })), signal);
    },
    {
      condition: (_, { getState }) => {
        const state = getState() as RootState;
        return state.session.status === SessionStatus.Cart;
      },
    },
  );
}
