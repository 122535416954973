import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../typography/Text';
// @ts-ignore
import { ReactComponent as CigarettesImage } from '../../images/sco-image-dispensing.svg';

export default function CheckoutCigaretteDispenseView() {
  const { t } = useTranslation();

  return (
    <div className="default">
      <div className="default__content">
        <CigarettesImage alt={t('loading.cigarettes')} className="default__image" />
        <Text className="default__text">{t('loading.cigarettes')}</Text>
      </div>
    </div>
  );
}

