// This optional code is used to register a service worker.
// register() is not called by default.

import { backOff } from 'exponential-backoff';
import { setUpdatePending } from './app-updates/appUpdatesSlice';
import UPDATE_INTERVAL from './app-updates/updateInterval';
import { isProdEnv } from './env';
import logger from './logging';
import { AppStore } from './store';

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const hasServiceWorkerSupport = () => 'serviceWorker' in navigator;

let checkTimer: NodeJS.Timeout | undefined;

function withRetry<T>(callback: () => Promise<T>) {
  return backOff(callback, { numOfAttempts: 5 });
}

function log(level: 'error' | 'info', msg: string) {
  logger[level](msg);
  // eslint-disable-next-line no-console
  console[level](msg);
}

function checkForUpdate(registration: ServiceWorkerRegistration) {
  clearTimeout(checkTimer);
  checkTimer = setTimeout(async () => {
    // eslint-disable-next-line no-console
    console.log('Checking for app updates...');
    try {
      await withRetry(() => registration.update());
    } catch (e) {
      if ((e as any)?.message?.startsWith('Failed to update a ServiceWorker for scope')) {
        // NOTE: This message is noise according to workbox and does not hinder
        // functionality. It does, however, pollute the slack log.
        // https://github.com/GoogleChrome/workbox/issues/3094
      } else {
        log('error', `Service worker could not be updated: ${e}`);
      }
    }
    checkForUpdate(registration);
  }, UPDATE_INTERVAL.asMilliseconds());
}

export async function register(store: AppStore) {
  if (!isProdEnv || !hasServiceWorkerSupport()) return;

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(process.env.PUBLIC_URL ?? '', window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    log('error', 'Server Worker Registration: PUBLIC_URL env var has ' +
      'different origin than current window');
    return;
  }

  const swUrl = `${process.env.PUBLIC_URL}/service-worker-snabble.js`;

  let registration: ServiceWorkerRegistration;
  try {
    registration = await withRetry(() => navigator.serviceWorker.register(swUrl));
  } catch (e) {
    log('error', `Failed to register service worker: ${e}`);
    return;
  }

  registration.addEventListener('updatefound', () => {
    const installingWorker = registration.installing;
    if (!installingWorker) {
      return;
    }

    installingWorker.addEventListener('statechange', () => {
      if (installingWorker.state !== 'activated') {
        return;
      }

      log('info', 'App update available, marking app as ready for update');
      store.dispatch(setUpdatePending());
    });
  });

  checkForUpdate(registration);
}

export async function unregister() {
  if (!hasServiceWorkerSupport()) return;

  clearTimeout(checkTimer);

  const registration = await navigator.serviceWorker.ready;
  registration.unregister();
}

