export enum CheckoutInfoErrorDetailType {
  SaleStop = 'sale_stop',
  ProductNotFound = 'product_not_found',
  MissingPrice = 'missing_price',
}

export default interface CheckoutInfoErrorDetail {
  message: string;
  type: CheckoutInfoErrorDetailType;
  sku: string;
}
