import mapReferringLineItemToAdditionalItem from './mapReferringLineItemToAdditionalItem';
import isStackableProduct from '../../../../product/isStackableProduct';
import ScannedProductInfo from '../../../../product/ScannedProductInfo';
import ProductLineItem from '../../../../checkout-info/line-item/ProductLineItem';
import ReferringLineItem from '../../../../checkout-info/line-item/ReferringLineItem';
import ProductCartItem from '../../ProductCartItem';
import CartItemType from '../../CartItemType';

export default function mapProductLineItemToCartItem(
  scannedInfo: ScannedProductInfo,
  lineItem: ProductLineItem,
  referringLineItems: ReferringLineItem[],
): ProductCartItem {
  return {
    type: CartItemType.Product,
    id: lineItem.id,
    name: lineItem.name,
    sku: lineItem.sku,
    scannedCode: lineItem.scannedCode,
    amount: lineItem.amount,
    originalAmount: lineItem.amount,
    price: lineItem.price,
    totalPrice: lineItem.totalPrice,
    isStackable: isStackableProduct(scannedInfo),
    saleRestriction: lineItem.saleRestriction,
    additionalItems: referringLineItems.map(mapReferringLineItemToAdditionalItem),
    info: scannedInfo,
  };
}
