import { useCallback } from 'react';
import { duration } from 'moment';
import { useAppDispatch, useAppSelector } from '../store';
import { refreshCoupons } from './couponSlice';
import { useRefresher } from '../schedule';

export const DEFAULT_REFRESH_INTERVAL = duration(15, 'minutes');
export const ERROR_REFRESH_INTERVAL = duration(5, 'minutes');

export default function CouponRefresher() {
  const hasCouponsUrl = useAppSelector(state => !!state.checkoutDevice.config.couponsURL);

  const dispatch = useAppDispatch();

  const handleRefresh = useCallback(
    async () => {
      await dispatch(refreshCoupons()).unwrap();
    },
    [dispatch],
  );

  useRefresher({
    onRefresh: handleRefresh,
    interval: DEFAULT_REFRESH_INTERVAL,
    errorInterval: ERROR_REFRESH_INTERVAL,
    disabled: !hasCouponsUrl,
  });

  return null;
}
