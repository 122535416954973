import Registry from '../registry/Registry';
import DeviceRole from './DeviceRole';
import DeviceType from './DeviceType';
import DeviceTypeConfig from './DeviceTypeConfig';

const deviceTypes = new Registry<DeviceTypeConfig>();

deviceTypes.set(
  DeviceType.SCO,
  {
    fetchMetadata: true,
    hasCart: true,
    hasTerminalLogin: true,
    initAppDb: true,
    roles: [DeviceRole.PointOfSale],
  },
  { default: true },
);

deviceTypes.set(
  DeviceType.Gatekeeper,
  {
    fetchMetadata: false,
    hasCart: false,
    hasTerminalLogin: false,
    initAppDb: false,
    roles: [DeviceRole.Gatekeeper],
  },
);

export default deviceTypes.freeze();
