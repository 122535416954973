import React, { ReactNode, useContext } from 'react';
import SlideAnimation from '../animation/SlideAnimation';
import RouterContext from './RouterContext';

export interface RouteProps {
  children: ReactNode;
  when: any;
}

export default function Route({ children, when }: RouteProps) {
  const { currentState } = useContext(RouterContext);
  const isVisible = when === currentState;

  // Disabled for now
  if (!isVisible) return null;

  return (
    <SlideAnimation>
      {children}
    </SlideAnimation>
  );
}
