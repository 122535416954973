import { useCallback } from 'react';
import { useAppSelector } from '../store';
import useProject from '../useProject';
import client from '../api/client';
import ErrorWithCode from '../error/ErrorWithCode';
import FeedbackRating from './FeedbackRatings';

export default function useFeedbackApi() {
  const project = useProject();
  const clientToken = useAppSelector(state => state.token.main);
  const checkoutDeviceId = useAppSelector(state => state.root.terminal.config?.id);
  const shop = useAppSelector(state => state.root.terminal.config?.shop);

  const sendRating = useCallback(async (value: FeedbackRating) => {
    if (!project || !checkoutDeviceId || !shop || !clientToken) return;

    let timestamp = new Date().toISOString();
    timestamp = `${timestamp.substring(0, timestamp.length - 5)}+00:00`;

    const body = {
      type: 'analytics',
      appId: checkoutDeviceId,
      shopID: shop,
      timestamp,
      payload: {
        key: 'rating',
        value, // NOTE value has to be a string!
        comment: '',
      },
    };

    try {
      await client.post(
        `/${project}/events`,
        body,
        {
          headers: {
            Authorization: `Bearer ${clientToken}`,
          },
        },
      );
    } catch (e) {
      throw new ErrorWithCode(
        'feedback',
        'Feedback could not be send',
        e,
      );
    }
  }, [checkoutDeviceId, clientToken, project, shop]);

  return { sendRating };
}
