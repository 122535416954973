import React, { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { duration, Duration } from 'moment';
import { Player } from '@lottiefiles/react-lottie-player';
// @ts-ignore
import { ReactComponent as SucceededIcon } from '../../images/check.svg';
import { LedState, useLed } from '../../led';
import BackToStartButton from './BackToStartButton';
import useActivityTracker from '../../activity-tracking/useActivityTracker';
import { useAppDispatch } from '../../store';
import useCodeScanner from '../../code/useCodeScanner';
import { resetSession } from '../../session';
import animationRegistry from '../../lottieAnimations/config';
import checkoutRegistry from '../config';
import useProject from '../../useProject';

interface CheckoutSuccessHintViewProps {
  autoTerminate?: boolean;
  autoTerminateAfter?: number | Duration;
  children?: ReactNode;
  containerClass?: string;
  textId: string;
}

const DEFAULT_AUTO_TERMINATE_TIMEOUT = duration(8, 'seconds');

// TODO: Name of this component may be misleading compared to the other view
// components in this folder

export default function CheckoutSuccessHintView({
  children,
  containerClass,
  textId,
  autoTerminate = false,
  autoTerminateAfter = DEFAULT_AUTO_TERMINATE_TIMEOUT,
}: CheckoutSuccessHintViewProps) {
  useLed(LedState.SuccessColor);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const project = useProject();
  const successAnimation = useMemo(() => {
    if (!project) return animationRegistry.get('default').success;
    return animationRegistry.get(project).success;
  }, [project]);

  const AdditionalContent = useMemo(() => {
    if (!project) return null;
    return checkoutRegistry.get(project)?.additionalCheckoutSuccessHintViewContent;
  }, [project]);

  const finishCheckout = useCallback(() => {
    dispatch(resetSession());
  }, [dispatch]);

  useActivityTracker({
    onInactive: finishCheckout,
    disabled: !autoTerminate,
    timeout: autoTerminateAfter,
  });

  useCodeScanner({
    onEntered: finishCheckout,
  });

  return (
    <div className={containerClass}>
      <div className="approval-result__success-hint">
        <SucceededIcon className="approval-result__success-hint__icon approval-result__success-hint__icon--success" />
        <div className="approval-result__success-hint__text">
          {t(`approval-result.${textId}`)}
        </div>
        <div className="approval-result__success-hint__animation">
          <Player
            autoplay
            src={successAnimation}
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      </div>

      <div className="approval-result__content">
        {AdditionalContent && <AdditionalContent />}

        {children}
      </div>

      <BackToStartButton />
    </div>
  );
}
