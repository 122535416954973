import React, { createElement } from 'react';
import './text.scss';

export interface HeadlineProps {
  children: React.ReactNode,
  className?: string,
  fullWidth?: boolean,
  level?: string | number,
}

// idea stolen from: https://codepen.io/jsstrn/pen/mMMmZB
function calcFont(textLength: number) {
  const baseSize = 29;
  const textSize = textLength;
  const minSize = 10;
  if (baseSize < textSize) {
    return baseSize - (baseSize - minSize);
  }
  return baseSize - textSize;
}

export default function Headline({
  children,
  className,
  fullWidth,
  level = 1,
}: HeadlineProps) {
  let fontSize: number = 0;

  // create an element with fluid font-size
  if (fullWidth) {
    fontSize = calcFont(children?.toString().length ?? 0);

    return createElement(
      `h${level}`,
      {
        className: `text text--h${level} ${className || ''}`,
        style: { fontSize: `${fontSize}vw`, lineHeight: 1 },
      },
      children,
    );
  }

  return createElement(
    `h${level}`,
    { className: `text text--h${level} ${className || ''}` },
    children,
  );
}
