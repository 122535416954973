import TerminalFailureCause from './TerminalFailureCause';
import TerminalPaymentStatus from './TerminalPaymentStatus';

const PAYMENT_STATUS_FAILURE_CAUSES: { [key in TerminalPaymentStatus]?: TerminalFailureCause } = {
  [TerminalPaymentStatus.Aborted]:
    TerminalFailureCause.UserAborted,
  [TerminalPaymentStatus.Failed]:
    TerminalFailureCause.TerminalFailed,
  [TerminalPaymentStatus.Error]:
    TerminalFailureCause.TerminalErrored,
  [TerminalPaymentStatus.AgeVerificationFailed]:
    TerminalFailureCause.AgeVerificationFailed,
  [TerminalPaymentStatus.AgeVerificationNotSupportedByCard]:
    TerminalFailureCause.AgeVerificationNotSupportedByCard,
};

export default function mapTerminalPaymentStatusToFailureCause(status: TerminalPaymentStatus) {
  return PAYMENT_STATUS_FAILURE_CAUSES[status];
}
