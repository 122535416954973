import React from 'react';
import { LedState, useLed } from '../../led';
import SessionOrigin from '../../session/SessionOrigin';
import { useAppSelector } from '../../store';
import BackToCartView from '../BackToCartView';

export default function CheckoutRejectedView() {
  useLed(LedState.ErrorColor);

  const isAppCart = useAppSelector(state => state.session.origin === SessionOrigin.App);

  return (
    <BackToCartView
      messageId="error.approval-rejected"
      terminate={isAppCart}
    />
  );
}
