import ReadonlyRegistry from './ReadonlyRegistry';

interface RegistryConstructorOptions {
  defaultKey?: string;
}

interface RegistrySetOptions {
  default?: boolean;
}

export default class Registry<T> {
  private map: Map<string, T> = new Map();

  defaultKey: string | undefined;

  readonly: boolean = false;

  constructor(opts: RegistryConstructorOptions = {}) {
    this.defaultKey = opts.defaultKey;
  }

  get keys() {
    return Array.from(this.map.keys());
  }

  get default() {
    if (!this.defaultKey) return undefined;

    return this.map.get(this.defaultKey);
  }

  get(key: string) {
    if (!key) return this.default;

    return this.map.get(key) ?? this.default;
  }

  forEach(cb: (value: T, key: string) => void) {
    return this.map.forEach(cb);
  }

  toMap(): ReadonlyMap<string, T> {
    return this.map;
  }

  set(key: string, value: T, opts: RegistrySetOptions = {}) {
    if (this.readonly) {
      throw new Error('unable to manipulate frozen registry');
    }

    if (opts.default) {
      this.defaultKey = key;
    }
    this.map.set(key, value);
  }

  freeze(): ReadonlyRegistry<T> {
    this.readonly = true;
    return this;
  }
}
