import { RootState } from '../../store';

/**
 * Determines whether the cart contains any error items.
 *
 * @param state The root state.
 * @returns `true` when the cart contains erroneous items, `false` when it does
 * not.
 */
export default function cartHasErrorItems(state: RootState) {
  return state.session.content.errorItems.length > 0;
}
