const DEFAULT_RESULT = Object.freeze({
  coupon: undefined,
  scannedCode: undefined,
});

function couponCodeMatchesTemplate(code, candidate) {
  return code.template === candidate.template &&
    code.code === candidate.parts.code;
}

function couponCodeMatchingAnyTemplate(coupon, candidates) {
  return (coupon.codes || []).find(code =>
    candidates.find(candidate => couponCodeMatchesTemplate(code, candidate)));
}


export default function findCouponByCode(coupons, templates, code) {
  if (!coupons || !coupons.length) {
    return DEFAULT_RESULT;
  }

  const candidates = templates.findMatchingTemplate(code);

  return coupons
    .map(coupon => ({
      coupon,
      scannedCode: couponCodeMatchingAnyTemplate(coupon, candidates),
    }))
    .find(({ scannedCode }) => scannedCode) ?? DEFAULT_RESULT;
}
