import client from '../../api/client';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import Link from '../../link/Link';

interface GetSignedDigitalReceiptOptions extends AuthenticatedApiOptions {
  url: string;
}

interface DigitalReceiptLinks {
  signed: Link;
}

interface SignedDigitalReceipt {
  links: DigitalReceiptLinks;
}

export default async function getSignedDigitalReceiptUrl({
  url,
  clientToken,
  signal,
}: GetSignedDigitalReceiptOptions) {
  const response = await client.get<SignedDigitalReceipt>(`${url}/sign`, {
    headers: {
      'Client-Token': clientToken,
    },
    signal,
  });
  return response.data.links.signed.href;
}
