import axios, { AxiosError } from 'axios';
import get from 'lodash/get';

export default function isAbortedRequestError(error: unknown) {
  return (
    Boolean(get(error, 'isAbortedRequest', false)) ||
    axios.isCancel(error) ||
    (axios.isAxiosError(error) && error.code === AxiosError.ECONNABORTED)
  );
}
