import Moment from 'moment';

export default (auth, action) => {
  switch (action.type) {
    case 'REQUESTING_PAYMENT_TOKEN': {
      return Object.assign(
        {}, auth,
        {
          isLoadingPaymentToken: true,
          receivedPaymentTokenError: false,
        },
      );
    }
    case 'REQUESTING_POS_TOKEN': {
      return Object.assign(
        {}, auth,
        {
          isLoadingPOSToken: true,
          receivedPOSTokenError: false,
        },
      );
    }
    case 'RECEIVED_POS_TOKEN': {
      const { token, expiresAt } = action.payload;
      const renewAt = Moment.unix(expiresAt).subtract(30, 'minutes');
      return Object.assign(
        {}, auth,
        {
          renewAt,
          posToken: token,
          isAuthorized: !!token,
          failed: !token,
          isLoadingPOSToken: false,
          receivedPOSTokenError: !token,
        },
      );
    }
    case 'RECEIVED_PAYMENT_TOKEN': {
      const { token } = action.payload;
      return Object.assign(
        {}, auth,
        {
          paymentToken: token,
          isLoadingPaymentToken: false,
          receivedPaymentTokenError: !token,
        },
      );
    }
    case 'RECEIVED_POS_TOKEN_ERROR': {
      return Object.assign(
        {}, auth,
        {
          renewAt: null,
          posToken: null,
          isAuthorized: false,
          failed: true,
          isLoadingPOSToken: false,
          receivedPOSTokenError: true,
        },
      );
    }
    case 'RECEIVED_PAYMENT_TOKEN_ERROR': {
      return Object.assign(
        {}, auth,
        {
          isLoadingPaymentToken: false,
          receivedPaymentTokenError: true,
          paymentToken: null,
        },
      );
    }
    case 'REMOVE_PAYMENT_TOKEN': {
      return Object.assign(
        {}, auth,
        {
          paymentToken: null,
          receivedPaymentTokenError: false,
        },
      );
    }
    case 'AUTHORIZATION_FAILED': {
      return Object.assign(
        {}, auth,
        {
          renewAt: null,
          posToken: null,
          isAuthorized: false,
          failed: true,
        },
      );
    }

    default:
      return auth;
  }
};
