import isSerializedError from './isSerializedError';

export const UNKNOWN_ERROR_REASON = 'unknown reason';

export default function extractErrorReason(e: unknown) {
  if (e instanceof Error && e.message) {
    return e.message;
  }
  if (isSerializedError(e)) {
    return e.message || e.code || UNKNOWN_ERROR_REASON;
  }
  return UNKNOWN_ERROR_REASON;
}
