import axios from 'axios';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import client from '../../api/client';
import isAbortedRequestError from '../../api/isAbortedRequestError';
import ErrorWithCode from '../../error/ErrorWithCode';
import logger from '../../logging';
import TerminalResult from '../../terminal/TerminalResult';
import CheckoutProcess from '../CheckoutProcess';

const CONFLICT_STATUS_CODE = 409;
const NOT_FOUND_STATUS_CODE = 404;

export interface PostCheckoutPaymentStatusParams {
  checkoutDeviceID?: string;
  clientID?: string;
  result?: TerminalResult;
  verifiedAge?: number;
  deliveredLate?: boolean;
}

export interface PostCheckoutPaymentStatusOptions extends AuthenticatedApiOptions {
  url: string;
  params?: PostCheckoutPaymentStatusParams;
  axiosOptions?: any;
  isDeliveredLate?: boolean;
}

export enum PostCheckoutPaymentStatusResult {
  Ok = 'ok',
  Conflict = 'conflict',
  NotFound = 'not_found',
}

export default async function postCheckoutPaymentStatus({
  clientToken,
  url,
  params,
  signal,
  axiosOptions,
  isDeliveredLate = false,
}: PostCheckoutPaymentStatusOptions) {
  const body = params ?? {};
  if (isDeliveredLate) body.deliveredLate = true;

  try {
    const { data: checkout } = await client.post<CheckoutProcess>(
      url,
      body,
      {
        headers: {
          'Client-Token': clientToken,
        },
        signal,
        ...(axiosOptions || {}),
      },
    );
    return {
      result: PostCheckoutPaymentStatusResult.Ok,
      checkout,
    };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === CONFLICT_STATUS_CODE) {
      return {
        result: PostCheckoutPaymentStatusResult.Conflict,
        checkout: null,
      };
    }

    if (axios.isAxiosError(e) && e.response?.status === NOT_FOUND_STATUS_CODE) {
      return {
        result: PostCheckoutPaymentStatusResult.NotFound,
        checkout: null,
      };
    }

    if (!isAbortedRequestError(e)) {
      logger.error('Payment status update was aborted', { tag: 'Payment' });
    } else {
      logger.error(`Network error while updating payment status: ${e}`, { tag: 'Http' });
    }

    throw new ErrorWithCode(
      'payment_status_not_updatable',
      'Payment status could not be updated',
      e,
    );
  }
}
