import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusError } from './api/postHealthStatus';

export interface AppStatusState {
  online: boolean;
  errors: StatusError[];
}

const initialState: AppStatusState = {
  online: true,
  errors: [],
};

/* eslint-disable no-param-reassign */
export const appStatusSlice = createSlice({
  name: 'appUpdates',
  initialState,
  reducers: {
    setOnline(state) {
      state.online = true;
      state.errors = [];
    },
    setOffline(state, action: PayloadAction<StatusError[]>) {
      state.online = false;
      state.errors = action.payload;
    },
  },
});
/* eslint-enable */

export default appStatusSlice.reducer;

export const { setOnline, setOffline } = appStatusSlice.actions;
