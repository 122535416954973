const TEGUT_BASIC_PREFIX = '928000162';
const TEGUT_EMPLOYEE_PREFIX = `${TEGUT_BASIC_PREFIX}1`;
const TEGUT_CORPORATE_PREFIX = `${TEGUT_BASIC_PREFIX}0`;

const matchesTegut = (value) => {
  if (!value) return false;
  if (value.length !== 19) return false;

  return value.startsWith(TEGUT_EMPLOYEE_PREFIX) ||
    value.startsWith(TEGUT_CORPORATE_PREFIX);
};

const matchesTegutPaymentCard = (value) => {
  if (!value) return false;
  if (value.length !== 19) return false;
  return (value.startsWith(TEGUT_CORPORATE_PREFIX) || value.startsWith(TEGUT_EMPLOYEE_PREFIX));
};

export { matchesTegut, matchesTegutPaymentCard };
