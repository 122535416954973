import remove from './remove';
import ErrorWithCode from '../../../error/ErrorWithCode';
import { RootState } from '../../../store';
import CartItemChangeSet from '../modification/CartItemChangeSet';
import CartItemChangeType from '../modification/CartItemChangeType';

export interface UpdateCartItemQuantityOptions {
  id: string;
  amount: number;
}

export default function updateQuantity(state: RootState, {
  id,
  amount,
}: UpdateCartItemQuantityOptions): CartItemChangeSet {
  if (amount <= 0) {
    return remove(state, id);
  }

  const item = state.session.content.cartItems.find(i => i.id === id);
  if (!item) return [];

  if (!('isStackable' in item) || !item.isStackable) {
    throw new ErrorWithCode(
      'unchangeable_quantity',
      'Quantity may not be changed',
    );
  }

  if (item.originalAmount === amount) return [];

  return [{ type: CartItemChangeType.Update, id, params: { amount } }];
}
