import ErrorWithCode from '../error/ErrorWithCode';
import logger from '../logging';
import isAbortedRequest from './isAbortedRequestError';

export default function loggedError(code: string, message: string, error?: unknown) {
  if (!isAbortedRequest(error)) {
    logger.error(message);
  }

  return new ErrorWithCode(code, message, error);
}
