import forOwn from 'lodash/forOwn';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import { useMemo } from 'react';

const ELEMENT_SEPARATOR = '__';
const MODIFIER_SEPARATOR = '--';

export type BemModifier = null | false | string;

export type BemModifiers =
  | BemModifier
  | BemModifier[]
  | { [className: string]: any };

interface BemClassOptions {
  element?: string;
  modifiers?: BemModifiers;
  onlyModifiers?: boolean;
}

function bemClass(bl: string, options: BemClassOptions) {
  const classes = [];

  let base: string;
  if (options.element) {
    base = `${bl}${ELEMENT_SEPARATOR}${options.element}`;
  } else {
    base = bl;
  }

  if (!options.onlyModifiers) {
    classes.push(base);
  }

  const applyModifier = (modifier: string) =>
    `${base}${MODIFIER_SEPARATOR}${modifier}`;

  if (Array.isArray(options.modifiers)) {
    classes.push(...options.modifiers.filter(isString).map(applyModifier));
  } else if (isString(options.modifiers)) {
    classes.push(applyModifier(options.modifiers));
  } else if (isPlainObject(options.modifiers)) {
    forOwn(options.modifiers, (enabled, modifier) => {
      if (!enabled) return;
      classes.push(applyModifier(modifier));
    });
  }

  return classes.join(' ');
}

export function block(
  blockName: string,
  modifiers?: BemModifiers,
  onlyModifiers = false,
) {
  return bemClass(blockName, { modifiers, onlyModifiers });
}

export function element(
  blockName: string,
  elementName: string,
  modifiers?: BemModifiers,
  onlyModifiers = false,
) {
  return bemClass(blockName, {
    element: elementName,
    modifiers,
    onlyModifiers,
  });
}

export interface BemContext {
  block: (modifiers?: BemModifiers, onlyModifiers?: boolean) => string;

  element: (
    element: string,
    modifiers?: BemModifiers,
    onlyModifiers?: boolean
  ) => string;
}

function buildBemContext(blockName: string): BemContext {
  return {
    block: (modifiers?: BemModifiers, onlyModifiers = false) =>
      block(blockName, modifiers, onlyModifiers),
    element: (
      elementName: string,
      modifiers?: BemModifiers,
      onlyModifiers = false,
    ) => element(blockName, elementName, modifiers, onlyModifiers),
  };
}

export function useBem(blockName: string): BemContext {
  return useMemo(() => buildBemContext(blockName), [blockName]);
}
