import ApiOptions from '../../api/ApiOptions';
import loggedError from '../../api/loggedError';
import terminalClient from '../../api/terminalClient';
import PrinterStatus from '../PrinterStatus';

interface PrinterStatusResponse {
  status: PrinterStatus
}

export default async function getPrinterStatus({ signal }: ApiOptions = {}) {
  try {
    const response = await terminalClient.get<PrinterStatusResponse>(
      '/printerstatus',
      { signal },
    );
    return response.data.status;
  } catch (e) {
    throw loggedError(
      'printer_status_not_fetchable',
      'Printer status could not be fetched',
      e,
    );
  }
}
