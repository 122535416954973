import { isDevEnv } from '../env';
import apiLog from './api/log';
import LogFields from './LogFields';
import Logger from './Logger';
import LogLevel from './LogLevel';

export { default as LogLevel } from './LogLevel';

/* eslint-disable no-console */
const LOCAL_LOGGERS = {
  [LogLevel.Error]: console.error,
  [LogLevel.Info]: console.info,
  [LogLevel.Warning]: console.warn,
};
/* eslint-enable */

export function log(
  level: LogLevel,
  message: string,
  fields: LogFields = {},
) {
  if (isDevEnv) {
    LOCAL_LOGGERS[level]('[logger]', message);
  }

  return apiLog({ params: { level, message, fields } });
}

const logger = {} as Logger;

[
  LogLevel.Error,
  LogLevel.Info,
  LogLevel.Warning,
].forEach((level) => {
  logger[level] = (message, fields) => log(level, message, fields);
});

export default logger;
