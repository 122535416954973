import React, { useCallback, useState } from 'react';
import { abortCheckOutCart, resetSession } from '../../session';
import { useAppDispatch, useAppSelector } from '../../store';
import useCheckoutProcessPoller from '../../checkout/useCheckoutProcessPoller';
// @ts-ignore
import ErrorView from '../../views/ErrorView';
import CheckoutApprovalPendingView from './CheckoutApprovalPendingView';
import { Route, Router } from '../../routing';
import logger from '../../logging';
import SessionOrigin from '../../session/SessionOrigin';
import useCheckoutProcess from '../useCheckoutProcess';

enum View {
  Error,
  Pending,
}

export default function CheckoutPendingView() {
  const dispatch = useAppDispatch();
  const isAppCart = useAppSelector(state => state.session.origin === SessionOrigin.App);
  const checkoutProcess = useCheckoutProcess();
  const [currentView, setCurrentView] = useState(View.Pending);

  const abortCheckout = useCallback(async () => {
    // if user comes from app we are not allowed to abort checkout, so we just reset the session
    if (isAppCart) {
      dispatch(resetSession());
    } else {
      try {
        await dispatch(abortCheckOutCart()).unwrap();
      } catch (e) {
        logger.info('Unable to manually abort checkout while in pending state', { tag: 'Checkout' });
        dispatch(resetSession());
      }
    }
  }, [dispatch, isAppCart]);

  const handleMaxAttemptsExceededToGetSupervisorApproval = useCallback(
    () => {
      logger.info('Max attempts exceeded to get supervisor approval', { tag: 'Checkout' });
      setCurrentView(View.Error);
    },
    [],
  );

  useCheckoutProcessPoller({
    interval: 3000,
    // should wait about 5 minutes before aborting automatically
    maxAttempts: 100,
    onMaxAttempts: handleMaxAttemptsExceededToGetSupervisorApproval,
    immediate: true,
  });

  if (!checkoutProcess) {
    return null;
  }

  return (
    <Router state={currentView}>
      <Route when={View.Error}>
        <ErrorView goBack={abortCheckout} />
      </Route>
      <Route when={View.Pending}>
        <CheckoutApprovalPendingView goBack={abortCheckout} id={checkoutProcess.id} />
      </Route>
    </Router>
  );
}
