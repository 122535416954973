import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import registry from './language/config';

function resourcesFromRegistry(): Resource {
  const languages = {};
  registry.forEach((definition) => {
    Object.assign(languages, definition.languages);
  });
  return languages;
}

i18next
  .use(initReactI18next)
  .init({
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: resourcesFromRegistry(),
    debug: process.env.NODE_ENV === 'development',
  });

export default i18next;
