import { AxiosError } from 'axios';

const ERR_NETWORK = 'ERR_NETWORK';

/**
 * Determines whether the given error is a network-related error.
 *
 * This function tries to fix the incomplete/buggy behavior from axios-retry as
 * implemented here:
 * https://github.com/softonic/axios-retry/blob/master/es/index.mjs#L9
 *
 * @param error The error
 * @returns `true` when the passed error is a network error, `false` when not
 */
export default function isNetworkError(error: unknown) {
  const errorAsAny = error as any;

  return (
    errorAsAny.code === ERR_NETWORK ||
    (
      !!errorAsAny.code?.startsWith('ECONN') &&
      errorAsAny.code !== AxiosError.ECONNABORTED
    )
  );
}
