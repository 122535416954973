import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { ReactComponent as ErrorIcon } from '../images/sco-image-error.svg';
import Headline from '../typography/Headline';
import Text from '../typography/Text';
// @ts-ignore
import Button from '../button/Button';

interface ErrorViewProps {
  headline?: string;
  messageId: string;
  detail?: string;
  onBack: () => void;
  timeout?: number;
}

export default function ErrorView({
  detail,
  headline,
  messageId,
  onBack,
  timeout,
}: ErrorViewProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!timeout) return () => {};

    const timer = setTimeout(() => {
      onBack();
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [onBack, timeout]);

  return (
    <div className="default" data-testid="errorView">
      <div className="default__content">
        <ErrorIcon className="default__image" />
        <Headline level="1">
          {headline || t('error.headline')}
        </Headline>
        <Text handleLineBreaks>{t(messageId)}</Text>
        {!!detail && <Text>{t('error.code', { detail })}</Text>}
        <Button
          variant="outlined"
          className="error__btn"
          onClick={onBack}
        >
          {t('btn.back')}
        </Button>
      </div>
    </div>
  );
}
