import isNull from 'lodash/isNull';
import Units from 'npm-units';
import { toBig, convertDecimalToInternal } from '../helper/price';
import CartItem from './CartItem';

const units = new Units();
const NON_BREAKING_SPACE = '\u00A0';

export default class ProductCartItem extends CartItem {
  depositItem = null;
  isPriceOverride = false;

  // only public for testing
  priceOverride = null;
  memorizedPrice = null;
  #memorizedTotalPrice = null;

  #totalPriceValue = null;
  #priceValue = null;

  constructor(product, quantity, scannedCode) {
    super(quantity, scannedCode);
    this.updateProduct(product);
  }

  updateProduct(product) {
    this.product = product;
    this.rounding = product.rounding;
    this.referenceUnit = product.referenceUnit;
    this.encodingUnit = this.#getEncodingUnit();
    this.displayName = product.name;
    this.subtitle = product.subtitle;
    this.imageUrl = product.imageUrl;
    this.decimalDigits = product.decimalDigit || 2;
    this.scanMessage = product.scanMessage;
    this.notForSale = product.notForSale;

    this.#setEmbedInformation();

    this.saleRestriction = product.getRestriction();

    // additional items may not have a scanned code
    if (this.scannedCode) {
      this.specifiedQuantity = product.getSpecifiedQuantity(this.scannedCode);
      this.transmissionCode = product.getTransmissionCode(this.scannedCode);
    }

    this.isCountable = product.type === 0 && !this.isPriceOverride;
    this.isRemovable = product.type === 0 || product.type === 1;
    this.#refreshPriceValues();
  }

  updateFromLineItem(lineItem) {
    this.sku = lineItem.sku;
    this.displayName = lineItem.name;
    this.quantity = lineItem.amount;
    this.units = lineItem.units;

    if (
      !lineItem.isCountable &&
      lineItem.scannedCode &&
      (
        !this.scannedCode ||
        lineItem.scannedCode !== this.scannedCode.code
      )
    ) {
      this.scannedCode = { ...this.scannedCode, code: lineItem.scannedCode };
    }

    this.#setSaleRestrictionFromLineItem(lineItem);

    this.memorizedPrice = lineItem.price;
    this.#memorizedTotalPrice = lineItem.totalPrice;
    this.#refreshPriceValues();
  }

  static fromLineItem(lineItem, product) {
    const item = new ProductCartItem(product, 1, null);
    item.updateFromLineItem(lineItem);
    return item;
  }

  addQuantity(quantity) {
    this.setQuantity(this.quantity + quantity);
  }

  setQuantity(quantity) {
    this.quantity = quantity;
    this.#forceRefreshPriceValues();
  }

  get price() {
    return this.#toBigString(this.priceValue);
  }

  get priceValue() {
    if (isNull(this.#priceValue)) {
      this.#refreshPriceValues();
    }
    return this.#priceValue;
  }

  get totalPrice() {
    return this.#toBigString(this.totalPriceValue);
  }

  get totalPriceValue() {
    if (isNull(this.#totalPriceValue)) {
      this.#refreshPriceValues();
    }
    return this.#totalPriceValue;
  }

  get priceText() {
    const { price, totalPrice } = this;

    if (this.units && this.units > 1) {
      return this.#formatPriceText(price, totalPrice, this.units);
    }

    return this.#formatPriceText(price, totalPrice, this.quantity);
  }

  get totalDepositPrice() {
    return this.#toBigString(this.#getTotalDepositPriceValue());
  }

  get depositPriceText() {
    return this.#formatPriceText(this.totalDepositPrice, this.totalDepositPrice);
  }

  serializeForCheckout() {
    let { transmissionCode } = this;
    if (!transmissionCode && this.scannedCode) {
      transmissionCode = this.scannedCode.scannedByUser;
    }
    return {
      id: this.key,
      sku: this.product.sku,
      amount: this.quantity,
      scannedCode: transmissionCode || '',
      weight: this.weight,
      weightUnit: this.weightUnit,
      units: this.units,
      price: this.priceOverride || this.memorizedPrice,
    };
  }

  serializeForEvent() {
    return {
      ...super.serializeForEvent(),
      sku: this.product.sku,
    };
  }

  get hasDeposit() {
    return !!this.product.depositProduct || !!this.depositItem;
  }

  #clearCacheFields() {
    this.depositItem = null;
    this.memorizedPrice = null;
    this.#memorizedTotalPrice = null;
  }

  #forceRefreshPriceValues() {
    this.#clearCacheFields();
    this.#refreshPriceValues();
  }

  #refreshPriceValues() {
    this.#priceValue = this.#getProductPriceValue();
    this.#totalPriceValue = this.#getTotalProductPriceValue();
  }

  #formatPriceText(unitPrice, totalPrice, quantityOrUnits = 1) {
    const totalPriceText = this.product.currencyFormatter(totalPrice);

    if (quantityOrUnits === 1) {
      return totalPriceText;
    }

    const unitPriceText = this.product.currencyFormatter(unitPrice);

    return [quantityOrUnits, '×', unitPriceText, '=', totalPriceText]
      .join(NON_BREAKING_SPACE);
  }

  #setSaleRestrictionFromLineItem(item) {
    switch (item.saleRestriction) {
      case 'min_age_21':
        this.saleRestriction = '21';
        break;
      case 'min_age_18':
        this.saleRestriction = '18';
        break;
      case 'min_age_16':
        this.saleRestriction = '16';
        break;
      case 'min_age_14':
        this.saleRestriction = '14';
        break;
      case 'min_age_12':
        this.saleRestriction = '12';
        break;
      case 'min_age_6':
        this.saleRestriction = '6';
        break;
      case 'fsk':
        this.saleRestriction = 'FSK';
        break;
      default:
        this.saleRestriction = '';
        break;
    }
  }

  #setEmbedInformation() {
    if (!this.scannedCode) {
      this.convertedEmbed = 1;
      return;
    }

    if (this.scannedCode.embed) {
      const { embed } = this.scannedCode;
      this.embed = embed;

      // if there is no encodingUnit and no referenceUnit
      // this seems to be a priceOverride product
      if (!this.encodingUnit && !this.referenceUnit) {
        this.memorizedPrice = embed;
        this.priceOverride = embed;
        this.isPriceOverride = true;
        return;
      }

      this.convertedEmbed = this.#convertToReferenceUnit(embed);

      switch (this.encodingUnit) {
        case 'piece':
          this.units = embed;
          return;
        case 'price':
          this.memorizedPrice = embed;
          return;
        default:
          this.weightUnit = this.encodingUnit;
          this.weight = embed;
      }
    } else if (this.scannedCode.units && this.encodingUnit === 'piece') {
      this.units = this.scannedCode.units;
      this.convertedEmbed = this.units;
    } else if (this.scannedCode.price && this.encodingUnit === 'price') {
      this.memorizedPrice = convertDecimalToInternal(
        this.scannedCode.price,
        this.decimalDigits,
        this.rounding,
      );
      this.convertedEmbed = this.memorizedPrice;
    } else if (this.scannedCode.weight) {
      const weight = units.convert(
        this.scannedCode.weight.valueOf(),
        units.library.Kilogram,
        units.findBySymbol(this.encodingUnit),
      );

      this.weight = weight.toNumber();
      this.weightUnit = this.encodingUnit;
      this.convertedEmbed = this.#convertToReferenceUnit(this.weight);
    } else {
      this.convertedEmbed = 1;
    }
  }

  #convertToReferenceUnit(value) {
    return units.convert(
      value,
      units.findBySymbol(this.encodingUnit),
      units.findBySymbol(this.referenceUnit),
    ).toFixed();
  }

  #getEncodingUnit() {
    if (!this.product) return null;
    if (!this.scannedCode) return this.product.encodingUnit;

    const code = this.product.scannableCodes.find(c => c.lookupCode === this.scannedCode.code);
    if (code && code.encodingUnit) {
      return code.encodingUnit;
    }

    return this.product.encodingUnit;
  }

  #getTotalProductPriceValue() {
    if (!isNull(this.#memorizedTotalPrice)) {
      return this.#memorizedTotalPrice;
    }

    if (this.isPriceOverride) {
      return this.priceOverride;
    }

    return this.product.getPriceForQuantity(
      this.quantity,
      this.convertedEmbed,
      this.encodingUnit,
    );
  }

  #getProductPriceValue() {
    return this.memorizedPrice ??
      this.product.getPrice() ??
      0;
  }

  #getDepositPriceValue() {
    return this.depositItem?.priceValue ??
      this.product.getDepositPrice() ??
      0;
  }

  #getTotalDepositPriceValue() {
    return this.depositItem?.totalPriceValue ??
      this.product.getDepositPriceForQuantity(this.quantity) ??
      0;
  }

  #toBigString(value) {
    return toBig(value, this.decimalDigits, this.rounding);
  }
}
