import React, { CSSProperties } from 'react';
import { createPortal } from 'react-dom';

interface LedColorPreviewProps {
  color: string;
}

export default function LedColorPreview({ color }: LedColorPreviewProps) {
  const style = { '--color': `#${color}` } as CSSProperties;

  return createPortal(
    <div className="led-color-preview" style={style} />,
    document.body,
  );
}
