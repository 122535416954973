enum CheckoutProcessState {
  /**
   * user waits for approval/checks
   */
  Pending = 'pending',

  /**
   * approval given/checks passed, user is ready to pay
   */
  Granted = 'granted',

  /**
   * payment in process
   */
  Processing = 'processing',

  /**
   * user aborts process
   */
  Aborted = 'aborted',

  /**
   * user got no approval
   */
  Rejected = 'rejected',

  /**
   * payment failed
   */
  Failed = 'failed',

  /**
  * payment succeeded
  */
  Succeeded = 'succeeded',
}

export default CheckoutProcessState;
