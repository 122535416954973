import { createSlice } from '@reduxjs/toolkit';

export interface AppDbState {
  isInitialized: boolean;
  loadingCount: number;
}

const initialState: AppDbState = {
  isInitialized: false,
  loadingCount: 0,
};

/* eslint-disable no-param-reassign */

const appDbSlice = createSlice({
  name: 'appDb',
  initialState,
  reducers: {
    markInitialized: (state) => {
      state.isInitialized = true;
    },
    incrementLoadingCount: (state) => {
      state.loadingCount += 1;
    },
    decrementLoadingCount: (state) => {
      if (state.loadingCount === 0) return;
      state.loadingCount -= 1;
    },
  },
});

/* eslint-enable */

export default appDbSlice.reducer;

export const {
  decrementLoadingCount,
  incrementLoadingCount,
  markInitialized,
} = appDbSlice.actions;
