import logger from '../../logging';
import { unshippedCheckoutsDb } from './unshippedCheckoutsDb';

export interface GetUnshippedCheckoutsOptions {
  projectId: string,
}

export default async function getUnshippedCheckouts({
  projectId,
}: GetUnshippedCheckoutsOptions) {
  try {
    return await unshippedCheckoutsDb.processes
      .where({ projectId })
      .toArray();
  } catch (e) {
    logger.error(`Unable to get unshipped checkouts from db, project id: "${projectId}"`, { tag: 'Checkout' }); // TODO move to Sentry
    return [];
  }
}
