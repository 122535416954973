import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import getToken from './api/getToken';
import deviceTypeConfig from '../device-type/config';
import DeviceRole from '../device-type/DeviceRole';

export interface TokenState {
  loadingCount: number;
  payment: string;
  main: string;
}

const initialState: TokenState = {
  loadingCount: 0,
  payment: '',
  main: '',
};

export const tokenIsRefreshing =
  (state: RootState) => state.token.loadingCount > 0;

export const requestPaymentToken = createAsyncThunk(
  'token/requestPaymentToken',
  (_, { getState, signal }) => {
    const state = getState() as RootState;

    const terminalConfig = state.root.terminal!.config;
    const projectId = terminalConfig?.project;
    if (!terminalConfig || !projectId) return null;

    const deviceConfig = deviceTypeConfig.get(terminalConfig.type);
    if (!deviceConfig) return null;

    const { id, secret } = terminalConfig;

    return getToken({
      id,
      projectId,
      roles: [DeviceRole.PaymentSystem, ...deviceConfig.roles],
      secret,
      signal,
    });
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;

      return (
        !!state.root.terminal.config &&
        !tokenIsRefreshing(state)
      );
    },
  },
);

export const refreshMainToken = createAsyncThunk(
  'token/refreshMainToken',
  (_, { getState, signal }) => {
    const state = getState() as RootState;

    const terminalConfig = state.root.terminal?.config;
    const projectId = terminalConfig?.project;
    if (!terminalConfig || !projectId) return null;

    const deviceConfig = deviceTypeConfig.get(terminalConfig.type);
    if (!deviceConfig) return null;

    const { id, secret } = terminalConfig;

    return getToken({
      id,
      projectId,
      roles: deviceConfig.roles,
      secret,
      signal,
    });
  },
  // {
  //   condition: (_, { getState }) => !tokenIsRefreshing(getState() as RootState),
  // },
);

/* eslint-disable no-param-reassign */
export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    removePaymentToken(state) {
      state.payment = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshMainToken.pending, (state) => {
        state.loadingCount += 1;
      })
      .addCase(refreshMainToken.fulfilled, (state, action) => {
        state.loadingCount -= 1;
        state.main = action.payload?.token ?? '';
      })
      .addCase(refreshMainToken.rejected, (state) => {
        state.loadingCount -= 1;

        // if request fails we have to remove current token
        state.main = '';
      })
      .addCase(requestPaymentToken.pending, (state) => {
        state.loadingCount += 1;
      })
      .addCase(requestPaymentToken.fulfilled, (state, action) => {
        state.loadingCount -= 1;
        state.payment = action.payload?.token ?? '';
      })
      .addCase(requestPaymentToken.rejected, (state) => {
        state.loadingCount -= 1;

        // if request fails we have to remove current token
        state.payment = '';
      });
  },
});
/* eslint-enable */

export const { removePaymentToken } = tokenSlice.actions;

export default tokenSlice.reducer;
