import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import abortCheckout from '../../checkout-process/api/abortCheckout';
import logger from '../../logging';
import { RootState } from '../../store';
import SessionState from '../SessionState';
import SessionStatus from '../SessionStatus';

const abortCheckOutCart = createAsyncThunk(
  'session/abortCheckOutCart',
  async (_, { getState, signal }) => {
    const state = getState() as RootState;
    const checkoutProcess = state.session.checkoutProcess!;

    logger.info(`Sending abort request for "${checkoutProcess.id}"`, { tag: 'Checkout' });

    return abortCheckout({
      clientToken: state.token.main,
      clientId: checkoutProcess.clientID,
      checkoutDeviceId: checkoutProcess.checkoutDeviceID,
      signal,
      url: checkoutProcess.links.self.href,
    });
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;

      return (
        state.session.status === SessionStatus.Checkout &&
        !!state.session.checkoutProcess
      );
    },
  },
);

export default abortCheckOutCart;

export function registerAbortCheckOutCartReducers(builder: ActionReducerMapBuilder<SessionState>) {
  builder
    /* eslint-disable no-param-reassign */
    .addCase(abortCheckOutCart.pending, (state) => {
      state.loadingCount += 1;
    })
    .addCase(abortCheckOutCart.fulfilled, (state) => {
      state.loadingCount -= 1;
      state.status = SessionStatus.Cart;
      state.checkoutProcess = undefined;
    })
    .addCase(abortCheckOutCart.rejected, (state) => {
      state.loadingCount -= 1;
    });
  /* eslint-enable */
}
