import React, { useCallback, useEffect, useState } from 'react';
import { PostCheckoutPaymentStatusResult } from '../../checkout-process/api/postCheckoutPaymentStatus';
import { resetSession } from '../../session';
import { Route, Router } from '../../routing';
import { useAppDispatch, useAppSelector } from '../../store';
import calculateSummarizedFulfillmentState from '../../fulfillment/calculateSummarizedFulfillmentState';
import CheckoutPaymentStatus from '../../checkout-process/CheckoutPaymentStatus';
import CheckoutProcess from '../../checkout-process/CheckoutProcess';
import CheckoutProcessState from '../../checkout-process/CheckoutProcessState';
import ErrorView from '../../error/ErrorView';
import extractErrorReason from '../../error/extractErrorReason';
import LoadingView from '../../loading/LoadingView';
import logger from '../../logging';
import PaymentMethod from '../../payment-method/PaymentMethod';
import SummarizedFulfillmentState from '../../fulfillment/SummarizedFulfillmentState';
import updateCheckoutPaymentStatus from '../../session/actions/updateCheckoutPaymentStatus';
import useCheckoutProcess from '../useCheckoutProcess';
import useCheckoutProcessPoller from '../useCheckoutProcessPoller';
import useProject from '../../useProject';
import addUnshippedCheckout from '../unshipped/addUnshippedCheckout';

enum View {
  Error,
  Loading,
}

function hasFulfillmentWithState(
  checkoutProcess: CheckoutProcess,
  state: SummarizedFulfillmentState,
) {
  return (
    !!checkoutProcess.fulfillments &&
    calculateSummarizedFulfillmentState(checkoutProcess.fulfillments) ===
      state
  );
}

export default function CheckoutGrantedView() {
  const dispatch = useAppDispatch();

  const paymentToken = useAppSelector(state => state.token.payment);
  const [isPollingCheckout, setIsPollingCheckout] = useState(false);

  const [errorKey, setErrorKey] = useState('');
  const currentView = errorKey ? View.Error : View.Loading;

  const checkoutProcess = useCheckoutProcess();
  const projectId = useProject();

  const updatePaymentToProcessing = useCallback(async (processId: string) => {
    try {
      const { result } = await dispatch(updateCheckoutPaymentStatus({
        status: CheckoutPaymentStatus.Processing,
      })).unwrap();

      if (result === PostCheckoutPaymentStatusResult.Conflict) {
        logger.info(`Payment status of "${processId}" could not ` +
          'be updated due to an invalid transition from "granted" to ' +
          '"processing" state, refreshing checkout and trying again', { tag: 'Checkout' });

        // Start polling the checkout again until all conflicts disappear
        setIsPollingCheckout(true);
      }
    } catch (e) {
      const reason = extractErrorReason(e);
      logger.warning('Unable to set payment status to processing during ' +
        `granted phase for "${processId}" (${reason})`, { tag: 'Checkout' });
      setErrorKey('payment');
      if (checkoutProcess && projectId) {
        await addUnshippedCheckout({
          checkout: checkoutProcess,
          paymentStatusUpdate: {
            status: CheckoutPaymentStatus.Failed,
            params: {},
          },
          projectId,
        });
      }
    }
  }, [checkoutProcess, dispatch, projectId]);

  useEffect(() => {
    // This check is important to prevent updating the payment status to
    // processing again after it has just been updated and the checkout was
    // already refreshed in the store. Most likely, this component is already
    // unmounted when the checkout state is not granted, but it is kind of a
    // last resort in case this async function is leaking.
    if (
      checkoutProcess &&
      checkoutProcess.state !== CheckoutProcessState.Granted
    ) {
      return;
    }

    setIsPollingCheckout(false);

    if (!paymentToken) {
      // Stop polling when no payment token available. The payment token
      // retrieval is managed by the PaymentTokenManager component.
      return;
    }

    // Start/continue polling the checkout process when it is not loaded, the
    // payment method is not gatekeeper terminal or the fulfillment is still
    // being allocated.
    if (
      !checkoutProcess ||
      checkoutProcess.paymentMethod !== PaymentMethod.GatekeeperTerminal ||
      hasFulfillmentWithState(checkoutProcess, SummarizedFulfillmentState.Allocating)
    ) {
      setIsPollingCheckout(true);
      return;
    }

    // When a fulfillment fails, do not set the payment state to processing but
    // instead display an error.
    if (hasFulfillmentWithState(checkoutProcess, SummarizedFulfillmentState.Failed)) {
      logger.info(`Fulfillment(s) failed for "${checkoutProcess.id}"`, { tag: 'Checkout' });
      setErrorKey('fulfillment');
      return;
    }

    // When the payment method is "gatekeeper terminal" and the checkout process
    // has no allocating or failed fulfillments (anymore), we can update the
    // payment status to processing
    updatePaymentToProcessing(checkoutProcess.id);
  }, [checkoutProcess, dispatch, paymentToken, updatePaymentToProcessing]);

  const handleMaxAttemptsExceeded = useCallback(() => {
    logger.warning('Max attempts exceeded to refresh checkout process during granted phase', { tag: 'Checkout' });
    setErrorKey('text');
  }, []);

  const handleNavigateBack = useCallback(() => {
    logger.info('Session reset by user in granted state after unrecoverable error occurred', { tag: 'App' });

    // As the transaction is in granted state, we can't reopen the cart, as
    // the action does not consider "granted" as final state for a checkout
    dispatch(resetSession());
  }, [dispatch]);

  useCheckoutProcessPoller({
    interval: 3000,
    maxAttempts: 50,
    onMaxAttempts: handleMaxAttemptsExceeded,
    disabled: !isPollingCheckout,
    immediate: true,
  });

  return (
    <Router state={currentView}>
      <Route when={View.Error}>
        <ErrorView
          messageId={`error.${errorKey}`}
          onBack={handleNavigateBack}
        />
      </Route>
      <Route when={View.Loading}>
        <LoadingView />
      </Route>
    </Router>
  );
}
