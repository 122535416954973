import Big from 'big.js';

function formatPrice(price, decimalDigits = 2, currency = 'EUR') {
  // TODO: locale from metadata
  const formattedPrice = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
    currency,
  }).format(price);

  return formattedPrice.startsWith('NaN') ? '' : formattedPrice;
}

// FIXME: Big does not actually convert the price to a Big, but a String!
function toBig(price, decimalDigits, rounding) {
  const v = new Big(price);
  v.e -= decimalDigits;

  return v.round(decimalDigits, rounding).valueOf();
}

function convertDecimalToInternal(decimal, decimalDigits, rounding) {
  const v = new Big(decimal);
  v.e += decimalDigits;

  return v.round(0, rounding).toNumber();
}

export { formatPrice, toBig, convertDecimalToInternal };
