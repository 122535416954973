import ScannedProductInfo from './ScannedProductInfo';
import resolveEncodingUnit from './resolveEncodingUnit';
import EncodingUnit from './EncodingUnit';

export default function extractPriceOverride(productInfo: ScannedProductInfo) {
  let price: number | undefined;
  const encodingUnit = resolveEncodingUnit(productInfo);

  if (encodingUnit && encodingUnit !== EncodingUnit.Price) {
    return undefined;
  }

  if (productInfo.scannedCode.embed) {
    price = productInfo.scannedCode.embed;
  }

  if (!price) {
    return undefined;
  }

  return price;
}
