import { Draft, PayloadAction } from '@reduxjs/toolkit';
import CartItem from '../content/CartItem';
import SessionState from '../SessionState';

interface ManipulateCartItemOptions {
  id: string;
  item: Partial<CartItem>;
}

/**
 * Locally manipulates a cart item without syncing it with the API.
 *
 * @param state - The session state.
 */
export default function manipulateCartItem(
  state: Draft<SessionState>,
  action: PayloadAction<ManipulateCartItemOptions>,
) {
  const item = state.content.cartItems.find(i => i.id === action.payload.id);
  if (!item) return;

  Object.assign(item, action.payload.item);
}
