import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import SessionState from '../SessionState';
import getCheckout from '../../checkout-process/api/getCheckout';
import logger from '../../logging';
import extractErrorReason from '../../error/extractErrorReason';

export interface RefreshCheckoutOptions {
  retries?: number;
}

const refreshCheckout = createAsyncThunk(
  'session/refreshCheckout',
  async ({ retries }: RefreshCheckoutOptions, { getState, signal }) => {
    const state = getState() as RootState;
    const checkoutProcess = state.session.checkoutProcess!;

    logger.info(`Refreshing checkout process "${checkoutProcess.id}"`, { tag: 'Checkout' });

    try {
      return await getCheckout({
        clientToken: state.token.main,
        signal,
        retries,
        url: checkoutProcess.links.self.href,
      });
    } catch (e) {
      const reason = extractErrorReason(e);
      logger.warning(`Unable to refresh checkout "${checkoutProcess.id}" (${reason})`, { tag: 'Checkout' });
      throw e;
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      return !!state.session.checkoutProcess;
    },
  },
);

export default refreshCheckout;

export function registerRefreshCheckoutReducers(builder: ActionReducerMapBuilder<SessionState>) {
  /* eslint-disable no-param-reassign */
  builder.addCase(refreshCheckout.fulfilled, (state, action) => {
    if (!action.payload) return;
    state.checkoutProcess = action.payload;
  });
  /* eslint-enable no-param-reassign */
}
