import ExternalBillingProvider from './ExternalBillingProvider';
import projectID from '../../ProjectID';

const TEGUT_BASIC_PREFIX = '928000162';
const TEGUT_EMPLOYEE_PREFIX = `${TEGUT_BASIC_PREFIX}1`;
const TEGUT_CORPORATE_PREFIX = `${TEGUT_BASIC_PREFIX}0`;

function isTegutExternalBillingCard(value: string) {
  if (value.length !== 19) return false;
  return (value.startsWith(TEGUT_CORPORATE_PREFIX) || value.startsWith(TEGUT_EMPLOYEE_PREFIX));
}

const provider: ExternalBillingProvider = {
  project: projectID.tegut,
  originType: 'tegutEmployeeID',
  validateCode: isTegutExternalBillingCard,
};

export default provider;
