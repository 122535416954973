import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AssetSVG = ({
  src, alt, className,
}) => {
  const [imageSrc, setImageSrc] = useState(src);

  if (imageSrc === '') return <div className={className} />;

  const format = src.split('.');
  if (format.length > 1 && format[1].startsWith('svg')) {
    return (
      <object
        type="image/svg+xml"
        data={src}
        className={className}
        onError={() => setImageSrc(undefined)}
      >
        {alt}
      </object>
    );
  }

  return (<img
    src={src}
    alt={alt}
    className={className}
    onError={() => setImageSrc(undefined)} />);
};

AssetSVG.defaultProps = {
  alt: '',
  className: '',
};

AssetSVG.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default AssetSVG;
