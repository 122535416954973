import { backOff } from 'exponential-backoff';
import ComponentLoader from './ComponentLoader';
import RetryOptions from './RetryOptions';

const DEFAULT_MAX_ATTEMPTS = 5;

export default function withRetry<T = any>(
  factory: ComponentLoader<T>,
  { numOfAttempts = DEFAULT_MAX_ATTEMPTS }: RetryOptions = {},
): ComponentLoader<T> {
  return () => backOff(factory, { numOfAttempts });
}
