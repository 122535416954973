import { createContext } from 'react';
import TickCallback from './TickCallback';

interface SchedulerContextModel {
  subscribe: (callback: TickCallback) => void;
  unsubscribe: (callback: TickCallback) => void;
}

export default createContext<SchedulerContextModel>({
  subscribe: () => {},
  unsubscribe: () => {},
});
