import compact from 'lodash/compact';
import parseInt from 'lodash/parseInt';
import ErrorWithCode from '../error/ErrorWithCode';
import CodeParseResult from './CodeParseResult';
import CodeType from './CodeType';

const UUID_PATTERN = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
const CHECKOUT_ID_PATTERN = /snabble:checkoutProcess:[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
const SINGLE_LINE_BARCODE_PATTERN = /^(\(?\+?[0-9]*\)?)?[0-9_\- \\(\\)]*$/;
const MULTI_LINE_BARCODE_PATTERN = /^(\(?\+?[0-9]*\)?)?[0-9_\- \\(\\)]*$/gm;
const CODE_93_PATTERN = /^[0-9A-Z\-.$/+% ]*$/;
const CSV_ENCODED_QR_CODE_PATTERN = /snabble;/;
const NEWLINE_CHAR = '\n';
const RECONCILIATION = 'snabble:admin:reconciliation';

const isUUID = (value: string) => !!value.match(UUID_PATTERN);

const isCheckoutProcessID = (value: string) =>
  !!value.match(CHECKOUT_ID_PATTERN);

const isSingleLineBarcode = (value: string) =>
  !!value.match(SINGLE_LINE_BARCODE_PATTERN);

const isMultiLineBarcode = (value: string) =>
  !!value.match(MULTI_LINE_BARCODE_PATTERN);

const isCode93 = (value: string) =>
  !!value.match(CODE_93_PATTERN);

const isCSVEncodedQRCode = (value: string) =>
  !!value.match(CSV_ENCODED_QR_CODE_PATTERN);

const isReconciliation = (value: string) =>
  !!value.match(RECONCILIATION);

export function extractCodesFromCSV(value: string) {
  if (!value) return [];

  const codes = compact(value.split('\n'));
  const collectedCodes: string[] = [];
  codes.forEach((c) => {
    const code = c.split(';');
    if (code[0] === 'snabble') return;
    for (let i = 0; i < parseInt(code[0]); i += 1) {
      collectedCodes.push(code[1]);
    }
  });
  return collectedCodes;
}

export default function parseScannerContent(content: string): CodeParseResult {
  if (!content) {
    throw new ErrorWithCode('empty_code', 'Code may not be empty');
  }

  if (isCheckoutProcessID(content)) {
    const code = content.split(':')[2];
    return { type: CodeType.AppToTerminal, codes: [code] };
  }

  if (isCSVEncodedQRCode(content)) {
    return { type: CodeType.Barcodes, codes: extractCodesFromCSV(content) };
  }

  if (isUUID(content)) {
    return { type: CodeType.Gatekeeper, codes: [content] };
  }

  if (isSingleLineBarcode(content)) {
    return { type: CodeType.Barcodes, codes: [content] };
  }

  if (isMultiLineBarcode(content)) {
    return {
      type: CodeType.Barcodes,
      codes: compact(content.split(NEWLINE_CHAR)),
    };
  }

  if (isReconciliation(content)) {
    return {
      type: CodeType.AdminReconciliation,
      codes: [content],
    };
  }

  if (isCode93(content)) {
    return { type: CodeType.Barcodes, codes: [content] };
  }

  throw new ErrorWithCode('unknown_code_type', 'Unknown code type');
}
