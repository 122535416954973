import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import sessionReducer, { SessionState } from '../session';
import couponReducer, { CouponState } from '../coupon/couponSlice';
import appDbSlice, { AppDbState } from '../appdb/appDbSlice';
import legacyReducer, {
  buildInitialState as buildLegacyInitialRootState,
  LegacyRootState,
} from '../reducers';
import metadataReducer, { MetadataState } from '../metadata/metadataSlice';
import toastReducer, { ToastState } from '../toast/toastSlice';
import sentryReduxEnhancer from './sentry-redux';
import errorMiddleware from './error/middleware';
import appUpdatesReducer, { AppUpdatesState } from '../app-updates/appUpdatesSlice';
import tokenReducer, { TokenState } from '../token/tokenSlice';
import checkoutDeviceReducer, { CheckoutDeviceState } from '../checkout-device/checkoutDeviceSlice';
import appStatusReducer, { AppStatusState } from '../health/appStatusSlice';

export type RootState = {
  root: LegacyRootState,
  appDb: AppDbState,
  appStatus: AppStatusState,
  appUpdates: AppUpdatesState,
  coupon: CouponState,
  checkoutDevice: CheckoutDeviceState,
  metadata: MetadataState,
  session: SessionState,
  toast: ToastState,
  token: TokenState,
};

export function buildStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: {
      root: legacyReducer,
      appDb: appDbSlice,
      appStatus: appStatusReducer,
      appUpdates: appUpdatesReducer,
      coupon: couponReducer,
      checkoutDevice: checkoutDeviceReducer,
      metadata: metadataReducer,
      session: sessionReducer,
      toast: toastReducer,
      token: tokenReducer,
    },
    preloadedState: {
      root: buildLegacyInitialRootState(),
      ...preloadedState,
    },
    // FIXME: Enable immutableCheck and serializableCheck again if ShoppingCart
    // migrated to slice
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(errorMiddleware),
    devTools: process.env.NODE_ENV === 'development',
    enhancers: [sentryReduxEnhancer],
  });
}

const store = buildStore();

export type AppStore = ReturnType<typeof buildStore>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> =
  ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
