import axios from 'axios';
import logger from '../logging';
import mapLegacyRootThunk from './mapLegacyRootThunk';
import client from '../api/client';
import isNetworkError from '../api/isNetworkError';

function gotFulfillmentProducts(products) {
  return {
    type: 'GOT_FULFILLMENT_PRODUCTS',
    payload: products,
  };
}

export default function getFulfillmentProducts(url, token) {
  return mapLegacyRootThunk(async (dispatch) => {
    if (!url) return;

    try {
      const result = await client.get(url, {
        headers: { 'Client-Token': token },
      });

      dispatch(gotFulfillmentProducts(result.data.products));
    } catch (e) {
      if (isNetworkError(e)) {
        logger.info('network problems while getting fulfillment products', { tag: 'Http' });
        return;
      }

      if (axios.isAxiosError(e)) {
        const status = e.response?.statusText || 'unknown response status';
        logger.info(`error while getting fulfillment products (${status})`, { tag: 'Http' });
        return;
      }

      logger.warning('error while getting fulfillment products (unknown reason)');
    }
  });
}
