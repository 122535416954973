import { RootState } from '../../store';

/**
 * A selector that returns the total price of the cart.
 *
 * @param state The root state.
 * @returns The total price of all items with all discounts applied.
 */
export default function totalCartPrice(state: RootState) {
  return state.session.content.totalPrice;
}
