import ApiOptions from '../../api/ApiOptions';
import terminalClient from '../../api/terminalClient';
import { isTestEnv } from '../../env';
import LogLevel from '../LogLevel';

const TIMEOUT = 2000;

export interface LogParams {
  message: string;
  level: LogLevel;
  fields?: Record<string, any>;
}

interface LogApiOptions extends ApiOptions {
  params: LogParams;
  dispatchInTestEnv?: boolean
}

export default async function log({ params, signal, dispatchInTestEnv = false }: LogApiOptions) {
  if (isTestEnv && !dispatchInTestEnv) return Promise.resolve(true);

  try {
    await terminalClient.post('/log', params, {
      'axios-retry': {
        retries: 0,
      },
      signal,
      timeout: TIMEOUT,
    });
    return true;
  } catch (e) {
    return false;
  }
}
