import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import Button from '../../button/Button';
import { resetSession } from '../../session';
import { useAppDispatch } from '../../store';

export default function BackToStartButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleBackToStart = () => {
    dispatch(resetSession());
  };

  return (
    <Button
      variant="unelevated"
      className="approval-result__back-button"
      onClick={handleBackToStart}
    >
      {t('btn.finished')}
    </Button>
  );
}
