import CartItem from '../CartItem';
import CheckoutInfoViolationType from '../../../checkout-info/CheckoutInfoViolationType';
import LineItem from '../../../checkout-info/line-item/LineItem';
import LineItemType from '../../../checkout-info/line-item/LineItemType';
import mapCouponLineItemToCartItem from './coupon/mapCouponLineItemToCartItem';
import mapProductLineItemToCartItem from './product/mapProductLineItemToCartItem';
import ReferringLineItem, { isReferringLineItem } from '../../../checkout-info/line-item/ReferringLineItem';
import ScannedItemInfo, { isScannedCouponInfo, isScannedProductInfo } from '../../../scan-info/ScannedItemInfo';

function groupReferringLineItems(lineItems: LineItem[]) {
  const items = new Map<string, ReferringLineItem[]>();

  lineItems.forEach((lineItem) => {
    if (!isReferringLineItem(lineItem)) return;

    const refItems = items.get(lineItem.refersTo) ?? [];
    refItems.push(lineItem);
    items.set(lineItem.refersTo, refItems);
  });

  return items;
}

export default function mapLineItemsToCartItems(
  lineItems: LineItem[],
  itemInfos: Map<string, ScannedItemInfo>,
  violations: Map<string, CheckoutInfoViolationType[]>,
) {
  const items: CartItem[] = [];
  const referringLineItems = groupReferringLineItems(lineItems);

  lineItems.forEach((lineItem) => {
    if (isReferringLineItem(lineItem)) return;

    const itemInfo = itemInfos.get(lineItem.id);
    if (!itemInfo) return;

    if (lineItem.type === LineItemType.Coupon) {
      if (!isScannedCouponInfo(itemInfo)) return;

      const referringViolations = violations.get(lineItem.id);

      items.push(mapCouponLineItemToCartItem(itemInfo, lineItem, referringViolations));
      return;
    }

    if (!isScannedProductInfo(itemInfo)) return;

    const referringItems = referringLineItems.get(lineItem.id) ?? [];
    items.push(mapProductLineItemToCartItem(
      itemInfo,
      lineItem,
      referringItems,
    ));
  });

  return items;
}
