import ApiOptions from '../../api/ApiOptions';
import loggedError from '../../api/loggedError';
import terminalClient from '../../api/terminalClient';

export interface PrintContentOptions extends ApiOptions {
  content: string;
}

export default async function printContent({
  content,
  signal,
}: PrintContentOptions) {
  try {
    await terminalClient.post('/print', { text: content }, { signal });
  } catch (e) {
    throw loggedError(
      'content_not_printable',
      'Content could not be printed',
      e,
    );
  }
}
