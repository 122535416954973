import React, { useEffect } from 'react';
import restart from '../helper/restart';
import logger from '../logging';
import useHealthStatus from '../health/api/useHealthStatus';
import OfflineView from './OfflineView';

const RELOAD_TIMEOUT_MS = 30000;

export default function SentryErrorView() {
  const { postOffline } = useHealthStatus();

  const handleRestart = () => {
    logger.error('Reloading after Sentry error.');
    restart();
  };

  useEffect(() => {
    logger.error(`Sentry error detected. Reloading in ${RELOAD_TIMEOUT_MS / 1000} seconds.`);

    postOffline({ reason: 'Sentry error' });

    const timeout = setTimeout(() => {
      handleRestart();
    }, RELOAD_TIMEOUT_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, [postOffline]);

  return <OfflineView />;
}
