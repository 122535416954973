import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// @ts-ignore
import { whichEnvironment } from './helper/urls';

const DSN = 'https://9f25687225f942af8d9ae3037a03f80e@o391311.ingest.sentry.io/6309081';

function isHostingEnvironment(location: Location) {
  if (!location) return false;

  return location.host.startsWith('sco');
}

function initSentry() {
  if (!isHostingEnvironment(document.location)) return;

  Sentry.init({
    dsn: DSN,
    environment: whichEnvironment(document.location),
    debug: process.env.NODE_ENV === 'development',

    integrations: [new BrowserTracing()],
    normalizeDepth: 7,

    // As we often have only one device per shop the automatic session tracking is
    // not able to differentiate between users. We have to deal with that
    // manually.
    autoSessionTracking: false,

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance
    // monitoring. We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
  });
}

initSentry();
