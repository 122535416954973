import ErrorWithCode from '../error/ErrorWithCode';
import CheckoutInfoErrorData from './CheckoutInfoErrorData';

export default class CheckoutInfoError extends ErrorWithCode {
  data: CheckoutInfoErrorData;

  constructor(data: CheckoutInfoErrorData) {
    super('invalid_checkout_info', 'Error while creating checkout info');
    this.data = data;
  }
}
