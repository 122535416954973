import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import CheckoutProcessState from '../CheckoutProcessState';
import patchCheckout from './patchCheckout';

export interface AbortCheckoutOptions extends AuthenticatedApiOptions {
  url: string;
  clientId?: string;
  checkoutDeviceId?: string;
}

export default function abortCheckout({
  checkoutDeviceId,
  clientId,
  clientToken,
  signal,
  url,
}: AbortCheckoutOptions) {
  return patchCheckout({
    clientToken,
    params: {
      state: CheckoutProcessState.Aborted,
      checkoutDeviceID: checkoutDeviceId,
      clientID: clientId,
    },
    url,
    signal,
  });
}
