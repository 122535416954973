import { useEffect, useMemo } from 'react';
import useDeviceTypeConfig from '../device-type/useDeviceTypeConfig';
import { isEmptyCart as isEmptyCartSelector } from '../session';
import SessionStatus from '../session/SessionStatus';
import { useAbortableAppDispatch, useAppDispatch, useAppSelector } from '../store';
import { removePaymentToken, requestPaymentToken } from './tokenSlice';

enum Action {
  None,
  RequestToken,
  RemoveToken,
}

/**
 * A component that ensures that the payment token is requested or cleared
 * dependent on the current session status.
 */
export default function PaymentTokenManager() {
  const abortableDispatch = useAbortableAppDispatch();
  const dispatch = useAppDispatch();

  const status = useAppSelector(state => state.session.status);
  const isEmptyCart = useAppSelector(isEmptyCartSelector);
  const deviceType = useDeviceTypeConfig();

  const action = useMemo(() => {
    if (!deviceType?.hasCart) {
      return Action.None;
    }

    if (status === SessionStatus.Checkout || !isEmptyCart) {
      return Action.RequestToken;
    }

    return Action.RemoveToken;
  }, [deviceType, isEmptyCart, status]);

  useEffect(() => {
    if (action === Action.None) return () => {};

    if (action === Action.RequestToken) {
      const abortController = new AbortController();

      abortableDispatch(requestPaymentToken(), abortController.signal);

      return () => {
        abortController.abort();
      };
    }

    dispatch(removePaymentToken());
    return () => {};
  }, [abortableDispatch, dispatch, action]);

  return null;
}
