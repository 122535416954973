import ErrorItem from './content/ErrorItem';
import CustomerCard from '../customer-card/CustomerCard';
import SignedCheckoutInfo from '../checkout-info/SignedCheckoutInfo';
import CartItem from './content/CartItem';
import PaymentMethod from '../payment-method/PaymentMethod';

export default interface SessionContent {
  /**
   * A list of items added to the cart.
   */
  cartItems: CartItem[];

  /**
   * A list of error items that the user has to dismiss before being able to
   * submit the cart.
   */
  errorItems: ErrorItem[];

  /**
   * The total price of the cart.
   */
  totalPrice: number;

  /**
   * Details about a customer card. (optional)
   */
  customerCard: CustomerCard | undefined;

  /**
   * The signed checkout info contains all data from the checkout info but also
   * provides an additional signature that is used validate the authenticity
   * checkout info. When the user is ready to pay, the signed checkout info is
   * sent to the backend to create a new checkout process.
   *
   * The checkout info contains a copy of all information that is necessary to
   * create a checkout process. It is updated every time the user adds things to
   * the cart, updates items or removes items.
   *
   * May be `undefined` when no items have been added to the cart in a currently
   * active session. For newly initiated sessions with an empty cart, this is
   * always `undefined`.
   */
  signedCheckoutInfo: SignedCheckoutInfo | undefined;

  /**
   * The payment methods that are available for the given cart content.
   */
  availablePaymentMethods: PaymentMethod[];

  acceptedPaymentOrigins: Record<string, string[]>;
}

export const initialSessionContent: SessionContent = {
  availablePaymentMethods: [],
  acceptedPaymentOrigins: {},
  cartItems: [],
  customerCard: undefined,
  errorItems: [],
  signedCheckoutInfo: undefined,
  totalPrice: 0,
};
