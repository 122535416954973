import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ExternalBillingType from '../external-billing/ExternalBillingType';
import { RootState } from '../store';
import TerminalType from '../terminal/TerminalType';
import AnimationType from '../theme/AnimationType';
import fetchCheckoutDeviceCategories from './api/fetchCheckoutDeviceCategories';
import fetchCheckoutDeviceConfig from './api/fetchCheckoutDeviceConfig';
import CheckoutDeviceConfig from './CheckoutDeviceConfig';
import CheckoutDeviceContent from './CheckoutDeviceContent';

const DEFAULT_CART_DELETION_TIMEOUT = 60000;

export interface CheckoutDeviceState {
  config: CheckoutDeviceConfig;
  content: CheckoutDeviceContent;
  initialized: boolean;
}

const initialState: CheckoutDeviceState = {
  config: {
    animationType: AnimationType.Default,
    cartDeletionTimeout: 60000,
    customerCardType: ExternalBillingType.None,
    featureFlags: [],
    landingPageType: undefined,
    ledColors: {
      error: '',
      primary: '',
      success: '',
    },
    terminalType: TerminalType.Feig,
    uiReleaseChannel: undefined,
    loadingCount: 0,
  },
  content: {
    categories: [],
    singleItems: [],
    loadingCount: 0,
  },
  initialized: false,
};

export const checkoutDeviceConfigIsRefreshing =
  (state: RootState) => state.checkoutDevice.config.loadingCount > 0;

export const checkoutDeviceContentIsRefreshing =
  (state: RootState) => state.checkoutDevice.content.loadingCount > 0;

export const refreshCheckoutDeviceCategories = createAsyncThunk(
  'checkoutDevice/refreshCategories',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { project, shop: shopId } = state.root.terminal.config!;
    const url = `/${project}/checkout-devices/categories/shop/${shopId}/merged`;
    const clientToken = state.token.main;

    return fetchCheckoutDeviceCategories({ clientToken, url });
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const { config } = state.root.terminal;

      return (
        !!config?.project &&
        !!config?.shop &&
        !checkoutDeviceContentIsRefreshing(state)
      );
    },
  },
);

export const refreshCheckoutDeviceConfig = createAsyncThunk(
  'checkoutDevice/refreshConfig',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { project, id } = state.root.terminal.config!;
    const url = `/${project}/checkout-devices/id/${id}/merged-configs`;
    const clientToken = state.token.main;

    return fetchCheckoutDeviceConfig({ clientToken, url });
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const { config } = state.root.terminal;

      return (
        !!config?.project &&
        !!config?.id &&
        !checkoutDeviceConfigIsRefreshing(state)
      );
    },
  },
);

/* eslint-disable no-param-reassign */
export const checkoutDeviceSlice = createSlice({
  name: 'checkoutDevice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(refreshCheckoutDeviceConfig.pending, (state) => {
        state.config.loadingCount += 1;
      })
      .addCase(refreshCheckoutDeviceConfig.fulfilled, (state, action) => {
        const { payload } = action;

        state.config = {
          animationType: payload.animationType || AnimationType.Default,
          cartDeletionTimeout: payload.cartDeletionTimeout || DEFAULT_CART_DELETION_TIMEOUT,
          cigaretteMachineURL: payload.cigaretteMachineURL,
          couponsURL: payload.couponsURL,
          customerCardType: payload.customerCardType,
          featureFlags: payload.featureFlags ?? [],
          feedbackURL: payload.feedbackURL,
          landingPageType: payload.landingpageType,
          ledColors: {
            error: payload.ledColorError ?? '',
            primary: payload.ledColorPrimary ?? '',
            success: payload.ledColorSuccess ?? '',
          },
          loadingCount: state.config.loadingCount - 1,
          terminalType: payload.terminalType,
          uiReleaseChannel: payload.uiReleaseChannel,
        };

        state.initialized = true;
      })
      .addCase(refreshCheckoutDeviceConfig.rejected, (state) => {
        state.config.loadingCount -= 1;
      })
      .addCase(refreshCheckoutDeviceCategories.pending, (state) => {
        state.content.loadingCount += 1;
      })
      .addCase(refreshCheckoutDeviceCategories.fulfilled, (state, action) => {
        state.content.categories = action.payload?.categories ?? [];
        state.content.singleItems = action.payload?.singleItems ?? [];
        state.content.loadingCount -= 1;
      })
      .addCase(refreshCheckoutDeviceCategories.rejected, (state) => {
        state.content.loadingCount -= 1;
      });
  },
});
/* eslint-enable */

export default checkoutDeviceSlice.reducer;
