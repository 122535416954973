import CheckoutInfoViolationType from '../../../../checkout-info/CheckoutInfoViolationType';
import CouponLineItem from '../../../../checkout-info/line-item/CouponLineItem';
import ScannedCouponInfo from '../../../../coupon/ScannedCouponInfo';
import CartItemType from '../../CartItemType';
import CouponCartItem from '../../CouponCartItem';

export default function mapCouponLineItemToCartItem(
  scannedInfo: ScannedCouponInfo,
  lineItem: CouponLineItem,
  violations: CheckoutInfoViolationType[] | undefined,
): CouponCartItem {
  return {
    type: CartItemType.Coupon,
    id: lineItem.id,
    couponID: lineItem.couponID,
    name: lineItem.name,
    scannedCode: lineItem.scannedCode,
    info: scannedInfo,
    redeemed: lineItem.redeemed,
    violations,
  };
}
