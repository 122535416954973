import extractPriceOverride from '../../../../product/extractPriceOverride';
import extractWeight from '../../../../product/extractWeight';
import extractUnits from '../../../../product/extractUnits';
import selectScannedCode from '../../../../product/selectScannedCode';
import CheckoutInfoProductItemParams from '../../../../checkout-info/params/CheckoutInfoProductItemParams';
import ProductCartItem from '../../ProductCartItem';

export default function mapProductCartItemToParams(item: ProductCartItem):
CheckoutInfoProductItemParams | undefined {
  if (item.amount < 1) return undefined;

  const price = extractPriceOverride(item.info);
  const weight = extractWeight(item.info);
  const units = extractUnits(item.info);
  const scannedCode = selectScannedCode(item.info).code;

  return {
    amount: item.amount,
    id: item.id,
    scannedCode,
    sku: item.sku,
    price,
    weight,
    units,
  };
}
