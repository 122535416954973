const defaultValues = {
  useFallback: false,
  isDownloaded: false,
  text: '',
  retryCount: 0,
  printed: false,
  printedFallback: false,
  hasDigitalReceiptError: false,
  url: null,
};

export default (receipt, action) => {
  switch (action.type) {
    case 'GOT_RECEIPT': {
      return Object.assign(
        {}, receipt,
        {
          useFallback: false,
          isDownloaded: true,
          text: action.payload,
          retryCount: 0,
        },
      );
    }

    case 'GOT_DIGITAL_RECEIPT_URL': {
      return Object.assign(
        {}, receipt,
        {
          url: action.payload,
          hasDigitalReceiptError: action.payload === null,
        },
      );
    }

    case 'RESET_RECEIPT': {
      return Object.assign(
        {}, receipt,
        defaultValues,
      );
    }

    case 'INCREASE_RECEIPT_RETRY_COUNT': {
      return Object.assign(
        {}, receipt,
        {
          retryCount: 1 + receipt.retryCount,
        },
      );
    }

    case 'USE_FALLBACK_RECEIPT': {
      return Object.assign(
        {}, receipt,
        {
          useFallback: true,
          retryCount: 0,
        },
      );
    }

    case 'PRINTED_CHECKOUT_RECEIPT': {
      return Object.assign(
        {}, receipt,
        {
          printed: true,
        },
      );
    }

    case 'PRINTED_CUSTOMER_RECEIPT': {
      return Object.assign(
        {}, receipt,
        {
          printedFallback: true,
        },
      );
    }

    default:
      return receipt;
  }
};
