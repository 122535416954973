import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ToastTheme from '../toast/ToastTheme';
import useToast from '../toast/useToast';
import isErrorWithCode from './isErrorWithCode';

export default function UnhandledErrorInterceptor() {
  const { showToast } = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const handleError = (error: unknown) => {
      if (isErrorWithCode(error)) {
        const content = t(`error.${error.code}`, {
          defaultValue: t('error.text'),
        });

        showToast({
          content,
          theme: ToastTheme.Error,
        });
        return;
      }

      if (
        error instanceof TypeError &&
        error.message.includes('ServiceWorker')
      ) {
        // kind of a hack to not display an irritating toast when the service
        // worker update failed
        return;
      }

      if (error instanceof Error) {
        showToast({
          content: t('error.text'),
          theme: ToastTheme.Error,
        });
      }
    };

    const handleUnhandledError = (event: ErrorEvent) => {
      // the event will be fired twice in dev mode, but that's a known bug
      // https://stackoverflow.com/questions/50201345/javascript-react-window-onerror-fired-twice
      if (event.error.alreadyHandled) {
        return;
      }

      // eslint-disable-next-line no-param-reassign
      event.error.alreadyHandled = true;
      handleError(event.error);
    };

    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      event.promise.catch(handleError);
    };

    window.addEventListener('error', handleUnhandledError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleUnhandledError);

      window.removeEventListener(
        'unhandledrejection',
        handleUnhandledRejection,
      );
    };
  }, [showToast, t]);

  return null;
}
