import { addApiHost } from '../helper/urls';
import logger from '../logging';
import mapLegacyRootThunk from './mapLegacyRootThunk';

function gotAssetManifest(files) {
  return {
    type: 'GOT_ASSET_MANIFEST',
    payload: files,
  };
}

function getAssetManifest() {
  return mapLegacyRootThunk((dispatch, getState) => {
    const { project } = getState().terminal.config;
    const url = addApiHost(`/${project}/assets/manifest`);

    fetch(
      `${url}.json`,
      {
        method: 'GET',
      },
    )
      .then(
        (response) => {
          response.json().then((json) => {
            dispatch(gotAssetManifest(json.files));
          });
        },
        () => {
          logger.info('error while getting asset manifest', { tag: 'Assets' });
        },
      );
  });
}
export default getAssetManifest;
