import isUndefined from 'lodash/isUndefined';
import CheckoutInfoItemParams from '../../../checkout-info/params/CheckoutInfoItemParams';
import CartItem from '../CartItem';
import mapCartItemToParams from './mapCartItemToParams';

export default function mapCartItemsToParams(cartItems: CartItem[]) {
  return cartItems
    .map(mapCartItemToParams)
    .filter(i => !isUndefined(i)) as CheckoutInfoItemParams[];
}
