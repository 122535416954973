import { useCallback } from 'react';
import useCheckoutProcess from './useCheckoutProcess';
import useRequiredMetadata from '../metadata/useRequiredMetadata';
import buildFallbackReceipt from '../receipt/fallback/buildFallbackReceipt';
import { useAppSelector } from '../store';
import TerminalPaymentStatus from '../terminal/TerminalPaymentStatus';
import ErrorWithCode from '../error/ErrorWithCode';

/**
 * A hook that provides a callback to build a fallback receipt that can be sent
 * to the terminal print endpoint in case the real receipt could not be
 * retrieved. The required information is accumulated from the metadata config,
 * the cart's checkout process and stored customer receipt. Thus, the callback
 * will only work in places where all of these information are available.
 */
export default function useGetFallbackReceiptContent() {
  const metadata = useRequiredMetadata();
  const checkoutProcess = useCheckoutProcess();
  const storedCustomerReceipt = useAppSelector(state => state.session.customerReceipt);

  return useCallback((customerReceipt?: string) => {
    if (!metadata.currency || !metadata.decimalDigits) {
      throw new ErrorWithCode('incomplete_metadata', 'Incomplete metadata');
    }

    if (!checkoutProcess) {
      throw new ErrorWithCode(
        'missing_checkout',
        'No checkout found to build fallback receipt for',
      );
    }

    return buildFallbackReceipt({
      customerReceipt: customerReceipt ?? storedCustomerReceipt,
      checkoutId: checkoutProcess.id,
      checkoutState: checkoutProcess.state,
      paymentMethod: checkoutProcess.paymentMethod,
      paymentStatus: TerminalPaymentStatus.Successful,
      priceOptions: {
        value: checkoutProcess.price,
        currency: metadata.currency,
        decimalDigits: metadata.decimalDigits,
      },
    });
  }, [checkoutProcess, storedCustomerReceipt, metadata]);
}
