import { createAsyncThunk } from '@reduxjs/toolkit';
import findCustomerCard from '../../customer-card/findCustomerCard';
import requireMetadata from '../../metadata/requireMetadata';
import { RootState } from '../../store';
import handleDispatchAbort from '../../store/handleDispatchAbort';
import add, { AddCartItemOptions } from '../content/changesets/add';
import CartItemChangeSet from '../content/modification/CartItemChangeSet';
import SessionStatus from '../SessionStatus';
import changeCart from './changeCart';

export default createAsyncThunk(
  'session/addCartItems',
  async (list: AddCartItemOptions[], {
    dispatch, getState, signal,
  }) => {
    const state = getState() as RootState;
    const { customerCards } = requireMetadata(state);

    const itemChanges: CartItemChangeSet = [];
    let { customerCard } = state.session.content;
    const { externalBilling } = state.session;

    list.forEach((options) => {
      const addedCustomerCard = findCustomerCard(
        customerCards?.accepted ?? [],
        options.scannedCode,
      );

      if (addedCustomerCard) {
        // the last customer card always wins, but that's probably okay for now
        // as it should be very uncommon to have multiple customer cards being
        // scanned at once
        customerCard = addedCustomerCard;
      } else {
        itemChanges.push(...add(state, options));
      }
    });

    await handleDispatchAbort(
      dispatch(changeCart({ itemChanges, customerCard, externalBilling })),
      signal,
    );

    return undefined;
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      return state.session.status === SessionStatus.Cart;
    },
  },
);
