import { findExternalBillingByProject } from './providers';
import ExternalBilling from './ExternalBilling';
import ErrorWithCode from '../error/ErrorWithCode';

export default function findExternalBilling(
  project: string,
  code: string,
): ExternalBilling | undefined {
  const provider = findExternalBillingByProject(project);

  if (!provider || !provider.validateCode(code)) {
    throw new ErrorWithCode(
      'invalid_external_billing',
      'Invalid external billing',
    );
  }

  return {
    originType: provider.originType,
    code,
  };
}
