import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import values from 'lodash/values';
import languageRegistry, { LanguageDefinition } from '../language/config';
import LanguageSwitchButton from './LanguageSwitchButton';
import useProject from '../useProject';
import { ReadonlyRegistry } from '../registry';

interface LanguageSwitchProps {
  registry?: ReadonlyRegistry<LanguageDefinition>;
}

export default function LanguageSwitch({
  registry = languageRegistry,
}: LanguageSwitchProps) {
  const project = useProject();

  const settings = useMemo(() => {
    if (!project) return undefined;
    return registry.get(project);
  }, [project, registry]);

  const [isChangingLanguage, setIsChangingLanguage] = useState(false);

  const { i18n } = useTranslation();

  const handleSelectLanguage = useCallback(async (key: string) => {
    setIsChangingLanguage(true);
    try {
      await i18n.changeLanguage(key);
    } finally {
      setIsChangingLanguage(false);
    }
  }, [i18n]);

  const languages = useMemo(
    () => {
      if (!settings) return [];
      return values(settings.languages).filter(lang => !!lang.icon);
    },
    [settings],
  );

  if (!settings?.languageSwitchable || languages.length === 0) return null;

  return (
    <Suspense fallback={<div />}>
      <ul className="language-switch" data-testid="languageSwitch">
        {languages.map(language => (
          <li key={language.key} className="language-switch__item">
            <LanguageSwitchButton
              onSelect={() => { handleSelectLanguage(language.key); }}
              disabled={isChangingLanguage}
            >
              {language.icon}
            </LanguageSwitchButton>
          </li>
        ))}
      </ul>
    </Suspense>
  );
}
