import React, { ReactNode, useEffect, useState } from 'react';
import { usePresence } from 'framer-motion';
import './CssAnimation.css';

export interface Animation {
  durationSeconds?: number;
  backgroundColor?: string;
  opacity?: number;
  scale?: number;
  translateX?: string;
  translateY?: string;
  rotate?: string;
}

export interface CssAnimationProps {
  inAnimation?: Animation;
  outAnimation?: Animation;
  reduceMotion?: Boolean;
  children: ReactNode;
}

const defaultAnimation:Animation = {
  durationSeconds: 0.2,
  opacity: 0,
  scale: 1.1,
};

export default function CssAnimation({
  inAnimation = defaultAnimation,
  outAnimation = defaultAnimation,
  reduceMotion = false,
  children,
}: CssAnimationProps) {
  const userPrefersReducedMotion =
    window?.matchMedia && window?.matchMedia('(prefers-reduced-motion: reduce)')?.matches === true;
  const reducedMotion = userPrefersReducedMotion || reduceMotion;

  const backgroundVariables = {
    '--in-animation-background': `${(inAnimation.backgroundColor) ?? '#ffffff'}`,
    '--out-animation-background': `${(outAnimation.backgroundColor) ?? '#ffffff'}`,
  } as React.CSSProperties;
  const animationVariables = {
    '--in-animation-duration': `${(inAnimation.durationSeconds) ?? 0}s`,
    '--in-animation-opacity': `${(inAnimation.opacity) ?? 1}`,
    '--in-animation-scale': `${(inAnimation.scale) ?? 1}`,
    '--in-animation-translateX': `${(inAnimation.translateX) ?? 0}`,
    '--in-animation-translateY': `${(inAnimation.translateY) ?? 0}`,
    '--in-animation-rotate': `${(inAnimation.rotate) ?? 0}`,

    '--out-animation-duration': `${(outAnimation.durationSeconds) ?? 0}s`,
    '--out-animation-opacity': `${(outAnimation.opacity) ?? 1}`,
    '--out-animation-scale': `${(outAnimation.scale) ?? 1}`,
    '--out-animation-translateX': `${(outAnimation.translateX) ?? 0}`,
    '--out-animation-translateY': `${(outAnimation.translateY) ?? 0}`,
    '--out-animation-rotate': `${(outAnimation.rotate) ?? 0}`,
  } as React.CSSProperties;

  const [isPresent, safeToRemove] = usePresence();
  const [isUnmounted, setIsUnmounted] = useState(false);
  useEffect(() => {
    if (!isPresent) {
      if (reducedMotion) {
        safeToRemove();
      }

      setIsUnmounted(true);
      setTimeout(safeToRemove, (outAnimation.durationSeconds || 0) * 1000);
    }
  }, [isPresent, outAnimation.durationSeconds, reducedMotion, safeToRemove]);

  return (
    <div
      style={backgroundVariables}
      className={`
        css-animation__backdrop
        ${isUnmounted ? 'css-animation_unmounted' : ''}
      `}
    >
      <div
        style={animationVariables}
        className={`
          ${reducedMotion ? '' : 'css-animation__wrapper'}
          ${isUnmounted ? 'css-animation_unmounted' : ''}
        `}
      >
        {children}
      </div>
    </div>
  );
}
