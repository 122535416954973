import { RootState } from '../../store';
import { TypedCartItem } from '../content/CartItem';
import CartItemType from '../content/CartItemType';
import cartItems from './cartItems';

/**
 * A function that builds a selector that returns all items of a specific type.
 * See `ItemType` enum for possible arguments.
 *
 * @param type The item type.
 * @returns A root state selector.
 */
export default function cartItemsOfType<T extends CartItemType>(type: T) {
  return (state: RootState) =>
    cartItems(state).filter(item => item.type === type) as TypedCartItem<T>[];
}
