import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { ReactComponent as InProgressImage } from '../images/sco-image-waiting.svg';
import Text from '../typography/Text';

export default function LoadingView() {
  const { t } = useTranslation();

  return (
    <div className="default">
      <div className="default__content">
        <InProgressImage alt={t('loading.headline')} className="default__image" />
        <Text className="default__text">{t('loading.headline')}</Text>
      </div>
    </div>
  );
}
