import React from 'react';
import SessionStatus from './session/SessionStatus';
import { useAppSelector } from './store';
import { Route, Router } from './routing';
import CheckoutLayoutView from './checkout/CheckoutLayoutView';
import WizardView from './wizard/WizardView';

export default function LayoutView() {
  const status = useAppSelector(state => state.session.status);

  return (
    <Router state={status}>
      <Route when={SessionStatus.Cart}>
        <WizardView />
      </Route>
      <Route when={SessionStatus.Checkout}>
        <CheckoutLayoutView />
      </Route>
    </Router>
  );
}
