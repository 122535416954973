import { v4 as uuidv4 } from 'uuid';
import times from 'lodash/times';
import { RootState } from '../../../store';
import CartItemChangeSet from '../modification/CartItemChangeSet';
import CartItemChangeType from '../modification/CartItemChangeType';
import extractPriceOverride from '../../../product/extractPriceOverride';
import extractUnits from '../../../product/extractUnits';
import extractWeight from '../../../product/extractWeight';
import isStackableProduct from '../../../product/isStackableProduct';
import ScannedProductInfo from '../../../product/ScannedProductInfo';
import selectScannedCode from '../../../product/selectScannedCode';
import { isProductCartItem } from '../CartItem';

export interface AddProductCartItemOptions {
  productInfo: ScannedProductInfo;
  amountToAdd?: number;
  index?: number;
}

export default function addProduct(state: RootState, {
  productInfo,
  amountToAdd = 1,
  index,
}: AddProductCartItemOptions): CartItemChangeSet {
  const productToAddIsStackable = isStackableProduct(productInfo);

  const stackableItem = state.session.content.cartItems
    .filter(isProductCartItem)
    .find(i => i.isStackable && i.sku === productInfo.product.sku);

  const price = extractPriceOverride(productInfo);
  const weight = extractWeight(productInfo);
  const units = extractUnits(productInfo);
  const selectedCode = selectScannedCode(productInfo);
  const weightUnit = productInfo.product.encodingUnit;

  if (stackableItem && productToAddIsStackable) {
    // Add the specified amount to an existing stackable item
    return [{
      type: CartItemChangeType.Update,
      id: stackableItem.id,
      params: {
        amount: stackableItem.amount + (selectedCode.amount * amountToAdd),
        price,
        weight,
        weightUnit,
        units,
        scannedCode: selectedCode.code,
      },
    }];
  }

  if (productToAddIsStackable) {
    // Add a single item with amount if the item is stackable
    return [{
      type: CartItemChangeType.Add,
      params: {
        id: uuidv4(),
        sku: productInfo.product.sku,
        amount: selectedCode.amount * amountToAdd,
        price,
        weight,
        weightUnit,
        units,
        scannedCode: selectedCode.code,
      },
      info: productInfo,
      index,
    }];
  }

  // Add number of items specified by amount with an individual amount of 1 if
  // the item is not stackable
  const count = state.session.content.cartItems.length;

  return times(amountToAdd).map((i) => {
    const k = (index ?? count) + i;
    return ({
      type: CartItemChangeType.Add,
      params: {
        id: uuidv4(),
        sku: productInfo.product.sku,
        amount: selectedCode.amount,
        price,
        weight,
        weightUnit,
        units,
        scannedCode: selectedCode.code,
      },
      info: productInfo,
      index: k,
    });
  });
}

