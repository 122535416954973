import { useCallback } from 'react';
import { duration } from 'moment';
import usePoller from '../polling/usePoller';
import { useAppDispatch, useAppSelector } from '../store';
// @ts-ignore
import { getTerminalConfig } from '../actions';

const DEFAULT_REFRESH_INTERVAL = duration(1, 'hour');
const ERROR_REFRESH_INTERVAL = duration(10, 'seconds');

export default function TerminalConfigRefresher() {
  const dispatch = useAppDispatch();
  const hasTerminalConfig = useAppSelector(state => !!state.root.terminal.config);

  const refreshInterval = hasTerminalConfig
    ? DEFAULT_REFRESH_INTERVAL
    : ERROR_REFRESH_INTERVAL;

  const handleTerminalConfigRefresh = useCallback(
    () => {
      dispatch(getTerminalConfig());
    },
    [dispatch],
  );

  // It makes no sense to use `useRefresher` here for the moment, as
  // getTerminalConfig is not an async thunk and thus will never throw. So
  // it is currently impossible to set a shorter refresh interval when an error
  // occurred.
  usePoller({
    onTick: handleTerminalConfigRefresh,
    interval: refreshInterval,
    immediate: !hasTerminalConfig,
  });

  return null;
}
