import axios from 'axios';
import client from '../../api/client';
import CheckoutInfoParams from '../params/CheckoutInfoParams';
import SignedCheckoutInfo from '../SignedCheckoutInfo';
import CheckoutInfoError from '../CheckoutInfoError';
import isCheckoutInfoErrorData from '../isCheckoutInfoErrorData';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import loggedError from '../../api/loggedError';

export interface CreateCheckoutInfoOptions extends AuthenticatedApiOptions {
  project: string;
  params: CheckoutInfoParams;
}

export default async function createCheckoutInfo({
  clientToken,
  params,
  project,
  signal,
}: CreateCheckoutInfoOptions) {
  try {
    const response = await client.post<SignedCheckoutInfo>(
      `/${project}/sco/info`,
      params,
      {
        headers: {
          'Client-Token': clientToken,
        },
        signal,
      },
    );
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      const error = (e.response.data as any)?.error;
      if (isCheckoutInfoErrorData(error)) {
        throw new CheckoutInfoError(error);
      }
    }

    throw loggedError(
      'checkout_info_not_creatable',
      'Checkout info could not be created',
      e,
    );
  }
}
