import { useCallback } from 'react';
import usePoller from '../polling/usePoller';
import { useAbortableAppDispatch } from '../store';
import { refreshCheckout } from '../session';

interface CheckoutProcessPollerOptions {
  disabled?: boolean;
  interval?: number;
  maxAttempts?: number;
  onMaxAttempts?: () => void;
  immediate?: boolean;
}

/**
 * A hook that refreshes the current checkout process using the given interval.
 * The checkout process in the session store is then replaced with the new one.
 */
export default function useCheckoutProcessPoller({
  disabled = false,
  interval = 3000,
  maxAttempts,
  onMaxAttempts,
  immediate = false,
}: CheckoutProcessPollerOptions = {}) {
  const dispatch = useAbortableAppDispatch();

  const handleRefresh = useCallback(async (signal: AbortSignal) => {
    // we don't need to retry fetching the checkout on errors as the poller is
    // responsible for that in this context
    await dispatch(refreshCheckout({ retries: 0 }), signal);
  }, [dispatch]);

  usePoller({
    onTick: handleRefresh,
    disabled,
    interval,
    maxAttempts,
    onMaxAttempts,
    immediate,
  });
}
