import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { acquireCheckoutProcess } from '../session';
import CodeParseResult from './CodeParseResult';
import CodeType from './CodeType';
import parseScannerContent from './parseScannerContent';
import useScanner, { UseScannerOptions } from '../scanner/useScanner';
import useToast from '../toast/useToast';
import isErrorWithCode from '../error/isErrorWithCode';
import ToastTheme from '../toast/ToastTheme';
import { useAppDispatch } from '../store';
import useCartMutator from '../cart/useCartMutator';
import ErrorWithCode from '../error/ErrorWithCode';
import useDeviceTypeConfig from '../device-type/useDeviceTypeConfig';
import initiateReconciliation from '../terminal/api/initiateReconciliation';
import logger from '../logging';

export interface UseCodeScannerOptions extends UseScannerOptions {
  /**
   * Callback that is invoked after the code has been parsed. Gets the parse
   * result as first argument.
   */
  onParsed?: (result: CodeParseResult) => void;
}

/**
 * This hook uses `useScanner` under the hood but adds code parsing
 * functionality and adds the scanned items to the cart when applicable.
 *
 * **Warning:** Multiple instances of `useCodeScanner` in a view may lead to
 * cart items being added multiple times.
 */
export default function useCodeScanner({
  disabled,
  onEntered,
  onEnter,
  onTimeout,
}: UseCodeScannerOptions = {}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { hideAllToasts, showToast } = useToast();
  const { addCartItems } = useCartMutator();
  const deviceType = useDeviceTypeConfig();

  const handleCodesScanned = useCallback(
    async (content: string) => {
      hideAllToasts();
      onEntered?.(content);

      let result: CodeParseResult;
      try {
        result = parseScannerContent(content);
      } catch (e) {
        if (!isErrorWithCode(e)) {
          throw e;
        }

        if (!deviceType?.hasCart) {
          throw new ErrorWithCode('invalid_code_gatekeeper', 'Invalid code gatekeeper');
        }

        logger.info(`Unable to parse scanner content: ${content}`); // TODO move to Sentry

        showToast({
          content: t(`error.${e.code}`, { defaultValue: e.message }),
          theme: ToastTheme.Error,
        });

        return;
      }

      switch (result.type) {
        case CodeType.Barcodes:
          if (deviceType?.hasCart) {
            const options = result.codes.map(c => ({ scannedCode: c }));
            await addCartItems(options);
            break;
          }

          throw new ErrorWithCode('invalid_code_gatekeeper', 'Invalid code gatekeeper');

        case CodeType.AppToTerminal:
        case CodeType.Gatekeeper:
          await dispatch(acquireCheckoutProcess(result.codes[0]));
          break;

        /*
         * The admin reconciliation code type is necessary for ccv terminals
         * with opi protocol which support postfinance cards.
         * It triggers a terminal request which leads to some sort of closing.
         * This may block the terminal for a while but otherwise the terminal
         * will not be able to proceed postfinance cards. This seems to be a known
         * issue as the developers said this is a requirement if we want to use this
         * sort of terminals.
        */
        case CodeType.AdminReconciliation:
          await initiateReconciliation();
          break;

        default:
          break;
      }
    },
    [addCartItems, deviceType, hideAllToasts, onEntered, dispatch, showToast, t],
  );

  useScanner({
    disabled,
    onEnter,
    onEntered: handleCodesScanned,
    onTimeout,
  });
}
