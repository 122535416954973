import ApiOptions from '../../api/ApiOptions';
import loggedError from '../../api/loggedError';
import mergeRetryConfigs from '../../api/mergeRetryConfigs';
import terminalClient from '../../api/terminalClient';
import TerminalPayment from '../TerminalPayment';

export interface GetPaymentOptions extends ApiOptions {
  checkoutId: string;
  retries?: number;
}

export default async function getPayment({
  checkoutId,
  signal,
  retries,
}: GetPaymentOptions = { checkoutId: '' }) {
  try {
    const response = await terminalClient.get<TerminalPayment>(`/status?checkoutId=${checkoutId}`, {
      'axios-retry': mergeRetryConfigs({ retries }),
      signal,
    });
    return response.data;
  } catch (e) {
    throw loggedError(
      'payment_not_fetchable',
      'Payment could not be fetched',
      e,
    );
  }
}
