import axios from 'axios';
import AuthenticatedApiOptions from '../../api/AuthenticatedApiOptions';
import client from '../../api/client';
import ErrorWithCode from '../../error/ErrorWithCode';

export interface PostIbanParams {
  origin: string;
  clientID: string;
  id: string;
}

export interface PostIbanOptions extends AuthenticatedApiOptions {
  url: string;
  params: PostIbanParams;
}

export default async function postIban({
  clientToken,
  signal,
  url,
  params,
}: PostIbanOptions) {
  try {
    await client.post(
      url,
      params,
      {
        headers: {
          'Client-Token': clientToken,
        },
        signal,
      },
    );
    return true;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return false;
    }

    throw new ErrorWithCode(
      'iban_not_storable',
      'IBAN could not be stored',
      e,
    );
  }
}
