import trimStart from 'lodash/trimStart';
import ApiOptions from '../../api/ApiOptions';
import loggedError from '../../api/loggedError';
import terminalClient from '../../api/terminalClient';
import logger from '../../logging';

const HASH = '#';
const HEX_CODE_PATTERN = /^(?=[0-9a-f]*$)(?:.{3}|.{6})$/i;

export interface SetLedColorOptions extends ApiOptions {
  color: string;
}

export default async function setLedColor({ color, signal }: SetLedColorOptions) {
  const sanitizedColor = trimStart(color, HASH);

  if (!HEX_CODE_PATTERN.test(sanitizedColor)) {
    throw loggedError('invalid_color_code', 'Invalid color code');
  }

  try {
    await terminalClient.post(
      `/led?color=${sanitizedColor}`,
      undefined,
      {
        'axios-retry': {
          retries: 0,
        },
        signal,
      },
    );
    return true;
  } catch (e) {
    logger.info(`Error while changing LED color to ${color}`, { tag: 'LED' }); // TODO move to Sentry
    return false;
  }
}
