import React from 'react';
import {
  AnyAction,
  Dispatch,
  isRejected,
  isRejectedWithValue,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import first from 'lodash/first';
import Translate from '../../components/Translate';
import isCheckoutInfoErrorData from '../../checkout-info/isCheckoutInfoErrorData';
import isSerializedError from '../../error/isSerializedError';
import { addToast } from '../../toast/toastSlice';
import ToastTheme from '../../toast/ToastTheme';
import logger from '../../logging';

function handleSerializedError(
  error: SerializedError,
  dispatch: Dispatch<AnyAction>,
) {
  const defaultValue = <Translate id="error.text" />;

  const content = error.code
    ? (
      <Translate
        id={`error.${error.code}`}
        data={{ defaultValue }}
      />
    )
    : defaultValue;

  dispatch(addToast({ content, theme: ToastTheme.Error }));
}

function buildErrorString(error: Record<string, any>) {
  const details = first(error.details) as Record<string, any>;
  return `${error.type ?? ''} / ${details?.type ?? ''}: ${details?.message ?? ''} (SKU: ${details?.sku ?? ''})`;
}

function handleRejectedWithValue(
  action: PayloadAction<unknown>,
  dispatch: Dispatch<AnyAction>,
) {
  const { payload: error } = action;

  if (isCheckoutInfoErrorData(error)) {
    const errorKey = first(error.details)?.type ?? error.type;
    if (!errorKey) return;
    logger.warning(buildErrorString(error), { tag: 'Checkout' });

    dispatch(addToast({
      content: (
        <Translate
          id={`error.${errorKey}`}
          data={{ defaultValue: <Translate id="error.server" /> }}
        />
      ),
      theme: ToastTheme.Error,
    }));
    return;
  }

  if (isSerializedError(error)) {
    handleSerializedError(error, dispatch);
  }
}

function isAbortedAction(action: AnyAction) {
  return action?.meta?.aborted ?? false;
}

export default function handleError(
  action: AnyAction,
  dispatch: Dispatch<AnyAction>,
) {
  if (isAbortedAction(action)) {
    return;
  }

  if (isRejectedWithValue(action)) {
    handleRejectedWithValue(action, dispatch);
    return;
  }

  if (isRejected(action)) {
    handleSerializedError(action.error, dispatch);
  }
}
