import axios, { AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import { useEffect } from 'react';

interface UseResponseInterceptorOptions {
  client: AxiosInstance | AxiosStatic;
  onFulfilled?: (value: AxiosResponse) => AxiosResponse,
  onRejected?: (error: any) => any,
}

/**
 * `useResponseInterceptor` registers a response interceptor on the Axios client
 * that is specified in the given options. When no client is specified, the
 * default client is used. The interceptor is added when a component using this
 * hook is mounted and ejected as soon as the component unmounts.
 */
export default function useResponseInterceptor({
  client = axios,
  onFulfilled,
  onRejected,
}: UseResponseInterceptorOptions) {
  useEffect(() => {
    const interceptorId = client.interceptors.response.use(
      onFulfilled,
      onRejected,
    );

    return () => {
      client.interceptors.response.eject(interceptorId);
    };
  }, [client, onFulfilled, onRejected]);
}
