import { lazyWithRetry } from '../lazy';
import Registry from '../registry/Registry';
import LandingPageType from './LandingPageType';
import WizardConfig from './WizardConfig';

const config = new Registry<WizardConfig>();

config.set(
  LandingPageType.Default,
  {
    startView: lazyWithRetry(() => import('../start/default/StartView')),
    cartView: lazyWithRetry(() => import('../cart/default/CartView')),
    emptyCartPlaceholder: lazyWithRetry(() => import('../cart/default/Placeholder')),
  },
  {
    default: true,
  },
);

config.set(
  LandingPageType.CoffeeHouse,
  {
    startView: lazyWithRetry(() => import('../start/coffee-house/StartView')),
    cartView: lazyWithRetry(() => import('../cart/coffee-house/CartView')),
  },
);

config.set(
  LandingPageType.Cigarettes,
  {
    startView: lazyWithRetry(() => import('../start/cigarette-picking/StartView')),
    cartView: lazyWithRetry(() => import('../cart/cigarette-picking/CartView')),
  },
);

config.set(
  LandingPageType.Gatekeeper,
  {
    startView: lazyWithRetry(() => import('../start/gatekeeper/StartView')),
  },
);

export default config.freeze();
