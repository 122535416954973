import { v4 as uuidv4 } from 'uuid';

const ABSTRACT_METHODS = [
  'serializeForCheckout',
  'updateFromLineItem',
];

export default class CartItem {
  constructor(quantity, scannedCode) {
    ABSTRACT_METHODS.forEach((method) => {
      if (!this[method]) {
        throw new Error(`${method} has to be implemented in class extending CartItem`);
      }
    });

    this.quantity = quantity;
    this.errorDetails = null;
    this.hasError = false;
    this.key = uuidv4();
    this.scannedCode = scannedCode;
    this.isCountable = true;
    this.isRemovable = true;
  }

  addError(details) {
    this.errorDetails = details;
    this.hasError = true;
  }

  serializeForEvent() {
    return {
      amount: this.quantity,
      scannedCode: this.scannedCode.code,
    };
  }
}
