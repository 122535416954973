import ProductType from './ProductType';
import ScannedProductInfo from './ScannedProductInfo';

export default function isStackableProduct(productInfo: ScannedProductInfo) {
  if (productInfo.product.type !== ProductType.Article) {
    return false;
  }

  if (productInfo.scannedCode.embed) {
    return false;
  }

  return true;
}
