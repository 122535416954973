import { useCallback } from 'react';
import { duration } from 'moment';
import { useAppDispatch, useAppSelector } from '../store';
// @ts-ignore
import { getFulfillmentProducts } from '../actions';
import { usePoller } from '../polling';

export const REFRESH_AFTER = duration(15, 'minutes');

export default function CigaretteStockRefresher() {
  const cigaretteMachineURL = useAppSelector(state =>
    state.checkoutDevice.config.cigaretteMachineURL);
  const mainToken = useAppSelector(state => state.token.main);

  const dispatch = useAppDispatch();

  const handleTick = useCallback(
    () => {
      dispatch(getFulfillmentProducts(cigaretteMachineURL, mainToken));
    },
    [dispatch, cigaretteMachineURL, mainToken],
  );

  const pollerDisabled = !cigaretteMachineURL || !mainToken;

  // It makes no sense to use `useRefresher` here for the moment, as
  // getFulfillmentProducts is not an async thunk and thus will never throw. So
  // it is currently impossible to set a shorter refresh interval when an error
  // occurred.
  usePoller({
    onTick: handleTick,
    interval: REFRESH_AFTER,
    disabled: pollerDisabled,
    immediate: true,
  });

  return null;
}
