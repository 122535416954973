import CheckoutInfoCouponItemParams from '../../../../checkout-info/params/CheckoutInfoCouponItemParams';
import CouponCartItem from '../../CouponCartItem';

export default function mapCouponCartItemToParams(item: CouponCartItem):
CheckoutInfoCouponItemParams {
  return {
    id: item.id,
    amount: 1,
    scannedCode: item.info.scannedCode.scannedByUser,
    couponID: item.couponID,
    redeemed: item.redeemed,
  };
}
