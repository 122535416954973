export default (assets, action) => {
  switch (action.type) {
    case 'GOT_ASSET_MANIFEST':
      return {
        ...assets,
        files: action.payload ?? [],
      };
    default:
      return assets;
  }
};
