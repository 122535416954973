import { RootState } from '../../store';
import cartHasErrorItems from './cartHasErrorItems';

/**
 * Selector that determines whether the cart is empty. The cart is regarded
 * empty when it contains no cart items, no error items, no customer card and no
 * external billing information.
 *
 * @param state The root state.
 * @returns `true` when the cart is empty, `false` when it is not.
 */
export default function isEmptyCart(state: RootState) {
  return (
    state.session.content.cartItems.length === 0 &&
    !cartHasErrorItems(state) &&
    !state.session.content.customerCard &&
    !state.session.externalBilling
  );
}
