import React from 'react';
import { LedState, useLed } from '../../led';
import SessionOrigin from '../../session/SessionOrigin';
import { useAppSelector } from '../../store';
import TerminalFailureCause from '../../terminal/TerminalFailureCause';
import BackToCartView from '../BackToCartView';

const TRANSLATION_KEY: { [key in TerminalFailureCause]: string } = {
  [TerminalFailureCause.UserAborted]: 'error.aborted',
  [TerminalFailureCause.TerminalFailed]: 'error.payment',
  [TerminalFailureCause.TerminalErrored]: 'error.payment',
  [TerminalFailureCause.AgeVerificationFailed]: 'age-verification.age-error',
  [TerminalFailureCause.AgeVerificationNotSupportedByCard]: 'age-verification.not-supported',
};

export default function CheckoutFailedView() {
  useLed(LedState.ErrorColor);

  const failureCause = useAppSelector(state => state.session.failureCause);
  const isAppCart = useAppSelector(state => state.session.origin === SessionOrigin.App);

  const messageId = failureCause
    ? TRANSLATION_KEY[failureCause]
    : 'error.payment';

  return (<BackToCartView messageId={messageId} terminate={isAppCart} />);
}
