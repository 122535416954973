export interface FormatPriceOptions {
  value: number;
  decimalDigits: number;
  currency: string;
  locale?: string;
}

export default function formatPrice({
  value,
  locale,
  decimalDigits,
  currency,
}: FormatPriceOptions) {
  if (!Number.isInteger(value)) {
    throw new Error('value must be an integer');
  }

  const formattedLocale = locale?.replace(/_/g, '-');

  const formatter = new Intl.NumberFormat(formattedLocale, {
    style: 'currency',
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
    currency,
  });

  const normalizedValue = value / (10 ** decimalDigits);

  let formattedValue = formatter.format(normalizedValue);

  // NOTE: there seems to be a bug with Intl.NumberFormat which causes the formatted value to not
  // include a space between the currency and the number if the number is negative. To minimize the
  // impact of this workaround it is currently only applied to CHF.
  if (formattedLocale === 'de-CH') formattedValue = formattedValue.replace(new RegExp(`^${currency}-`), `${currency} -`);

  return formattedValue;
}
