import { AnimatePresence } from 'framer-motion';
import React, { ReactNode } from 'react';
import RouterContext from './RouterContext';

export interface RouterProps {
  children: ReactNode;
  state: any;
}

/**
 * The `Router` component provides a in-memory router that changes the active
 * route based on the change of passed `state` property.
 */
export default function Router({
  children,
  state,
}: RouterProps) {
  return (
    <AnimatePresence mode="wait">
      <RouterContext.Provider value={{ currentState: state }}>
        {children}
      </RouterContext.Provider>
    </AnimatePresence>
  );
}
